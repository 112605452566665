import { playerSocialNetworksApiKeys } from 'entities/playerSocialNetworks/consts';
import { mapPlayerSocialNetworksResponse } from 'entities/playerSocialNetworks/mapper/mapPlayerSocialNetworksResponse';
import {
  TCreatePlayerSocialNetworkPayload,
  TPlayerSocialNetwork,
} from 'entities/playerSocialNetworks/types';
import { del, get, patch, post } from 'shared/api/axios';
import { createPaginationParams } from 'shared/api/utils';
import { toNumber } from 'shared/lib/toNumber';
import { TEntityId } from 'shared/types/common';

export type TFetchPlayerSocialNetworks = {
  page: number;
  filter?: Record<string, string>;
};

export const fetchPlayerSocialNetworks = ({
  page,
  filter,
}: TFetchPlayerSocialNetworks) =>
  get<TPlayerSocialNetwork[], true>(
    playerSocialNetworksApiKeys.getPlayerSocialNetworks,
    {
      params: {
        ...createPaginationParams(page),
        ...filter,
      },
    },
  ).then((response) => mapPlayerSocialNetworksResponse(response.data));

export const deletePlayerSocialNetwork = (playerId: TEntityId) =>
  del(playerSocialNetworksApiKeys.deletePlayerSocialNetwork(playerId));

export const editPlayerSocialNetwork = ({
  id,
  socialNetworkId,
  ...playerSocialNetwork
}: TPlayerSocialNetwork) =>
  patch(playerSocialNetworksApiKeys.editPlayerSocialNetwork(id), {
    ...playerSocialNetwork,
    socialNetworkId: toNumber(socialNetworkId),
  });

export const createPlayerSocialNetwork = ({
  socialNetworkId,
  ...playerSocialNetwork
}: TCreatePlayerSocialNetworkPayload) =>
  post(playerSocialNetworksApiKeys.createPlayerSocialNetwork, {
    socialNetwork: {
      ...playerSocialNetwork,
      socialNetworkId: toNumber(socialNetworkId),
    },
  });
