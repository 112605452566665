import { TLabel } from 'entities/labels/types';
import { TMetaType } from 'shared/types/common';

type TMapLabelsResponseArgs = {
  data: TLabel[];
  meta?: TMetaType<true>;
};

export const mapLabelsResponse = ({
  data: categories,
  meta,
}: TMapLabelsResponseArgs) => {
  const page = meta?.page;

  return {
    total: page?.total_entries,
    page: page?.number,
    categories: categories,
  };
};
