import { socialNetworksApiKeys } from 'entities/socialNetworks/consts';
import { TSocialNetwork } from 'entities/socialNetworks/types';
import { get } from 'shared/api/axios';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { createPageSizeParam } from 'shared/api/utils';

export const fetchSocialNetworks = () => {
  const params = createPageSizeParam(DEFAULT_NO_PAGINATION_PAGE_SIZE);
  return get<TSocialNetwork[]>(socialNetworksApiKeys.getSocialNetworks, {
    params,
  }).then((response) => response.data.data);
};
