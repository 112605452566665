import { usePermissions } from 'entities/user/lib/usePermissions';
import { TUserResource } from 'entities/user/model/types';
import { TProtectedLink } from 'shared/types/protectedLinks';

export type TUseProtectedLinksReturn = <T extends TProtectedLink>(
  links: T[],
  isEveryPermissionProtected?: boolean,
) => T[];

/**
 * Хук, который возвращает callback для фильтрации ссылок на основе permissions.
 * @returns {TUseProtectedLinksReturn} - Функция, которая фильтрует массив ссылок на основе пользовательских разрешений.
 *
 * Аргументы функции:
 * * links - Массив ссылок для фильтрации.
 * * isEveryPermissionProtected - Если true, permissions должны быть удовлетворены для всех ресурсов.
 * Если false, permissions должны быть удовлетворены хотя бы для одного ресурса. По умолчанию true
 */
export const useProtectedLinks = (): TUseProtectedLinksReturn => {
  const canUser = usePermissions();

  return (links, isEveryPermissionProtected = true) =>
    links.filter(({ permissions }) => {
      if (!permissions) {
        return true;
      }

      const resources = Object.keys(permissions) as TUserResource[];

      const hasPermission = (resource: TUserResource) => {
        const operations = permissions[resource];
        return operations ? canUser(operations, [resource]) : false;
      };

      return isEveryPermissionProtected
        ? resources.every(hasPermission)
        : resources.some(hasPermission);
    });
};
