import { useLocales } from 'entities/config/lib/useLocales';
import { mapEditPlayerCareerDTO } from 'entities/playerCareers/mapper/mapEditPlayerCareerDTO';
import { useEditPlayerCareerMutation } from 'entities/playerCareers/queries';
import { TPlayerCareer } from 'entities/playerCareers/types';
import { TPlayerCareerModalFields } from 'feature/player-career-modal/PlayerCareerModal';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'react-use';
import { getFileFromUrl } from 'shared/lib/getFileFromUrl';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';

export const useEditPlayerCareer = (
  activeCareerId: TEntityId | null,
  career: TPlayerCareer,
) => {
  const { locales } = useLocales();

  const [isEditModalOpen, setIsEditModalOpen] = useBoolean(false);
  const { playerId = '' } = useParams<{
    teamId: string;
    playerId: string;
  }>();
  const [careerModal, setCareerModal] = useState<TPlayerCareerModalFields>();

  const openEditModal = async () => {
    const teamPicture = await getFileFromUrl(career.teamPicture.x3);
    setCareerModal({
      season: career.season,
      championshipCount: toString(career.championshipCount),
      cupsCount: toString(career.cupsCount),
      eurocupsCount: toString(career.eurocupsCount),
      gamesCount: toString(career.gamesCount),
      teamPicture,
      translations: career.translations,
    });
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => setIsEditModalOpen(false);

  const { mutateAsync: editCareerMutation, isLoading } =
    useEditPlayerCareerMutation();

  const editCareerHandler = async (data: TPlayerCareerModalFields) => {
    const createEditCareerDTO = await mapEditPlayerCareerDTO(
      {
        data,
        locales,
        playerId,
      },
      activeCareerId as TEntityId,
    );

    await editCareerMutation(createEditCareerDTO);
    setIsEditModalOpen(false);
  };

  return {
    openEditModal,
    editCareerModalProps: {
      title: 'Редактирование карьеры',
      closeModal: closeEditModal,
      actionSubmit: editCareerHandler,
      isOpened: isEditModalOpen,
      isLoading,
      initialValues: careerModal,
    },
  };
};
