import { useFetchUser } from 'entities/user/model/queries';
import { useUserStore } from 'entities/user/model/store';
import { TUserPermissionData } from 'entities/user/model/types';
import mapValues from 'lodash/mapValues';
import { useEffect } from 'react';
import { groupByProperty } from 'shared/lib/groupByProperty';

const normalizePermissions = (permissions: TUserPermissionData[]) =>
  mapValues(groupByProperty(permissions, 'resource'), (value) =>
    value.map((v) => v.operation),
  );

export const useUser = () => {
  const { data: userData, isLoading } = useFetchUser();

  const { user, setUser } = useUserStore((state) => state);

  useEffect(() => {
    if (userData) {
      setUser({
        ...userData,
        permissions: normalizePermissions(userData.permissions),
      });
    } else {
      setUser(userData);
    }
  }, [userData]);

  return { user, isLoading };
};
