import { useLocales } from 'entities/config/lib/useLocales';
import { useDeleteManagerMutation } from 'entities/managers/api/mutations';
import { TManager } from 'entities/managers/model/types';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card } from 'shared/components/Card/Card';
import { ECardType } from 'shared/components/Card/types';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { notify } from 'shared/components/Notification';
import { routes } from 'shared/routes';

type TProps = {
  manager: TManager;
};

export const ManagerCard = ({ manager }: TProps) => {
  const navigate = useNavigate();
  const { teamId = '' } = useParams();

  const { defaultLocale } = useLocales();
  const { mutateAsync: deleteManager, isLoading } = useDeleteManagerMutation();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const onDeleteHandler = () => {
    deleteManager(manager.id, {
      onError: () => notify('Не удалось удалить менеджера'),
    });
  };

  return (
    <>
      <Card
        key={manager.id}
        type={ECardType.Manager}
        entity={{
          name: manager.translations[defaultLocale].firstName,
          lastName: manager.translations[defaultLocale].lastName,
          picture: manager?.picture?.x3 ?? '',
          position: manager.managerPosition,
        }}
        onDelete={() => setOpenConfirmModal(true)}
        onEdit={() => navigate(routes.editManager.as(teamId, manager.id))}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpened={openConfirmModal}
        title="Удаление менеджера?"
        description={
          'Вы уверены, что хотите удалить этого менеджера?\nДанное действие необратимо.'
        }
        onClose={() => setOpenConfirmModal(false)}
        onConfirm={onDeleteHandler}
      />
    </>
  );
};
