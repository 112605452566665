import cn from 'classnames';
import { useLocales } from 'entities/config/lib/useLocales';
import { ModalContext } from 'pages/Stories/EditStory/context';
import { useContext } from 'react';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { CopyButton } from 'shared/components/CopyButton/CopyButton';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { langNameMap } from 'shared/consts';
import { TLangType } from 'shared/types/common';

import styles from './SaveStoryModal.module.css';

type TProps = {
  selectedStoryGroupId: string;
  onClose: () => void;
  isOpened: boolean;
  createdLocales: TLangType[];
  onPublishNow: (date?: Date) => void;
};

export const SaveStoryModal = ({
  onClose,
  isOpened,
  selectedStoryGroupId,
  createdLocales,
  onPublishNow,
}: TProps) => {
  const { openModal } = useContext(ModalContext);
  const { locales } = useLocales();

  const handlePublish = () => {
    const now = new Date();
    onPublishNow(now);
  };

  const filledLocales = createdLocales
    .map((locale) => langNameMap[locale])
    .join(', ');
  const notFilledLocales = locales
    .filter((lang) => !createdLocales.includes(lang))
    .map((locale) => langNameMap[locale])
    .join(', ');

  return (
    <ActionModal
      cancelText="Запланировать"
      confirmText="Опубликовать"
      contentClassName={styles.mainContent}
      opened={isOpened}
      title="Опубликовать контент"
      width={549}
      cancelButtonProps={{
        icon: 'calendar',
        iconPosition: 'before',
        onClick: () => openModal('datePicker'),
      }}
      confirmButtonProps={{
        onClick: handlePublish,
        kind: 'primary',
      }}
      onClose={onClose}
    >
      <div className={cn(styles.info, styles.idInfo)}>
        <Text>ID группы сторис</Text>
        <div className={styles.copyId}>
          <CopyButton iconSize="s" textToCopy={selectedStoryGroupId} />
          <Text className={styles.textMuted} size={14}>
            ID: {selectedStoryGroupId}
          </Text>
        </div>
      </div>
      <div className={styles.info}>
        <Text>Заполненные языки</Text>
        <Text className={styles.textMuted} size={16}>
          {filledLocales}
        </Text>
      </div>
      {notFilledLocales.length > 0 && (
        <div className={styles.info}>
          <Text>Незаполненные языки</Text>
          <Text className={styles.textMuted} size={16}>
            {notFilledLocales}
          </Text>
          <div className={styles.alertMessage}>
            <Icon kind="alert-circle" size="s" />
            <Text className={styles.textMuted}>
              Данные локализации не будут отображены из-за отсутствия заполненного
              контента
            </Text>
          </div>
        </div>
      )}
    </ActionModal>
  );
};
