import { useCopyToClipboard } from 'react-use';
import { TIconSize } from 'shared/components/Icon/types';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Tooltip } from 'shared/components/Tooltip/Tooltip';

type TProps = {
  iconSize?: TIconSize;
  className?: string;
  textToCopy: string;
};

export const CopyButton = ({ iconSize, textToCopy, className }: TProps) => {
  const [state, copyToClipboard] = useCopyToClipboard();
  return (
    <Tooltip tip={state.value === textToCopy ? 'Скопировано' : 'Копировать'}>
      <div>
        <IconButton
          className={className}
          icon="copy"
          iconSize={iconSize}
          onClick={() => copyToClipboard(textToCopy)}
        />
      </div>
    </Tooltip>
  );
};
