import { TRectangle } from 'pages/Stories/EditStory/types';

export function isRectsIntersect(r1: TRectangle, r2: TRectangle) {
  return !(
    r2.left > r1.right ||
    r2.right < r1.left ||
    r2.top > r1.bottom ||
    r2.bottom < r1.top
  );
}
