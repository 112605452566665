import { useQuery } from '@tanstack/react-query';
import { labelsQueryKeys } from 'entities/labels/consts';
import { TPaginatedParameters } from 'shared/api/types';

import { fetchLabels } from './api';

export const useFetchLabelsQuery = (props: TPaginatedParameters | void) => {
  const page = props?.page || 1;
  const pageSize = props?.pageSize;

  return useQuery({
    queryKey: [labelsQueryKeys.labels, page, pageSize],
    queryFn: () => fetchLabels({ page, pageSize }),
    cacheTime: 0,
  });
};
