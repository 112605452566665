import { TDefaultRightPanelProps } from 'feature/right-panel/types';
import { CommonImportance } from 'feature/right-panel/ui/CommonImportance/CommonImportance';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { imageSettings } from 'shared/constants';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';

export const AddNewsPostRightPanelImportance = (
  commonProps: TDefaultRightPanelProps,
) => {
  const { control } = useFormContext<TEditNewsPostFields>();
  const { field: sliderImage, fieldState } = useController({
    control,
    name: 'slider.file',
  });
  const { field: sliderImageData } = useController({
    control,
    name: 'slider.data',
  });

  const { field: important } = useController({
    control,
    name: 'important',
  });

  useEffect(() => {
    getBase64FromFile(sliderImage.value).then((data) => {
      sliderImageData.onChange(data);
    });
  }, [sliderImage.value]);

  return (
    <CommonImportance
      aspectRatio={imageSettings.news.slider.aspectRatio}
      control={control}
      description="“Порядок отображения” дает возможность отображать вашу новость в списке “Важных”."
      fieldState={fieldState}
      sliderImage={sliderImage.value}
      title="Сделайте новость важной"
      cover={{
        imageUrl: sliderImageData.value,
        fieldName: 'slider.file',
        important: important.value,
        tip: 'Обложка слайдера служит основным изображением на карточке новости внутри слайдера новостей',
      }}
      switchField={{
        fieldName: 'important',
        description: 'Сделать новость важной',
      }}
      onChange={sliderImageData.onChange}
      {...commonProps}
    />
  );
};
