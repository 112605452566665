import axios from 'axios';
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom';
import { Button } from 'shared/components/Button/Button';
import { Text } from 'shared/components/Text/Text';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_TITLE } from 'shared/validation';
import { Navigation } from 'widgets/navigation/ui/Navigation/Navigation';

import styles from './ErrorPage.module.css';

type TErrorContent = {
  status?: number;
  title?: string;
  message: string;
};

const statusText: { [key: number]: string } = {
  401: 'Вы не авторизованы',
  403: 'У вас недостаточно прав для просмотра этой страницы',
  404: 'Страница не найдена',
  500: 'Внутренняя ошибка сервера',
} as const;

const getErrorContent = (error: unknown): TErrorContent => {
  if (isRouteErrorResponse(error)) {
    const { status } = error;
    let message = statusText[status];
    if (!message) {
      message = DEFAULT_ERROR_MESSAGE;
    }

    return {
      status,
      message,
    };
  }

  if (axios.isAxiosError(error)) {
    const status = error?.status ?? error.response?.status;

    return {
      status,
      message: error.message,
    };
  }

  // TODO: Implement custom error classes: [WEB-1012]
  // It is assumed, that manually thrown errors will contain 'title' and 'message' properties
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  if (typeof error === 'object' && error !== null && error.title && error.message) {
    //@ts-ignore
    return { title: error.title, message: error.message };
  }
  /* eslint-enable */

  return {
    title: DEFAULT_ERROR_TITLE,
    message: DEFAULT_ERROR_MESSAGE,
  };
};

export const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  const { status, title, message } = getErrorContent(error);

  return (
    <div className={styles.root}>
      <Navigation />
      <div className={styles.content}>
        {status && !title && (
          <Text className={styles.errorStatus} weight="SemiBold">
            {status}
          </Text>
        )}
        {!status && title && <Text className={styles.errorTitle}>{title}</Text>}
        <Text size={20}>{message}</Text>
        <Button
          className={styles.refreshButton}
          icon="refresh-ccw-01"
          onClick={() => navigate(0)}
        >
          Обновить
        </Button>
      </div>
    </div>
  );
};
