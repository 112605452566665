// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --player-team-achievements-columns-template: 260px 64px auto 36px\n}\n\n.PlayerTeamAchievementsTable-module__root--BL1ml {\n  width: 100%;\n}\n\n.PlayerTeamAchievementsTable-module__rowGridTemplate--zPr3H {\n  grid-template-columns: var(--player-team-achievements-columns-template);\n  min-height: 44px;\n  padding-left: unset;\n}\n\n.PlayerTeamAchievementsTable-module__headRow--K4jiI {\n  grid-template-columns: var(--player-team-achievements-columns-template);\n  min-height: 36px;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/player/PlayerTeamAchievementsPage/PlayerTeamAchievementsTable/PlayerTeamAchievementsTable.module.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,uEAAuE;EACvE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,uEAAuE;EACvE,gBAAgB;AAClB","sourcesContent":[":root {\n  --player-team-achievements-columns-template: 260px 64px auto 36px\n}\n\n.root {\n  width: 100%;\n}\n\n.rowGridTemplate {\n  grid-template-columns: var(--player-team-achievements-columns-template);\n  min-height: 44px;\n  padding-left: unset;\n}\n\n.headRow {\n  grid-template-columns: var(--player-team-achievements-columns-template);\n  min-height: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlayerTeamAchievementsTable-module__root--BL1ml",
	"rowGridTemplate": "PlayerTeamAchievementsTable-module__rowGridTemplate--zPr3H",
	"headRow": "PlayerTeamAchievementsTable-module__headRow--K4jiI"
};
export default ___CSS_LOADER_EXPORT___;
