import { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { TEditorVideo } from 'shared/components/Editor/types';
import { FormInput } from 'shared/components/Form';
import { Text } from 'shared/components/Text/Text';
import { useValidVideoLink } from 'shared/components/VideoPlayer/helpers';
import { VideoPlayer } from 'shared/components/VideoPlayer/VideoPlayer';
import { secureProtocol } from 'shared/constants';
import { onBlurVideoLinkHandler } from 'shared/handlers';

import styles from './AttachVideoLinkModal.module.css';

type TAddVideoField = {
  video: string;
};

type TProps = {
  isOpened: boolean;
  title: string;
  onClose: () => void;
  onConfirm: (link: TEditorVideo) => void;
};

export const AttachVideoLinkModal = ({
  isOpened,
  title,
  onClose,
  onConfirm,
}: TProps) => {
  const { control, reset } = useForm<TAddVideoField>();
  const {
    field: { value },
  } = useController({
    control,
    name: 'video',
  });
  const { isValidLink, attachedVideoHosting } = useValidVideoLink(value);

  const onConfirmHandler = () => {
    attachedVideoHosting && onConfirm({ src: value, hosting: attachedVideoHosting });
    onClose();
    reset();
  };

  useEffect(() => {
    if (!isOpened) {
      reset();
    }
  }, [isOpened]);

  return (
    <ActionModal
      opened={isOpened}
      title={title}
      width={542}
      confirmButtonProps={{
        isDisabled: !isValidLink,
        onClick: onConfirmHandler,
      }}
      onClose={onClose}
    >
      <>
        <FormInput
          className={styles.preview}
          control={control}
          fieldName="video"
          label="Ссылка на видео"
          placeholder="Вставьте ссылку"
          required
          size="s"
          onBlur={() => onBlurVideoLinkHandler(isValidLink, value)}
        />
        <Text className={styles.hintText} size={12}>
          Ссылки надо использовать из списка разрешенных сервисов, они должны
          начинаться с {secureProtocol}
        </Text>
        {isValidLink && (
          <div className={styles.videoWrapper}>
            <VideoPlayer
              hosting={attachedVideoHosting ?? ''}
              src={value}
              width={494}
            />
          </div>
        )}
      </>
    </ActionModal>
  );
};
