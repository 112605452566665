import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { managersQueryKeys } from 'entities/managers/api/consts';
import { TManagersResponse } from 'entities/managers/mapper/mapFetchManagersResponse';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

import { fetchManager, fetchManagers, TFetchManagersArgs } from './api';

export const useFetchManagersQuery = (
  params: TFetchManagersArgs,
  options: UseQueryOptions<TManagersResponse, unknown, TManagersResponse> = {},
) =>
  useQuery({
    queryKey: [managersQueryKeys.managers, params],
    queryFn: () => fetchManagers(params),
    ...options,
  });

export const useFetchManagerQuery = (id: TEntityId = '') =>
  useQuery({
    queryKey: [managersQueryKeys.manager, id],
    queryFn: () => fetchManager(id),
    enabled: toBoolean(id),
  });
