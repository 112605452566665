// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SecondLayoutNavigation-module__root--sAZWG {\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0;\n  width: 236px;\n  padding: 12px 0;\n  border-right: 1px solid var(--gray-200);\n}\n\n.SecondLayoutNavigation-module__list--nYmpG {\n  display: flex;\n  padding: 8px 12px;\n  flex-direction: column;\n  gap: 2px;\n  list-style-type: none;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/ui/layouts/second-layout/SecondLayoutNavigation/SecondLayoutNavigation.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,YAAY;EACZ,eAAe;EACf,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,sBAAsB;EACtB,QAAQ;EACR,qBAAqB;AACvB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0;\n  width: 236px;\n  padding: 12px 0;\n  border-right: 1px solid var(--gray-200);\n}\n\n.list {\n  display: flex;\n  padding: 8px 12px;\n  flex-direction: column;\n  gap: 2px;\n  list-style-type: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SecondLayoutNavigation-module__root--sAZWG",
	"list": "SecondLayoutNavigation-module__list--nYmpG"
};
export default ___CSS_LOADER_EXPORT___;
