// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToolsList-module__root--WDTBk {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-content: flex-start;\n  row-gap: 24px;\n  padding: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/Stories/EditStory/components/ToolsList/ToolsList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,yBAAyB;EACzB,aAAa;EACb,aAAa;AACf","sourcesContent":[".root {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-content: flex-start;\n  row-gap: 24px;\n  padding: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ToolsList-module__root--WDTBk"
};
export default ___CSS_LOADER_EXPORT___;
