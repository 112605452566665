// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Box-module__root--XzRXk {\n  position: absolute;\n  width: auto;\n  height: auto;\n}\n\n.Box-module__isHighlighted--x91Ri {\n  border: 2px dashed var(--purple-400);\n}\n\n.Box-module__isDraggable--zWFs3 {\n  cursor: grab;\n}\n\n.Box-module__inner--QBsHp {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  pointer-events: none;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/Stories/EditStory/components/Box/Box.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,oBAAoB;AACtB","sourcesContent":[".root {\n  position: absolute;\n  width: auto;\n  height: auto;\n}\n\n.isHighlighted {\n  border: 2px dashed var(--purple-400);\n}\n\n.isDraggable {\n  cursor: grab;\n}\n\n.inner {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Box-module__root--XzRXk",
	"isHighlighted": "Box-module__isHighlighted--x91Ri",
	"isDraggable": "Box-module__isDraggable--zWFs3",
	"inner": "Box-module__inner--QBsHp"
};
export default ___CSS_LOADER_EXPORT___;
