import { TManagerCareer } from 'entities/managerCareers/model/types';
import { ManagerCareersActions } from 'pages/manager/ManagerCareersPage/ManagerCareersActions/ManagerCareersActions';
import React from 'react';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elements-list-table/types';

import styles from '../ManagerCareersPage.module.css';

export const getManagerCareersColumnsConfig: TConfigCreator<TManagerCareer> = (
  defaultLocale,
  activeItemId,
  setActiveItemId,
) => [
  {
    title: 'Клуб',
    render: (item) => (
      <>
        <div className={styles.previewWrapper}>
          {item.teamPicture ? (
            <img
              alt={`${item?.translations[defaultLocale]?.teamName} preview`}
              src={item.teamPicture.x1}
            />
          ) : (
            <Icon kind="picture" size="s" />
          )}
        </div>
        <Text className={styles.text}>
          {item?.translations[defaultLocale]?.teamName}
        </Text>
      </>
    ),
  },
  {
    title: 'Должность',
    render: (item) => <Text>{item.translations[defaultLocale]?.jobTitle}</Text>,
  },
  {
    title: 'Сезон',
    render: (item) => <Text>{item.season}</Text>,
  },
  {
    title: 'ID',
    render: (item) => <Text>{item.id}</Text>,
  },
  {
    title: '',
    render: (item) => (
      <ManagerCareersActions
        activeCareerId={activeItemId}
        career={item}
        setActiveCareerId={setActiveItemId}
      />
    ),
  },
];
