import { BackButton } from 'pages/Stories/EditStory/components/forms/components/BackButton/BackButton';
import { TitleColors } from 'pages/Stories/EditStory/components/forms/QuizElementForm/components/TitleColors/TitleColors';
import { Divider } from 'shared/components/Divider';
import { Form, FormInput } from 'shared/components/Form';
import { Checkbox } from 'shared/components/Form/Checkbox/Checkbox';

import { TCommonElementFormProps } from '../../forms/types';
import commonElementFormStyles from '../ElementForm.module.css';
import { AnswerInputs } from './components/AnswerInputs/AnswerInputsWrapper';
import { AnswerRadioButtons } from './components/AnswerRadioButtons/AnswerRadioButtons';
import styles from './QuizElementForm.module.css';
import { useQuizElementForm } from './useQuizElementForm';

export const QuizElementForm = ({
  selectedElement,
}: TCommonElementFormProps<'quiz'>) => {
  const {
    control,
    submit,
    fields,
    titleDisabled,
    getSwitchField,
    handleChangeCorrectAnswer,
  } = useQuizElementForm(selectedElement);

  return (
    <div className={commonElementFormStyles.root}>
      <Form action={submit} className={styles.formComponent}>
        <FormInput
          control={control}
          fieldName="title"
          inputClassName={commonElementFormStyles.input}
          label="Заголовок"
          maxLength={150}
          placeholder="Введите заголовок"
        />
        <Divider />
        <AnswerInputs
          control={control}
          fields={fields}
          getSwitchField={getSwitchField}
        />
        <Divider />
        <AnswerRadioButtons
          control={control}
          fields={fields}
          onChange={handleChangeCorrectAnswer}
        />
        <Divider />
        <Checkbox
          checkboxLabel="Скрыть заголовок"
          control={control}
          fieldName="titleDisabled"
          inline
          inlineInputClassName={commonElementFormStyles.formField}
        />
        {!titleDisabled && <TitleColors control={control} />}
      </Form>
      <BackButton />
    </div>
  );
};
