import { usePlayerLayout } from 'feature/player-layout/ui/PlayerLayout/usePlayerLayout';
import { useParams } from 'react-router-dom';
import { TButtonProps } from 'shared/components/Button/Button';
import { Loader } from 'shared/components/Loader/Loader';
import { TWithOptionalChildren } from 'shared/types/common';
import { SecondLayout } from 'shared/ui/layouts/second-layout/SecondLayout';

import styles from './PlayerLayout.module.css';

type TProps = {
  confirmButtonProps?: TButtonProps;
};

export const PlayerLayout = ({
  children,
  confirmButtonProps = {},
}: TProps & TWithOptionalChildren) => {
  const { playerId, teamId } = useParams<{
    playerId: string;
    teamId: string;
  }>();

  const { backButtonClickHandler, playerNavigationLinks, isLoading, error } =
    usePlayerLayout({
      playerId,
      teamId,
    });

  if (error) {
    throw error;
  }

  if (isLoading) {
    return <Loader className={styles.loading} />;
  }

  return (
    <SecondLayout
      confirmButtonProps={confirmButtonProps}
      navigationLinks={playerNavigationLinks}
      title="Игрок"
      backButtonProps={{
        onClick: backButtonClickHandler,
      }}
    >
      {children}
    </SecondLayout>
  );
};
