import { Text } from 'shared/components/Text/Text';

import styles from './Dashboard.module.css';

export const Dashboard = () => {
  return (
    <div className={styles.root}>
      <Text size={30} tag="h2" weight="SemiBold">
        Добро пожаловать!
      </Text>
      <Text size={24}>Для начала работы воспользуйтесь навигационным меню</Text>
    </div>
  );
};
