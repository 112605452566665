import { MAX_FILE_SIZE } from 'shared/consts';
import z from 'zod';

export const fileZodValidation = (
  errorMessage: string,
  maxFileSize = MAX_FILE_SIZE,
  maxSizeErrorMessage = 'Максимальный размер файла 7MB.',
) =>
  z
    .any()
    .refine((file?: File) => file, errorMessage)
    .refine((file?: File) => {
      return file && file.size < maxFileSize;
    }, maxSizeErrorMessage);
