import { TManagerTeamAchievement } from 'entities/managerTeamAchievements/types';
import { TMetaType } from 'shared/types/common';

type TMapManagerTeamAchievementsResponseArgs = {
  data: TManagerTeamAchievement[];
  meta?: TMetaType<true>;
};

export const mapManagerTeamAchievementsResponse = ({
  data: managerTeamAchievements,
  meta,
}: TMapManagerTeamAchievementsResponseArgs) => {
  const page = meta!.page;

  return {
    total: page.total_entries,
    page: page.number,
    managerTeamAchievements,
  };
};
