import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import { deleteTeam } from 'entities/teams/api';
import { teamsQueryKeys } from 'entities/teams/consts';
import { createTeam, editTeam } from 'entities/teams/api';

export const useDeleteTeamMutation = () => {
  return useMutation({
    mutationFn: deleteTeam,
    onSuccess: () => {
      return queryClient.resetQueries([teamsQueryKeys.teams]);
    },
  });
};

export const createTeamMutation = () =>
  useMutation({
    mutationFn: createTeam,
  });

export const editTeamMutation = () =>
  useMutation({
    mutationFn: editTeam,
  });
