import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchCountriesQuery } from 'entities/countries/query';
import { CountryLabel } from 'feature/country-select/CountryLabel/CountryLabel';
import { useMemo } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { FormSelect } from 'shared/components/Form/Select/FormSelect';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { toString } from 'shared/lib/toString';
import { compareIds } from 'shared/utils/entityIds';

import styles from './CountrySelect.module.css';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
  className?: string;
  labelClassName?: string;
};

export const CountrySelect = <T extends FieldValues>({
  control,
  fieldName,
  className,
  labelClassName,
}: TProps<T>) => {
  const { defaultLocale } = useLocales();
  const {
    field: { value: selectedCountryId },
  } = useController({
    control,
    name: fieldName,
  });

  const { data: countries } = useFetchCountriesQuery();

  const countryOptions = useMemo(
    () =>
      countries?.map<TSelectOption>((country) => ({
        value: toString(country.id),
        label: country.translations[defaultLocale]?.name ?? '',
        renderLabel: (label: string) => (
          <CountryLabel image={country.picture.x1} label={label} />
        ),
      })) ?? [],
    [countries],
  );

  const countryPlaceholder = useMemo(() => {
    if (selectedCountryId && countries?.length) {
      const selectedCountry = countries.find((c) =>
        compareIds(c.id, selectedCountryId),
      );

      return (
        <CountryLabel
          key={selectedCountry!.code}
          flagSize="m"
          image={selectedCountry!.picture.x1}
          label={selectedCountry!.translations[defaultLocale]?.name ?? ''}
        />
      );
    }

    return 'Выбрать страну';
  }, [selectedCountryId, countryOptions]);

  return (
    <FormSelect
      className={className}
      control={control}
      fieldName={fieldName}
      label="Страна"
      labelClassName={labelClassName}
      multiple={false}
      options={countryOptions}
      optionsContainerClassName={styles.optionsContainer}
      placeholder={countryPlaceholder}
      required
      search={true}
    />
  );
};
