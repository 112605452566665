import { useLocales } from 'entities/config/lib/useLocales';
import { useCreatePhotoGalleryCategoryMutation } from 'entities/photoGalleryCategories/mutations';
import { useFetchPhotoGalleryCategoriesQuery } from 'entities/photoGalleryCategories/queries';
import { PhotoGalleryCategoriesPageTable } from 'pages/settings/categories/PhotoGalleryCategoriesPage/PhotoGalleryCategoriesPageTable';
import React, { useCallback, useState } from 'react';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { usePagination } from 'shared/hooks/usePagination';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TTranslations } from 'shared/types/common';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

import styles from './PhotoGalleryCategoriesPage.module.css';

export const PhotoGalleryCategoriesPage = () => {
  const { locales } = useLocales();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const createPhotoGalleryCategories = useCreatePhotoGalleryCategoryMutation();

  const { selectedPage, handlePageChange } = usePagination();
  const { data, isLoading, error } =
    useFetchPhotoGalleryCategoriesQuery(selectedPage);

  const closeModal = useCallback(() => {
    setShowCreateModal(false);
  }, []);

  const openModal = useCallback(() => {
    setShowCreateModal(true);
  }, []);

  const createPhotoGalleryCategory = (translations: TTranslations<string>) => {
    createPhotoGalleryCategories.mutate({
      translations: reduceTranslationObject(locales, (locale) => ({
        name: translations[locale],
      })),
    });
    closeModal();
  };
  return (
    <div className={styles.root}>
      <CommonListWrapper
        error={error}
        isEmpty={!data?.categories || (data?.total === 0 && !isLoading)}
        isLoading={isLoading}
        title="Категории фотогалереи"
        createButtonProps={{
          content: 'Создать категорию',
          onClick: openModal,
        }}
        paginationProps={{
          selectedPage: data?.page ?? 1,
          totalItems: data?.total ?? 0,
          onPageChange: handlePageChange,
        }}
      >
        <PhotoGalleryCategoriesPageTable categories={data?.categories || []} />
      </CommonListWrapper>
      <LocalizationModal
        actionSubmit={createPhotoGalleryCategory}
        closeModal={closeModal}
        isOpened={showCreateModal}
        subtitle="Название категории"
        title="Создание категории"
        inputProps={{
          maxLength: 255,
          placeholder: 'Введите заголовок',
          required: true,
          size: 's',
        }}
      />
    </div>
  );
};
