import { TManager } from 'entities/managers/model/types';
import { TPlayer } from 'entities/players/model/types';
import { TCommonOptionType, TSelectOption } from 'feature/right-panel/types';
import { declination } from 'shared/lib/declination';
import { toString } from 'shared/lib/toString';
import { TLangType, TTranslationsObject } from 'shared/types/common';

type TPlaceholderArgs = {
  count?: number;
  singular: string;
  few: string;
  many: string;
  defaultPlaceholder: string;
};

export const generatePlaceholder = (args: TPlaceholderArgs): string => {
  const { count, singular, few, many, defaultPlaceholder } = args;

  return count
    ? declination(count, [
        `Выбран ${count} ${singular}`,
        `Выбрано ${count} ${few}`,
        `Выбрано ${count} ${many}`,
      ])
    : defaultPlaceholder;
};

type TNamedTranslations = TTranslationsObject<{
  firstName: string;
  lastName: string;
}>;

const generateName = <T extends TPlayer | TManager>(
  item: T & TNamedTranslations,
  locale: TLangType,
) => {
  return `${item.translations?.[locale]?.firstName ?? ''} ${
    item.translations?.[locale]?.lastName ?? ''
  }`;
};

export const generateOptionsArray = <T extends TCommonOptionType>(
  defaultLocale: TLangType,
  data?: T[],
): TSelectOption[] => {
  if (!data) {
    return [];
  }

  return data.map((item) => ({
    value: toString(item.id),
    label: item.translations?.[defaultLocale]?.name ?? '',
  }));
};

export const generateNameOptionsArray = <T extends TPlayer | TManager>(
  defaultLocale: TLangType,
  data?: T[],
): TSelectOption[] => {
  if (!data) {
    return [];
  }

  return data.map((item) => ({
    value: toString(item.id),
    label: generateName(item, defaultLocale),
  }));
};
