import z from 'zod';

export const workspacePanelAddLinkModalValidation = z.object({
  url: z
    .string()
    .min(1, 'Поле обязательно для ввода')
    .url('Должен быть правильный URL')
    .startsWith('https://', { message: 'Должен быть безопасный URL' }),
  text: z.string(),
});
