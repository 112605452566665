import { TSortDirection } from 'pages/Stories/StoriesList/types';

type TProps = {
  sortDirection?: TSortDirection;
};

const SORT_ORDER: Record<TSortDirection, TSortDirection> = {
  asc: 'asc',
  desc: 'desc',
};

export const useNextSortDirection = ({ sortDirection }: TProps) => {
  if (sortDirection === SORT_ORDER.asc) {
    return SORT_ORDER.desc;
  }
  return SORT_ORDER.asc;
};
