import { ManagerLayout } from 'feature/manager-layout/ui/ManagerLayout/ManagerLayout';
import { noop } from 'lodash';
import { useManagerSocialNetworks } from 'pages/manager/ManagerSocialNetworksPage/hooks/useManagerSocialNetworks';
import { ManagerSocialNetworksTable } from 'pages/manager/ManagerSocialNetworksPage/ManagerSocialNetworksTable/ManagerSocialNetworksTable';
import {
  calculateSelectedPage,
  calculateTotalItems,
} from 'pages/video/VideoPage/utils';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

import styles from './ManagerSocialNetworksPage.module.css';

export const ManagerSocialNetworksPage = () => {
  const {
    error,
    isLoading,
    handlePageChange,
    socialLinks,
    page,
    total,
    handleConfirmButtonClick,
  } = useManagerSocialNetworks();

  return (
    <ManagerLayout
      confirmButtonProps={{
        onClick: handleConfirmButtonClick,
      }}
    >
      <CommonListWrapper
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title="Ссылки на социальные сети"
        createButtonProps={{
          content: 'Добавить ссылку',
          onClick: noop,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(socialLinks, page),
          totalItems: calculateTotalItems(socialLinks, total),
          onPageChange: handlePageChange,
        }}
      >
        <ManagerSocialNetworksTable socialLinks={socialLinks ?? []} />
      </CommonListWrapper>
    </ManagerLayout>
  );
};
