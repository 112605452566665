import { PlayerSocialNetworksActions } from 'pages/player/PlayerSocialNetworksPage/PlayerSocialNetworksActions/PlayerSocialNetworksActions';
import { TPlayerSocialNetworkItem } from 'pages/player/PlayerSocialNetworksPage/types';
import React from 'react';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elements-list-table/types';

import styles from './PlayerSocialNetworksPage.module.css';

export const getPlayerSocialNetworksColumnsConfig: TConfigCreator<
  TPlayerSocialNetworkItem
> = (defaultLocale, activeSocialLinkId, setActiveSocialLinkId) => [
  {
    title: 'Название',
    cellClassName: styles.nameColumn,
    render: (item) => (
      <>
        <div className={styles.icon}>
          <img
            alt={`social network ${item.socialNetwork.translations[defaultLocale].name}`}
            src={item.socialNetwork.picture.x3}
          />
        </div>
        <Text className={styles.text} color="var(--gray-500)">
          {item.socialNetwork.translations[defaultLocale].name}
        </Text>
      </>
    ),
  },
  {
    title: '',
    render: (playerSocialNetwork) => (
      <PlayerSocialNetworksActions
        activeSocialLinkId={activeSocialLinkId}
        playerSocialNetwork={playerSocialNetwork}
        setActiveSocialLinkId={setActiveSocialLinkId}
      />
    ),
  },
];
