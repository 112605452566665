import { toString } from 'shared/lib/toString';

const MAX_LENGTH_VAR = '%MAX_LENGTH_VAR%';
const TEXT_TYPE = '%TEXT_TYPE%';
const DEFAULT_MAX_LENGTH = 20;
const TEXT_MAX_LENGTH_MESSAGE = `Длина ${TEXT_TYPE} - не более ${MAX_LENGTH_VAR} символов`;

type TGetTextMaxLengthTextType = 'text' | 'link' | 'title';

const getTextType = (textType: TGetTextMaxLengthTextType) => {
  switch (textType) {
    case 'text':
      return 'текста';
    case 'link':
      return 'ссылки';
    case 'title':
      return 'заголовка';
    default:
      return 'текста';
  }
};

export const getTextMaxLengthMessage = (
  maxLength: number | string = DEFAULT_MAX_LENGTH,
  textType: TGetTextMaxLengthTextType = 'text',
) =>
  TEXT_MAX_LENGTH_MESSAGE.replace(MAX_LENGTH_VAR, toString(maxLength)).replace(
    TEXT_TYPE,
    getTextType(textType),
  );
