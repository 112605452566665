// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlayerAchievementsPage-module__text--CH8zD {\n  padding-left: 12px;\n  padding-right: 12px;\n  max-width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.PlayerAchievementsPage-module__createButton--u8KrG {\n  padding: 7px 11px;\n}\n\n.PlayerAchievementsPage-module__nameColumn--VWKfU {\n  width: 260px;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/player/PlayerAchievementsPage/PlayerAchievementsPage.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".text {\n  padding-left: 12px;\n  padding-right: 12px;\n  max-width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.createButton {\n  padding: 7px 11px;\n}\n\n.nameColumn {\n  width: 260px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "PlayerAchievementsPage-module__text--CH8zD",
	"createButton": "PlayerAchievementsPage-module__createButton--u8KrG",
	"nameColumn": "PlayerAchievementsPage-module__nameColumn--VWKfU"
};
export default ___CSS_LOADER_EXPORT___;
