import { fontStyleMap } from 'pages/Stories/EditStory/components/forms/constants';
import { TElementProps } from 'pages/Stories/EditStory/types';
import { Text } from 'shared/components/Text/Text';

import styles from './VotingElement.module.css';

export const VotingElement = ({ settings }: TElementProps<'voting'>) => {
  const { title, fontStyle, fontFamily, fill, background } = settings;
  const fontStyles = fontStyleMap[fontStyle];

  return (
    <div className={styles.root}>
      <div
        className={styles.question}
        style={{
          fontFamily,
          backgroundColor: background,
          color: fill,
          ...fontStyles,
        }}
      >
        <Text size={16}>{title}</Text>
      </div>
      <div className={styles.answers}>
        <Text size={24} weight="Bold">
          Да
        </Text>
        <Text size={24} weight="Bold">
          Нет
        </Text>
      </div>
    </div>
  );
};
