import { labelsApiKeys } from 'entities/labels/consts';
import { mapLabelsResponse } from 'entities/labels/mapper/mapLabelsResponse';
import { TFetchLabels, TLabel } from 'entities/labels/types';
import { del, get, patch, post } from 'shared/api/axios';
import { createPaginationParams } from 'shared/api/utils';
import { TEntityId, TTranslationsObject } from 'shared/types/common';

export const fetchLabels = ({ page, pageSize }: TFetchLabels) =>
  get<TLabel[], true>(labelsApiKeys.label, {
    params: {
      ...createPaginationParams(page, pageSize),
      sort: 'name',
    },
  }).then((response) => {
    return mapLabelsResponse({
      ...response.data,
    });
  });

export const createLabel = (
  data: TTranslationsObject<{
    name: string;
  }>,
) =>
  post<TLabel>(labelsApiKeys.label, { label: data }).then(
    (response) => response.data.data,
  );

export const editLabel = (data: TLabel) =>
  patch<TLabel>(`${labelsApiKeys.labelWithId(data.id)}`, { label: data }).then(
    (response) => response.data.data,
  );

export const deleteLabel = (labelId: TEntityId) =>
  del(`${labelsApiKeys.labelWithId(labelId)}`).then(
    (response) => response.data.data,
  );
