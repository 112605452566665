import { TPlayerTeamAchievement } from 'entities/playerTeamAchievements/types';
import { TMetaType } from 'shared/types/common';

type TMapPlayerTeamAchievementsResponseArgs = {
  data: TPlayerTeamAchievement[];
  meta?: TMetaType<true>;
};

export const mapPlayerTeamAchievementsResponse = ({
  data: playerTeamAchievements,
  meta,
}: TMapPlayerTeamAchievementsResponseArgs) => {
  const page = meta!.page;

  return {
    total: page.total_entries,
    page: page.number,
    playerTeamAchievements,
  };
};
