import cn from 'classnames';
import { format } from 'date-fns';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { Button } from 'shared/components/Button/Button';
import { TimeControl } from 'shared/components/DatePicker/components/TimeControl';
import { getGMTOffset } from 'shared/components/DatePicker/utils';
import { Field } from 'shared/components/Form';
import { Icon } from 'shared/components/Icon/Icon';
import { insertStringAt } from 'shared/utils/string';

import formCommonStyles from '../../../Form/FormCommonStyles.module.css';
import styles from './Footer.module.css';

type TProps = {
  date?: Date;
  onCancel: (e: MouseEvent<HTMLButtonElement>) => void;
  onConfirm: (date: Date) => void;
  onTimeChange: (date: Date) => void;
};

const gmtOffset = getGMTOffset();

export const Footer = ({ date, onCancel, onTimeChange, onConfirm }: TProps) => {
  const [timeValue, setTimeValue] = useState<string>(
    date ? format(date, 'HH:mm') : '00:00',
  );
  const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const time = event.target.value;
    if (!date) {
      setTimeValue(time);
      return;
    }
    const [hours, minutes] = time.split(':').map((str) => parseInt(str, 10));
    const newSelectedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      minutes,
    );
    onTimeChange(newSelectedDate);
    setTimeValue(time);
  };

  const handleConfirm = () => {
    if (onConfirm && date) {
      onConfirm(date);
    }
  };

  const addMinute = () => {
    const [hours, minutes] = timeValue.split(':').map((str) => parseInt(str, 10));
    let newMinute = minutes + 1;
    let newHour = hours;
    if (newMinute > 59) {
      newHour = newHour + 1;
      newMinute = 0;
    }
    if (newHour > 23) {
      newHour = 0;
    }
    if (date) {
      const newSelectedDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        newHour,
        newMinute,
      );
      onTimeChange(newSelectedDate);
    }
    setTimeValue(
      `${newHour.toLocaleString('ru-RU', {
        minimumIntegerDigits: 2,
      })}:${newMinute.toLocaleString('ru-RU', { minimumIntegerDigits: 2 })}`,
    );
  };

  const subMinute = () => {
    const [hours, minutes] = timeValue.split(':').map((str) => parseInt(str, 10));
    let newMinute = minutes - 1;
    let newHour = hours;
    if (newMinute < 0) {
      newHour = newHour - 1;
      newMinute = 59;
    }
    if (newHour < 0) {
      newHour = 23;
    }
    if (date) {
      const newSelectedDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        newHour,
        newMinute,
      );
      onTimeChange(newSelectedDate);
    }
    setTimeValue(
      `${newHour.toLocaleString('ru-RU', {
        minimumIntegerDigits: 2,
      })}:${newMinute.toLocaleString('ru-RU', { minimumIntegerDigits: 2 })}`,
    );
  };

  const handleBlur = () => {
    if (!timeValue) {
      setTimeValue('00:00');
      if (date) {
        onTimeChange(
          new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0),
        );
      }
    }
  };

  const cName = cn(
    formCommonStyles.formControl,
    formCommonStyles.l,
    styles.timeField,
  );

  return (
    <div className={styles.container}>
      <div className={styles.timePicker}>
        <Icon kind="clock" size="s" />
        <Field className={styles.field}>
          <>
            <input
              className={cName}
              type="time"
              value={timeValue}
              onBlur={handleBlur}
              onChange={handleTimeChange}
            />
            <TimeControl add={addMinute} sub={subMinute} />
          </>
        </Field>
        <span>({insertStringAt(gmtOffset, 6, ':')})</span>
      </div>
      <div className={styles.buttons}>
        <Button color="gray" kind="outlined" onClick={onCancel}>
          Отменить
        </Button>
        <Button isDisabled={!date} onClick={handleConfirm}>
          Подтвердить
        </Button>
      </div>
    </div>
  );
};
