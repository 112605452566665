import { useManagerSocialNetworksActions } from 'pages/manager/ManagerSocialNetworksPage/hooks/useManagerSocialNetworksActions';
import { TUseManagerSocialNetworksActions } from 'pages/manager/ManagerSocialNetworksPage/types';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';

export const ManagerSocialNetworksActions = (
  props: TUseManagerSocialNetworksActions,
) => {
  const { actionsProps, deleteModalProps } = useManagerSocialNetworksActions(props);

  return (
    <>
      <Actions {...actionsProps} />
      {deleteModalProps.isOpened && <ConfirmationModal {...deleteModalProps} />}
    </>
  );
};
