import { useQuery } from '@tanstack/react-query';
import {
  fetchManagerCareers,
  TFetchManagerCareersParams,
} from 'entities/managerCareers/api/api';
import { managerCareersQueryKeys } from 'entities/managerCareers/api/consts';

export const useFetchManagerCareersQuery = ({
  page = 1,
  filter,
}: TFetchManagerCareersParams) => {
  return useQuery({
    queryKey: [managerCareersQueryKeys.managerCareers, page, filter],
    queryFn: () => fetchManagerCareers({ page, filter }),
    cacheTime: 0,
  });
};
