import { useLocales } from 'entities/config/lib/useLocales';
import { TStoryGroupData } from 'entities/stories/types';
import { getStatusByPublicationDate } from 'feature/story/getStatusByPublicationDate';
import { findLastIndex } from 'lodash';
import { DateTimeCell } from 'pages/Stories/StoriesList/components/DateTimeCell';
import styles from 'pages/Stories/StoriesList/StoriesList.module.css';
import { TStoryGroupActionsByStatus } from 'pages/Stories/StoriesList/types';
import { Fragment } from 'react';
import actionsStyles from 'shared/components/Actions/Actions.module.css';
import { ActionsWrapper } from 'shared/components/Actions/ActionsWrapper';
import { Button } from 'shared/components/Button/Button';
import { Divider } from 'shared/components/Divider';
import { Icon } from 'shared/components/Icon/Icon';
import { Table } from 'shared/components/SimpleTable';
import { Status } from 'shared/components/Status';
import { Text } from 'shared/components/Text/Text';

type TProps = {
  storyGroup: TStoryGroupData;
  activeStoryGroup: TStoryGroupData | null;
  actions: TStoryGroupActionsByStatus;
  onActionsClick: (storyGroup: TStoryGroupData) => void;
};

export const StoryGroupRow = ({
  storyGroup,
  activeStoryGroup,
  actions,
  onActionsClick,
}: TProps) => {
  const { defaultLocale } = useLocales();
  const isActionsOpened = activeStoryGroup?.id === storyGroup.id;
  const handleActionsClick = () => {
    onActionsClick(storyGroup);
  };
  const status = getStatusByPublicationDate(storyGroup.published);

  return (
    <Table.Row
      key={storyGroup.id}
      className={styles.rowGridTemplate}
      isActive={activeStoryGroup?.id === storyGroup.id}
      padding="s"
    >
      <Table.Cell className={styles.publicationNameColumn}>
        {storyGroup.preview.x1 ? (
          <div>
            <img alt="Preview Image" src={storyGroup.preview.x1} />
          </div>
        ) : (
          <div>
            <Icon kind="picture" size="s" />
          </div>
        )}
        <Text>{storyGroup.translations[defaultLocale]?.name}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text className={styles.screensCount} weight="SemiBold">
          {storyGroup.stories.length}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <DateTimeCell dateTimeString={storyGroup.createdAt} />
      </Table.Cell>
      <Table.Cell>{storyGroup.id}</Table.Cell>
      <Table.Cell>
        <Status status={status} />
      </Table.Cell>
      <Table.Cell>
        <DateTimeCell dateTimeString={storyGroup.published} />
      </Table.Cell>
      <Table.Cell className={styles.viewsCount}>
        <Icon kind="eye" size="xs" />
        <Text>{storyGroup.viewsCount}</Text>
      </Table.Cell>
      <Table.Cell>
        {/* TODO: Заиспользовать новый компонент Actions */}
        <ActionsWrapper isOpen={isActionsOpened} onIconClick={handleActionsClick}>
          {actions[status].map((action, index, actions) => (
            <Fragment key={`${storyGroup.id}-${index}`}>
              <Button
                className={actionsStyles.actionButton}
                icon={action.icon}
                iconPosition="before"
                kind="basic"
                onClick={action.onClick}
              >
                {action.title}
              </Button>
              {index !== findLastIndex(actions) && <Divider />}
            </Fragment>
          ))}
        </ActionsWrapper>
      </Table.Cell>
    </Table.Row>
  );
};
