import { TNewsCategory } from 'entities/newsCategories/types';
import { TLangType, TMetaType } from 'shared/types/common';

type TMapNewsResponseArgs = {
  data: TNewsCategory[];
  meta?: TMetaType<true>;
  defaultLocale: TLangType;
};

export const mapNewsCategoriesResponse = ({
  data: categories,
  meta,
}: TMapNewsResponseArgs) => {
  const page = meta?.page;

  return {
    total: page?.total_entries,
    page: page?.number,
    categories,
  };
};
