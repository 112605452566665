import { videoCategoriesApiKeys } from 'entities/videoCategories/consts';
import { mapVideoCategoriesResponse } from 'entities/videoCategories/mapper/mapVideoCategoriesResponse';
import {
  TCreateVideoCategoryDTO,
  TFetchVideoCategories,
  TVideoCategory,
  TVideoEditCategory,
} from 'entities/videoCategories/types';
import { del, get, patch, post } from 'shared/api/axios';
import { createPaginationParams } from 'shared/api/utils';
import { TEntityId } from 'shared/types/common';

export const fetchVideoCategories = ({
  page,
  pageSize,
  sort = 'name',
  defaultLocale,
}: TFetchVideoCategories) =>
  get<TVideoCategory[], true>(videoCategoriesApiKeys.getVideoCategories, {
    params: {
      ...createPaginationParams(page, pageSize),
      sort,
    },
  }).then((response) => {
    return mapVideoCategoriesResponse({
      ...response.data,
      defaultLocale,
    });
  });

export const createVideoCategory = (data: TCreateVideoCategoryDTO) =>
  post<TVideoCategory>(videoCategoriesApiKeys.createVideoCategory, {
    videoCategory: data,
  }).then((response) => response.data.data);

export const deleteVideoCategory = (categoryId: TEntityId) =>
  del(videoCategoriesApiKeys.deleteVideoCategory(categoryId)).then(
    (response) => response.data.data,
  );

export const editVideoCategory = ({ categoryId, data }: TVideoEditCategory) =>
  patch(videoCategoriesApiKeys.editVideoCategory(categoryId), {
    videoCategory: data,
  }).then((response) => response.data.data);
