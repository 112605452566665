import { teamsApiKeys } from 'entities/teams/consts';
import { mapTeamsResponse } from 'entities/teams/mapper/mapTeamsResponse';
import { TCreateTeamDTO, TEditTeamDTO, TTeam } from 'entities/teams/types';
import { del, get, patch, post } from 'shared/api/axios';
import { TCreatePaginationParamsReturnType } from 'shared/api/utils';
import { TEntityId } from 'shared/types/common';

export type TFetchTeamParams = {
  paginationParams: TCreatePaginationParamsReturnType;
};

export const fetchTeams = (paginationParams: TCreatePaginationParamsReturnType) =>
  get<TTeam[], true>(teamsApiKeys.getTeams, {
    params: paginationParams,
  }).then((response) => mapTeamsResponse(response.data));

export const fetchTeam = (id: TEntityId) =>
  get<TTeam>(teamsApiKeys.getTeam(id)).then((response) => response.data.data);

export const createTeam = (teamDTO: TCreateTeamDTO) =>
  post(teamsApiKeys.createTeam, teamDTO);

export const editTeam = ({ teamId, ...teamDTO }: TEditTeamDTO) =>
  patch(teamsApiKeys.editTeam(teamId), teamDTO);

export const deleteTeam = (teamId: TEntityId) =>
  del(teamsApiKeys.deleteTeam(teamId));
