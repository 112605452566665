import { TCreateManagerDTO } from 'entities/managers/model/types';
import { mapFileToUpload } from 'shared/api/mappers';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toNumber } from 'shared/lib/toNumber';
import { TLangType } from 'shared/types/common';
import { TManagerFormFields } from 'widgets/manager-form/types';

export const mapManagerFormFieldsToDTO = async (
  managerFormFields: TManagerFormFields,
  locales: TLangType[],
): Promise<TCreateManagerDTO> => {
  return {
    status: managerFormFields.status,
    discardedAt:
      managerFormFields.discarded === 'true' && managerFormFields.discardedAt
        ? managerFormFields.discardedAt.toISOString()
        : undefined,
    managerPositionId: toNumber(managerFormFields.managerPositionId),
    teamId: toNumber(managerFormFields.teamId),
    countryId: toNumber(managerFormFields.countryId),
    debutDate: managerFormFields.debutDate
      ? managerFormFields.debutDate.toISOString()
      : undefined,
    birthDate: managerFormFields.birthDate
      ? managerFormFields.birthDate.toISOString()
      : undefined,
    translations: reduceTranslationObject(locales, (locale) => ({
      firstName: managerFormFields.firstName[locale],
      lastName: managerFormFields.lastName[locale],
      biography: managerFormFields.biography
        ? managerFormFields.biography[locale]
        : undefined,
      sharingLink: managerFormFields.sharingLink
        ? managerFormFields.sharingLink
        : undefined,
      shopLink: managerFormFields.shopLink ? managerFormFields.shopLink : undefined,
    })),
    picture: managerFormFields.picture
      ? await mapFileToUpload(managerFormFields.picture)
      : undefined,
    photo: managerFormFields.photo
      ? await mapFileToUpload(managerFormFields.photo)
      : undefined,
  };
};
