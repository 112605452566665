import { useDeletePlayerCareerMutation } from 'entities/playerCareers/queries';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useDeletePlayerCareer = () => {
  const { mutate: deletePlayerCareerMutation, isLoading } =
    useDeletePlayerCareerMutation();

  const deletePlayerCareer = (activeCareerId: TEntityId) => {
    deletePlayerCareerMutation(activeCareerId, {
      onError: () => notify('Не удалось удалить карьеру игрока', { type: 'error' }),
    });
  };

  return {
    isDeletingPlayerCareer: isLoading,
    deletePlayerCareer,
  };
};
