// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmptyList-module__root--pTLNJ {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 12px;\n}\n\n  .EmptyList-module__root--pTLNJ button {\n    width: unset;\n    padding-left: 16px;\n    padding-right: 16px;\n  }\n", "",{"version":3,"sources":["webpack://./src/2-pages/Stories/StoriesList/components/EmptyList/EmptyList.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;AAOX;;EALE;IACE,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;EACrB","sourcesContent":[".root {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 12px;\n\n  button {\n    width: unset;\n    padding-left: 16px;\n    padding-right: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "EmptyList-module__root--pTLNJ"
};
export default ___CSS_LOADER_EXPORT___;
