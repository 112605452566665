import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { playersQueryKeys } from 'entities/players/api/consts';
import { TPlayersResponse } from 'entities/players/api/mapper/mapFetchPlayersResponse';
import { TPlayer } from 'entities/players/model/types';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

import { fetchPlayer, fetchPlayers, TFetchPlayersArgs } from './api';

export const useFetchPlayersQuery = (
  { paginationParams, filter }: TFetchPlayersArgs,
  options: UseQueryOptions<TPlayersResponse, unknown, TPlayersResponse> = {},
) =>
  useQuery({
    queryKey: [playersQueryKeys.players, paginationParams, filter],
    queryFn: () => fetchPlayers({ paginationParams, filter }),
    ...options,
  });

export const useFetchPlayerQuery = (
  id: TEntityId = '',
  options: UseQueryOptions<TPlayer, unknown, TPlayer> = {},
) =>
  useQuery({
    queryKey: [playersQueryKeys.player, id],
    queryFn: () => fetchPlayer(id),
    enabled: toBoolean(id),
    ...options,
  });
