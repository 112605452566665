import cn from 'classnames';
import { useLocales } from 'entities/config/lib/useLocales';
import { MouseEvent } from 'react';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Text } from 'shared/components/Text/Text';
import { TLangType, TTranslationsMapper } from 'shared/types/common';

import styles from './LocalesControl.module.css';

const localeNames: TTranslationsMapper = {
  ru: 'Русский яз.',
  en: 'Английский яз.',
  de: 'Немецкий яз.',
  fr: 'Французский яз.',
};

type TProps = {
  locale: TLangType;
  isCreated: boolean;
  isDisabled: boolean;
  isSelected: boolean;
  onLocaleSelect: (locale: TLangType) => void;
  onLocaleAdd: (locale: TLangType) => void;
  onLocaleDelete: (locale: TLangType) => void;
};

export const LocaleControl = ({
  locale,
  isCreated,
  isDisabled,
  isSelected,
  onLocaleSelect,
  onLocaleAdd,
  onLocaleDelete,
}: TProps) => {
  const { defaultLocale } = useLocales();

  const handleLocaleClick = () => {
    if (isDisabled) {
      return;
    }
    onLocaleSelect(locale);
  };
  const handleButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (isDisabled) {
      return;
    }
    if (isCreated) {
      return onLocaleDelete(locale);
    }
    return onLocaleAdd(locale);
  };
  return (
    <div
      className={cn(styles.localeControl, {
        [styles.isSelected]: isSelected,
        [styles.isDisabled]: isDisabled,
        [styles.selectable]: isCreated,
      })}
      onClick={handleLocaleClick}
    >
      <Text>{localeNames[locale]}</Text>
      {locale !== defaultLocale && (
        <IconButton
          className={styles.button}
          disabled={isDisabled}
          icon={isCreated ? 'x' : 'plus'}
          iconSize="s"
          onClick={handleButtonClick}
        />
      )}
    </div>
  );
};
