// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlayerCareersActions-module__dropdown--l_6D9 {\n  padding: 0 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/player/PlayerCareersPage/PlayerCareersActions/PlayerCareersActions.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB","sourcesContent":[".dropdown {\n  padding: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "PlayerCareersActions-module__dropdown--l_6D9"
};
export default ___CSS_LOADER_EXPORT___;
