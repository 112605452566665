import { useFetchPlayersQuery } from 'entities/players/api/queries';
import { DEFAULT_PAGINATION_TEAM_SIZE } from 'pages/team/constants';
import { createPaginationParams } from 'shared/api/utils';
import { notify } from 'shared/components/Notification';

export const usePlayers = (selectedPage: number, teamId: string | undefined) => {
  const { data, isLoading } = useFetchPlayersQuery(
    {
      paginationParams: createPaginationParams(
        selectedPage,
        DEFAULT_PAGINATION_TEAM_SIZE,
      ),
      filter: teamId ? { 'filter[team_id][eq]': teamId } : {},
    },
    { cacheTime: 0, onError: () => notify('Не удалось получить список игроков') },
  );

  const isShowPagination =
    (data?.total ?? 0) > 0 &&
    (data?.total ?? 0) > DEFAULT_PAGINATION_TEAM_SIZE &&
    !isLoading;

  return {
    playersList: data,
    isShowPagination,
  };
};
