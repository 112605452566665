import { PlayerCareerModal } from 'feature/player-career-modal/PlayerCareerModal';
import { usePlayerCareersActions } from 'pages/player/PlayerCareersPage/hooks/usePlayerCareersActions';
import { TPlayerCareersActions } from 'pages/player/PlayerCareersPage/types';
import React, { Fragment } from 'react';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';

import styles from './PlayerCareersActions.module.css';

export const PlayerCareersAction = (props: TPlayerCareersActions) => {
  const {
    actions,
    isDeleteModalOpen,
    isDeletingPlayerCareer,
    isActive,
    handleActionsButtonClick,
    handleConfirmDeletion,
    handleCloseDeleteModal,
    editCareerModalProps,
  } = usePlayerCareersActions(props);

  return (
    <>
      <Actions
        actions={actions}
        activeItemId={props.activeCareerId}
        className={styles.dropdown}
        isLoading={isDeletingPlayerCareer}
        isOpen={isActive}
        onIconClick={handleActionsButtonClick}
      />
      <ConfirmationModal
        description={'После удаления, содержимое \n будет утеряно навсегда'}
        isOpened={isDeleteModalOpen}
        title="Удаление карьеры"
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDeletion}
      />
      <PlayerCareerModal {...editCareerModalProps} />
    </>
  );
};
