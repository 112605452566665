import { CSSProperties, useEffect, useRef, useState } from 'react';

import styles from './TextElement.module.css';

type TProps = {
  style: CSSProperties;
  initialFontSize: number;
  text: string;
};

const FONT_SCALING_STEP = 0.5;

export const TextElementPreview = ({ style, text, initialFontSize }: TProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);
  const isScaling = useRef(true);
  const [scaledFontSize, setScaledFontSize] = useState(initialFontSize);

  useEffect(() => {
    // TODO: This logic causes a lot of rerenders. Research for possible optimization
    if (containerRef.current && textRef.current && isScaling.current) {
      const container = containerRef.current;
      const text = textRef.current;
      const containerHeight = container.offsetHeight;
      const textHeight = text.offsetHeight;

      // Increase font size if text is smaller than container
      if (textHeight < containerHeight) {
        setScaledFontSize((prevSize) => prevSize + FONT_SCALING_STEP);
      }
      // Once text is bigger than container, decrease font size by 1 step and stop scaling
      if (container.scrollHeight > container.offsetHeight) {
        setScaledFontSize((prevSize) => prevSize - FONT_SCALING_STEP);
        isScaling.current = false;
      }
    }
  }, [containerRef.current, textRef.current, scaledFontSize]);

  return (
    <div
      className={styles.previewText}
      ref={containerRef}
      style={{ ...style, fontSize: scaledFontSize }}
    >
      <span ref={textRef}>{text}</span>
    </div>
  );
};
