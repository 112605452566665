import cn from 'classnames';
import { useLocales } from 'entities/config/lib/useLocales';
import { noop } from 'lodash';
import { useToggle } from 'react-use';
import { Button } from 'shared/components/Button/Button';
import { Input } from 'shared/components/Form/Input/Input';
import { TInputOwnProps } from 'shared/components/Form/types';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { TTranslations } from 'shared/types/common';

import styles from './LocalesInput.module.css';

type TBaseProps = {
  title: string;
  value?: TTranslations<string>;
  onChange: (value: TTranslations<string>) => void;
  previewInputPlaceholder?: string;
  inputClassName?: string;
};

export type TLocalesInputProps = TBaseProps &
  Omit<TInputOwnProps, 'onChange' | 'value' | 'onClick'>;

export const LocalesInput = ({
  title,
  value,
  onChange,
  label,
  maxLength,
  previewInputPlaceholder,
  className,
  inputClassName,
  ...inputProps
}: TLocalesInputProps) => {
  const { defaultLocale } = useLocales();
  const localizationValue = value?.[defaultLocale] ?? '';
  const [isOpened, toggleIsOpened] = useToggle(false);

  return (
    <div className={cn(styles.root, className)}>
      <Input
        disabled
        value={localizationValue}
        onChange={noop}
        {...inputProps}
        className={cn(styles.field, inputClassName)}
        inputClassName={styles.input}
        label={label}
        placeholder={previewInputPlaceholder}
      />
      <Button
        className={styles.button}
        color="green"
        kind="ghost"
        size="s"
        onClick={toggleIsOpened}
      >
        {localizationValue ? 'Редактировать' : 'Заполнить'}
      </Button>
      <LocalizationModal
        actionSubmit={onChange}
        closeModal={() => toggleIsOpened(false)}
        createInitialValue={(locale) => value?.[locale] ?? ''}
        inputProps={{ ...inputProps, maxLength }}
        isOpened={isOpened}
        title={title}
      />
    </div>
  );
};
