import cn from 'classnames';
import { ReactNode } from 'react';
import { Button, TButtonProps } from 'shared/components/Button/Button';
import {
  IconButton,
  TIconButtonProps,
} from 'shared/components/IconButton/IconButton';
import { Text } from 'shared/components/Text/Text';
import { SecondLayoutNavigation } from 'shared/ui/layouts/second-layout/SecondLayoutNavigation/SecondLayoutNavigation';
import { TSecondLayoutNavigationLink } from 'shared/ui/layouts/second-layout/types';

import styles from './SecondLayout.module.css';

type TProps = {
  title: string;
  confirmButtonProps?: TButtonProps;
  backButtonProps: Omit<TIconButtonProps, 'icon' | 'iconSize'>;
  navigationLinks: TSecondLayoutNavigationLink[];
  children: ReactNode;
};

export const SecondLayout = ({
  title,
  backButtonProps,
  confirmButtonProps,
  children,
  navigationLinks,
}: TProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>
          <IconButton icon="chevron-left" iconSize="l" {...backButtonProps} />
          <Text color="var(--gray-450)" size={20} weight="Bold">
            {title}
          </Text>
        </div>
        <Button
          {...confirmButtonProps}
          className={cn(styles.confirmButton, confirmButtonProps?.className)}
        >
          Подтвердить
        </Button>
      </div>
      <div className={styles.content}>
        <SecondLayoutNavigation links={navigationLinks} />
        {children}
      </div>
    </div>
  );
};
