import { TManagerTeamAchievement } from 'entities/managerTeamAchievements/types';
import { ManagerTeamAchievementsActions } from 'pages/manager/ManagerTeamAchievementsPage/ManagerTeamAchievementsActions/ManagerTeamAchievementsActions';
import React from 'react';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elements-list-table/types';

import styles from './ManagerTeamAchievementsPage.module.css';

export const getManagerTeamAchievementsColumnsConfig: TConfigCreator<
  TManagerTeamAchievement
> = (defaultLocale, activeSocialLinkId, setActiveSocialLinkId) => [
  {
    title: 'Название',
    cellClassName: styles.nameColumn,
    render: (item) => (
      <Text className={styles.text} color="var(--gray-500)">
        {item.translations?.[defaultLocale]?.title}
      </Text>
    ),
  },
  {
    title: 'Год',
    render: (item) => <Text color="var(--gray-500)">{item.year}</Text>,
  },
  {
    title: 'ID',
    render: (item) => <Text color="var(--gray-500)">{item.id}</Text>,
  },
  {
    title: '',
    render: (managerTeamAchievement) => (
      <ManagerTeamAchievementsActions
        activeItemId={activeSocialLinkId}
        managerTeamAchievement={managerTeamAchievement}
        setActiveItemId={setActiveSocialLinkId}
      />
    ),
  },
];
