import React from 'react';
import { Button } from 'shared/components/Button/Button';

type TProps = {
  onClick: () => void;
  disabled?: boolean;
};

export const NextButton = ({ onClick, disabled }: TProps) => {
  return (
    <Button
      color="green"
      isDisabled={disabled}
      kind="outlined"
      size="s"
      onClick={onClick}
    >
      Продолжить
    </Button>
  );
};
