import cn from 'classnames';
import { createElement, forwardRef } from 'react';
import { TTextColor } from 'shared/components/Text/types';
import { TWithOptionalChildren } from 'shared/types/common';

import styles from './Text.module.css';

type TTagName = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';

export type TTextProps = {
  className?: string;
  size?: 10 | 12 | 14 | 16 | 18 | 20 | 24 | 30 | 36 | 40;
  weight?: 'Regular' | 'SemiBold' | 'Bold';
  decoration?: 'underline' | 'none';
  tag?: TTagName;
  color?: TTextColor;
} & TWithOptionalChildren;

export const Text = forwardRef<HTMLElementTagNameMap[TTagName], TTextProps>(
  (
    {
      size = 12,
      weight = 'Regular',
      decoration = 'none',
      tag = 'span',
      className,
      children,
      color,
    },
    ref,
  ) =>
    createElement(
      tag,
      {
        ref,
        className: cn(
          styles[`fontSize${size}`],
          styles[`fontWeight${weight}`],
          styles[decoration],
          className,
        ),
        style: {
          color,
        },
      },
      children,
    ),
);
