import { allOperations } from 'entities/user/model/consts';
import { TSettingsNavigationLink } from 'feature/settings-navigation/types';
import { routes } from 'shared/routes';

export const settingsNavigationLinks: TSettingsNavigationLink[] = [
  {
    name: 'Категории',
    subLinks: [
      {
        name: 'Категории видео',
        route: routes.categoryVideo,
        permissions: { VideoCategory: allOperations },
      },
      {
        name: 'Категории новости',
        route: routes.categoryNews,
        permissions: { NewsCategory: allOperations },
      },
      {
        name: 'Категории фотогалереи',
        route: routes.categoryPhotogallery,
        permissions: { PhotoGalleryCategory: allOperations },
      },
    ],
  },
  {
    name: 'Лейблы',
    subLinks: [
      {
        name: 'Список лейблов',
        route: routes.labels,
        permissions: { Label: allOperations },
      },
    ],
  },
];
