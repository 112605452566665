import { noop } from 'lodash';
import { useDeleteManagerCareer } from 'pages/manager/ManagerCareersPage/hooks/useDeleteManagerCareer';
import { TManagerCareersActions } from 'pages/manager/ManagerCareersPage/types';
import { useBoolean } from 'react-use';
import { TGroupAction } from 'shared/types/common';

export const useManagerCareersActions = ({
  activeCareerId,
  setActiveCareerId,
  career,
}: TManagerCareersActions) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const { isDeletingManagerCareer, deleteManagerCareer } = useDeleteManagerCareer();
  const isActive = career.id === activeCareerId;

  const actions: TGroupAction[] = [
    {
      title: 'Редактировать',
      icon: 'edit-02',
      action: noop,
    },
    {
      title: 'Удалить',
      icon: 'trash',
      action: () => {
        setIsDeleteModalOpen(true);
      },
    },
  ];

  const handleActionsButtonClick = () => {
    setActiveCareerId(isActive ? null : career.id);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setActiveCareerId(null);
  };

  const handleConfirmDeleteCareer = () => {
    handleCloseDeleteModal();
    deleteManagerCareer(career.id);
  };

  return {
    isDeletingManagerCareer,
    isDeleteModalOpen,
    actions,
    isActive,
    handleActionsButtonClick,
    handleConfirmDeleteCareer,
    handleCloseDeleteModal,
  };
};
