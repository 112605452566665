import { TRoutesWithPermission, TRouteWithPermission } from 'app/types';
import { usePermissions } from 'entities/user/lib/usePermissions';
import { TUserResource } from 'entities/user/model/types';

export const useProtectedRoutes = (routes: TRoutesWithPermission) => {
  const canUser = usePermissions();

  const filterRoutes = (routes: TRoutesWithPermission): TRoutesWithPermission =>
    routes.reduce((acc: TRoutesWithPermission, route: TRouteWithPermission) => {
      // Если для роута не прописаны права, то считаем, что пользователь имеет к нему доступ
      // Если права прописаны, то сверяем их с правами, полученными от сервера.
      const hasPermission =
        !route.permissions ||
        (route.permissions &&
          (Object.keys(route.permissions) as TUserResource[]).every((resource) => {
            const operations = route.permissions && route.permissions[resource];
            if (operations) {
              return canUser(operations, [resource]);
            }

            return false;
          }));

      if (!hasPermission) {
        return acc;
      }

      if (route.children) {
        return [...acc, { ...route, children: filterRoutes(route.children) }];
      }
      return [...acc, route];
    }, []);

  return filterRoutes(routes);
};
