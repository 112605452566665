import { TManagerCareer } from 'entities/managerCareers/model/types';
import { TMetaType } from 'shared/types/common';

type TMapManagerCareersResponseParams = {
  data: TManagerCareer[];
  meta?: TMetaType<true>;
};

export const mapManagerCareersResponse = ({
  data: careers,
  meta,
}: TMapManagerCareersResponseParams) => {
  const page = meta?.page;

  return {
    total: page?.total_entries,
    page: page?.number,
    careers,
  };
};
