import { TEXT_MIN_LENGTH_MESSAGE } from 'shared/consts';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TLangType } from 'shared/types/common';
import { fileZodValidation } from 'shared/zod/fileZodValidation';
import z from 'zod';

const TEXT_MAX_NUMBER_LENGTH_MESSAGE = 'Не может быть больше 999';

const localesConverter = (locales: TLangType[]) => {
  return reduceTranslationObject(locales, () =>
    z.object({ teamName: z.string().min(1, TEXT_MIN_LENGTH_MESSAGE) }),
  );
};

export const playerCareerModalValidationSchema = (locales: TLangType[]) =>
  z.object({
    translations: z.object({ ...localesConverter(locales) }),
    teamPicture: fileZodValidation('Загрузите превью-изображение'),
    season: z.string().min(1, TEXT_MIN_LENGTH_MESSAGE),
    championshipCount: z
      .string()
      .min(1, TEXT_MIN_LENGTH_MESSAGE)
      .max(3, TEXT_MAX_NUMBER_LENGTH_MESSAGE),
    cupsCount: z
      .string()
      .min(1, TEXT_MIN_LENGTH_MESSAGE)
      .max(3, TEXT_MAX_NUMBER_LENGTH_MESSAGE),
    eurocupsCount: z
      .string()
      .min(1, TEXT_MIN_LENGTH_MESSAGE)
      .max(3, TEXT_MAX_NUMBER_LENGTH_MESSAGE),
  });
