import { useEffect, useState } from 'react';
import { Icon } from 'shared/components/Icon/Icon';
import { ImageUploadModal } from 'shared/components/ImageUploadModal/ImageUploadModal';
import { getUrlFromFile, removeUrlFromMemory } from 'shared/lib/getUrlFromFile';

import styles from './UploadAvatar.module.css';

type TProps = {
  avatar?: File;
  onLoadAvatar: (avatar: File) => void;
  aspectRatio: number;
};

export const UploadAvatar = ({ avatar, onLoadAvatar, aspectRatio }: TProps) => {
  const [openLoadAvatarModal, setOpenLoadAvatarModal] = useState(false);
  const [filePreview, setFilePreview] = useState<string>(
    avatar ? getUrlFromFile(avatar) : '',
  );

  useEffect(() => {
    setFilePreview(avatar ? getUrlFromFile(avatar) : '');
    removeUrlFromMemory(filePreview);
  }, [avatar]);

  useEffect(() => {
    return () => {
      removeUrlFromMemory(filePreview);
    };
  }, []);

  const closeModal = () => {
    setOpenLoadAvatarModal(false);
  };

  const onClickHandler = () => {
    setOpenLoadAvatarModal(true);
  };

  const onConfirm = (files: File[]) => {
    const file = files[0];
    onLoadAvatar(file);
    const url = getUrlFromFile(file);
    removeUrlFromMemory(filePreview);
    setFilePreview(url);
    closeModal();
  };

  return (
    <>
      <div className={styles.root} onClick={onClickHandler}>
        {filePreview ? (
          <>
            <img alt="avatar" src={filePreview} />
            <div className={styles.editWrapper}>
              <Icon className={styles.editIcon} kind="edit-02" />
            </div>
          </>
        ) : (
          <div className={styles.addWrapper}>
            <Icon className={styles.addIcon} kind="image-plus" />
          </div>
        )}
      </div>
      <ImageUploadModal
        aspectRatio={aspectRatio}
        opened={openLoadAvatarModal}
        title="Настройка изображения"
        withCropping
        onClose={closeModal}
        onConfirm={onConfirm}
      />
    </>
  );
};
