import { RawAxiosRequestHeaders } from 'axios';
import JSONAPISerializer from 'json-api-serializer';
import { API_VERSION } from 'shared/api/constants';
import { Serializer } from 'shared/api/serializer';
import { useTokenStore } from 'shared/api/token';
import { TAPIError } from 'shared/api/types';
import { notify } from 'shared/components/Notification';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'shared/consts';
import { toString } from 'shared/lib/toString';

const entitiesTypes = new Set();

export const registerJsonApiType = (type: string | undefined) => {
  if (type && !entitiesTypes.has(type)) {
    Serializer.register(type);
    entitiesTypes.add(type);
  }
};

export const registerJsonApiRelationshipTypes = (
  relationships: { [p: string]: JSONAPISerializer.Relationship } | undefined,
) => {
  for (const key in relationships) {
    const value = relationships[key];

    if (value.data) {
      const type = Array.isArray(value.data)
        ? value.data?.[0]?.type
        : value.data.type;

      registerJsonApiType(type);
    }
  }
};

export const getHeaders = (): RawAxiosRequestHeaders => {
  const { token } = useTokenStore.getState();

  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token}` : undefined,
    'x-api-version': API_VERSION,
  };
};

export const defaultErrorHandler = (reason: TAPIError) => {
  const message = reason.response?.data.errors
    ? reason.response?.data.errors
        .map((error) => {
          let title = error.title;
          if (error.details) {
            title += `: ${error.attribute} ${error.details}`;
          }
          return title;
        })
        .join(', ')
    : reason.message;
  notify(message, { type: 'error' });
};

export const createPageNumberParam = (pageNumber: number) => {
  return { 'page[number]': toString(pageNumber) };
};

export const createPageSizeParam = (
  pageSize: number = DEFAULT_PAGINATION_PAGE_SIZE,
) => {
  return { 'page[size]': toString(pageSize) };
};

export const createPaginationParams = (pageNumber: number, pageSize?: number) => {
  return {
    ...createPageNumberParam(pageNumber),
    ...createPageSizeParam(pageSize),
  };
};

export type TCreatePaginationParamsReturnType = ReturnType<
  typeof createPaginationParams
>;
