import { useFetchStoryGroupsQuery } from 'entities/stories/queries';
import { EmptyList } from 'pages/Stories/StoriesList/components/EmptyList/EmptyList';
import { useCreateStory } from 'pages/Stories/StoriesList/hooks/useCreateStory';
import { StoriesList } from 'pages/Stories/StoriesList/StoriesList';
import React from 'react';
import { ImageUploadModal } from 'shared/components/ImageUploadModal/ImageUploadModal';
import { Loader } from 'shared/components/Loader/Loader';
import { imageSettings } from 'shared/constants';

import styles from './StoriesList.module.css';

export const StoriesListWrapper = () => {
  const {
    isImageUploadOpened,
    handleFileAdd,
    handleCloseUploadModal,
    handleOpenUploadModal,
  } = useCreateStory();
  const { data: storyGroups = [], isLoading } = useFetchStoryGroupsQuery();

  if (isLoading) {
    return <Loader className={styles.loading} showLabel />;
  }

  return (
    <>
      {storyGroups.length === 0 && !isLoading ? (
        <EmptyList onCreateStories={handleOpenUploadModal} />
      ) : (
        <StoriesList
          isLoading={isLoading}
          storyGroups={storyGroups}
          onCreateStories={handleOpenUploadModal}
        />
      )}
      <ImageUploadModal
        aspectRatio={imageSettings.stories.background.aspectRatio}
        hint={imageSettings.stories.background.hint}
        opened={isImageUploadOpened}
        title="Загрузка изображения"
        withCropping
        onClose={handleCloseUploadModal}
        onConfirm={handleFileAdd}
      />
    </>
  );
};
