import { playerCareersApiKeys } from 'entities/playerCareers/consts';
import { mapPlayerCareersResponse } from 'entities/playerCareers/mapper/mapPlayerCareersResponse';
import {
  TFetchPlayerCareersParams,
  TPlayerCareer,
  TPlayerCreateCareerDTO,
  TPlayerEditCareerDTO,
} from 'entities/playerCareers/types';
import { del, get, patch, post } from 'shared/api/axios';
import { createPaginationParams } from 'shared/api/utils';
import { TEntityId } from 'shared/types/common';

export const fetchPlayerCareers = ({
  page,
  filter = {},
}: TFetchPlayerCareersParams) =>
  get<TPlayerCareer[], true>(playerCareersApiKeys.getPlayerCareers, {
    params: {
      ...createPaginationParams(page),
      ...filter,
    },
  }).then((response) => mapPlayerCareersResponse({ ...response.data }));

export const deletePlayerCareer = (careerId: TEntityId) =>
  del(playerCareersApiKeys.deleteCareer(careerId)).then(
    (response) => response.data.data,
  );

export const createPlayerCareer = (data: TPlayerCreateCareerDTO) =>
  post<TPlayerCareer>(playerCareersApiKeys.createPlayerCareer, data).then(
    (response) => response.data.data,
  );

export const editPlayerCareer = ({ careerId, ...data }: TPlayerEditCareerDTO) =>
  patch<TPlayerCareer>(playerCareersApiKeys.editCareer(careerId), data).then(
    (response) => response.data.data,
  );
