import { mapApiVideoPostToVideoPost } from 'entities/video/mapper/mapApiVideoPostToVideoPost';
import { TAPIVideoPost } from 'entities/video/types';
import { TLangType, TMetaType } from 'shared/types/common';

type TMapVideoPostsResponseArgs = {
  data: TAPIVideoPost[];
  meta?: TMetaType<true>;
  defaultLocale: TLangType;
};

export const mapVideoPostsResponse = ({
  data: video,
  meta,
  defaultLocale,
}: TMapVideoPostsResponseArgs) => {
  const page = meta!.page;

  return {
    total: page.total_entries,
    page: page.number,
    video: video.map((videoPost) =>
      mapApiVideoPostToVideoPost(videoPost, defaultLocale),
    ),
  };
};
