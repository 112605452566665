import { useQuery } from '@tanstack/react-query';
import {
  fetchManagerTeamAchievements,
  TFetchManagerTeamAchievementsParams,
} from 'entities/managerTeamAchievements/api';
import { managerTeamAchievementsQueryKeys } from 'entities/managerTeamAchievements/consts';

export const useFetchManagerTeamAchievementsQuery = ({
  page = 1,
  filter,
}: Partial<TFetchManagerTeamAchievementsParams>) => {
  return useQuery({
    queryKey: [
      managerTeamAchievementsQueryKeys.managerTeamAchievements,
      page,
      filter,
    ],
    queryFn: () => fetchManagerTeamAchievements({ page, filter }),
    cacheTime: 0,
  });
};
