import { TEntityId } from 'shared/types/common';

export const opponentTeamsQueryKeys = {
  opponentTeam: 'opponentTeam',
};

const opponentTeamsBaseUrl = 'opponent_teams';

export const opponentTeamsApiKeys = {
  getOpponentTeam: (id: TEntityId) => `${opponentTeamsBaseUrl}/${id}`,
};
