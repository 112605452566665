type TMapValuesToKeysIfAllowed<T> = {
  [K in keyof T]: T[K] extends PropertyKey ? K : never;
};
type TFilter<T> = TMapValuesToKeysIfAllowed<T>[keyof T];

// groupBy из lodash не даёт типизацию, поэтому создаем свою функцию
export const groupByProperty = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<PropertyKey, any>,
  Key extends TFilter<T>,
>(
  arr: T[],
  key: Key,
): Record<T[Key], T[]> =>
  arr.reduce(
    (accumulator, val) => {
      const groupedKey = val[key];
      if (!accumulator[groupedKey]) {
        accumulator[groupedKey] = [];
      }
      accumulator[groupedKey].push(val);
      return accumulator;
    },
    {} as Record<T[Key], T[]>,
  );
