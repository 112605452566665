import { useQuery } from '@tanstack/react-query';
import {
  fetchPlayerSocialNetworks,
  TFetchPlayerSocialNetworks,
} from 'entities/playerSocialNetworks/api';
import { playerSocialNetworksQueryKeys } from 'entities/playerSocialNetworks/consts';

export const useFetchPlayerSocialNetworksQuery = ({
  page = 1,
  filter,
}: Partial<TFetchPlayerSocialNetworks>) => {
  return useQuery({
    queryKey: [playerSocialNetworksQueryKeys.playerSocialNetworks, page, filter],
    queryFn: () => fetchPlayerSocialNetworks({ page, filter }),
    cacheTime: 0,
  });
};
