// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddNewsPostHeaderPanel-module__root--famRp {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 8px;\n  background-color: var(--bg-white);\n  padding: 12px 24px 12px 28px;\n  border-bottom: 1px solid var(--gray-200);\n  height: var(--second-header-height);\n  position: relative;\n}\n\n  .AddNewsPostHeaderPanel-module__root--famRp > *:nth-child(2) {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translateX(-50%) translateY(-50%);\n  }\n\n.AddNewsPostHeaderPanel-module__control--eUXP9 {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/NewsPostForm/components/AddNewsPostHeaderPanel/AddNewsPostHeaderPanel.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,QAAQ;EACR,iCAAiC;EACjC,4BAA4B;EAC5B,wCAAwC;EACxC,mCAAmC;EACnC,kBAAkB;AAQpB;;EANE;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,4CAA4C;EAC9C;;AAGF;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV","sourcesContent":[".root {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 8px;\n  background-color: var(--bg-white);\n  padding: 12px 24px 12px 28px;\n  border-bottom: 1px solid var(--gray-200);\n  height: var(--second-header-height);\n  position: relative;\n\n  > *:nth-child(2) {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translateX(-50%) translateY(-50%);\n  }\n}\n\n.control {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "AddNewsPostHeaderPanel-module__root--famRp",
	"control": "AddNewsPostHeaderPanel-module__control--eUXP9"
};
export default ___CSS_LOADER_EXPORT___;
