import { TVideoCategory } from 'entities/videoCategories/types';
import { TVideoCategoriesSortParams } from 'pages/settings/categories/VideoCategoriesPage/types';
import { VideoCategoriesActionWrapper } from 'pages/settings/categories/VideoCategoriesPage/VideoCategoriesActionWrapper';
import React from 'react';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elements-list-table/types';

import styles from './VideoCategoriesPage.module.css';

export const getVideoCategoriesColumnsConfig: TConfigCreator<
  TVideoCategory,
  TVideoCategoriesSortParams
> = (defaultLocale, activeItemId, setActiveItemId) => [
  {
    title: 'Категория',
    headCellProps: {
      sortParam: 'name',
      sortable: true,
    },
    render: (item) => (
      <>
        {item.picture ? (
          <div className={styles.previewWrapper}>
            <img
              alt={`${item?.translations[defaultLocale]?.name} preview`}
              className={styles.preview}
              src={item.picture.x1}
            />
          </div>
        ) : (
          <div className={styles.previewWrapper}>
            <Icon kind="picture" size="s" />
          </div>
        )}
        <Text className={styles.text}>
          {item?.translations[defaultLocale]?.name}
        </Text>
      </>
    ),
  },
  {
    title: 'Важность',
    cellClassName: styles.publicationNameColumn,
    headCellProps: {
      sortParam: 'importance',
      sortable: true,
    },
    render: (item) => <Text className={styles.importance}>{item?.importance}</Text>,
  },
  {
    title: '',
    render: (item) => (
      <VideoCategoriesActionWrapper
        activeItemId={activeItemId}
        setActiveItemId={setActiveItemId}
        videoCategory={item}
      />
    ),
  },
];
