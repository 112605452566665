import { TPlayerCareer } from 'entities/playerCareers/types';
import { PlayerCareersAction } from 'pages/player/PlayerCareersPage/PlayerCareersActions/PlayerCareersAction';
import React from 'react';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elements-list-table/types';

import styles from './PlayerCareersPage.module.css';

export const getPlayerCareersColumnsConfig: TConfigCreator<TPlayerCareer> = (
  defaultLocale,
  activeItemId,
  setActiveItemId,
) => [
  {
    title: 'Клуб',
    render: (item) => (
      <>
        <div className={styles.previewWrapper}>
          {item.teamPicture ? (
            <img
              alt={`${item?.translations[defaultLocale]?.teamName} preview`}
              src={item.teamPicture.x1}
            />
          ) : (
            <Icon kind="picture" size="s" />
          )}
        </div>
        <Text className={styles.text}>
          {item?.translations[defaultLocale]?.teamName}
        </Text>
      </>
    ),
  },
  {
    title: 'Сезон',
    render: (item) => <Text className={styles.season}>{item.season}</Text>,
  },
  {
    title: 'Игр в чемпионате',
    render: (item) => <Text>{item.championshipCount}</Text>,
  },
  {
    title: 'Игр в кубке',
    render: (item) => <Text>{item.cupsCount}</Text>,
  },
  {
    title: 'Игр в еврокубке',
    render: (item) => <Text>{item.eurocupsCount}</Text>,
  },
  {
    title: 'Всего игр',
    render: (item) => <Text>{item.gamesCount || '-'}</Text>,
  },
  {
    title: 'ID',
    render: (item) => <Text>{item.id}</Text>,
  },
  {
    title: '',
    render: (item) => (
      <PlayerCareersAction
        activeCareerId={activeItemId}
        career={item}
        setActiveCareerId={setActiveItemId}
      />
    ),
  },
];
