import { StoryGroupPanel } from 'pages/Stories/EditStory/components/StoryGroupPanel/StoryGroupPanel';
import { ToolsPanel } from 'pages/Stories/EditStory/components/ToolsPanel';
import { WorkZone } from 'pages/Stories/EditStory/components/WorkZone';
import { useStoryElementsRND } from 'pages/Stories/hooks/useStoryElementsRND';
import { TLangType } from 'shared/types/common';

import styles from './StoryEditor.module.css';

type TProps = {
  selectedLocale: TLangType;
  createdLocales: TLangType[];
};

export const StoryEditor = ({ selectedLocale }: TProps) => {
  const { storyElementsToShow, storyOutlineRef, updateStoryElement } =
    useStoryElementsRND(selectedLocale);

  return (
    <div className={styles.root}>
      <StoryGroupPanel />
      <WorkZone
        selectedLocale={selectedLocale}
        storyElements={storyElementsToShow}
        storyOutlineRef={storyOutlineRef}
        updater={updateStoryElement}
      />
      <ToolsPanel />
    </div>
  );
};
