import { useQuery } from '@tanstack/react-query';
import { fetchOpponentTeam } from 'entities/opponentTeams/api';
import { opponentTeamsQueryKeys } from 'entities/opponentTeams/consts';
import { TGetOpponentTeam } from 'entities/opponentTeams/types';
import { toBoolean } from 'shared/lib/toBoolean';

export const useFetchOpponentTeamQuery = (params: TGetOpponentTeam) =>
  useQuery({
    queryKey: [opponentTeamsQueryKeys.opponentTeam, params.id],
    queryFn: () => fetchOpponentTeam(params),
    enabled: toBoolean(params.id),
  });
