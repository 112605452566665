import { useLocales } from 'entities/config/lib/useLocales';
import { useDeletePlayerMutation } from 'entities/players/api/mutations';
import { TPlayer } from 'entities/players/model/types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'shared/components/Card/Card';
import { ECardType } from 'shared/components/Card/types';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { notify } from 'shared/components/Notification';
import { routes } from 'shared/routes';

type TProps = {
  player: TPlayer;
};

export const PlayerCard = ({ player }: TProps) => {
  const navigate = useNavigate();
  const { defaultLocale } = useLocales();
  const { mutateAsync: deletePlayer, isLoading } = useDeletePlayerMutation();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const onDeleteHandler = () => {
    deletePlayer(player.id, { onError: () => notify('Не удалось удалить игрока') });
  };

  return (
    <>
      <Card
        key={player.id}
        type={ECardType.Player}
        entity={{
          name: player.translations[defaultLocale].firstName,
          lastName: player.translations[defaultLocale].lastName,
          picture: player?.picture?.x3 ?? '',
          position: player.playerPosition,
          number: player.number,
        }}
        onDelete={() => setOpenConfirmModal(true)}
        onEdit={() => navigate(routes.editPlayer.as(player.team, player.id))}
      />
      <ConfirmationModal
        isLoading={isLoading}
        isOpened={openConfirmModal}
        title="Удаление игрока?"
        description={
          'Вы уверены, что хотите удалить этого игрока?\nДанное действие необратимо.'
        }
        onClose={() => setOpenConfirmModal(false)}
        onConfirm={onDeleteHandler}
      />
    </>
  );
};
