import { getTextMaxLengthMessage } from 'pages/Stories/EditStory/components/forms/utils';
import { TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE } from 'shared/consts';
import z from 'zod';

import { PROMOCODE_MAX_LENGTH } from './constants';

export const copyElementValidationSchema = z.object({
  title: z.string(),
  promocode: z
    .string()
    .min(TEXT_MIN_LENGTH, { message: TEXT_MIN_LENGTH_MESSAGE })
    .max(PROMOCODE_MAX_LENGTH, getTextMaxLengthMessage(PROMOCODE_MAX_LENGTH)),
  size: z.string(),
  fontFamily: z.string(),
  fontStyle: z.string(),
  fill: z.string(),
  background: z.string(),
});
