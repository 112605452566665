import { useManagerTeamAchievementsActions } from 'pages/manager/ManagerTeamAchievementsPage/hooks/useManagerTeamAchievementsActions';
import { TManagerTeamAchievementsActions } from 'pages/manager/ManagerTeamAchievementsPage/types';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';

export const ManagerTeamAchievementsActions = (
  props: TManagerTeamAchievementsActions,
) => {
  const { actionsProps, deleteModalProps } =
    useManagerTeamAchievementsActions(props);

  return (
    <>
      <Actions {...actionsProps} />
      {deleteModalProps.isOpened && <ConfirmationModal {...deleteModalProps} />}
    </>
  );
};
