import axios from 'axios';
import { useCreateNewsPostMutation } from 'entities/news/mutations';
import { TAddNewsPostFields } from 'shared/components/NewsPostForm/types';
import { notify } from 'shared/components/Notification';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_TITLE } from 'shared/validation';

export const useCreateNewsPost = () => {
  const { mutateAsync } = useCreateNewsPostMutation();

  return async (data: TAddNewsPostFields) => {
    try {
      await mutateAsync(data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        notify(DEFAULT_ERROR_MESSAGE, { type: 'error', title: DEFAULT_ERROR_TITLE });
      }
    }
  };
};
