import { TEntityId } from 'shared/types/common';

export const routes = {
  root: '/',
  auth: '/auth',
  uikit: '/uikit',

  stories: '/stories',
  createStory: '/stories/new',
  editStory: '/stories/:storyGroupId',

  news: '/news',
  createNewsPost: '/news/new',
  editNewsPost: {
    url: '/news/:newsPostId',
    as: (newsPostId: TEntityId) => `/news/${newsPostId}`,
  },

  video: '/video',
  createVideo: '/video/new',
  editVideo: {
    url: '/video/:videoId',
    as: (videoId: TEntityId) => `/video/${videoId}`,
  },

  teams: '/teams',
  createTeam: '/teams/new',
  editTeam: {
    url: '/teams/:teamId',
    as: (teamId: TEntityId) => `/teams/${teamId}`,
  },

  createPlayer: {
    url: '/teams/:teamId/players/new',
    as: (teamId: TEntityId) => `/teams/${teamId}/players/new`,
  },
  editPlayer: {
    url: '/teams/:teamId/players/:playerId',
    as: (teamId: TEntityId, playerId: TEntityId) =>
      `/teams/${teamId}/players/${playerId}`,
  },
  playerSocialNetworks: {
    url: '/teams/:teamId/players/:playerId/social-links',
    as: (teamId: TEntityId, playerId: TEntityId) =>
      `/teams/${teamId}/players/${playerId}/social-links`,
  },
  playerCareers: {
    url: '/teams/:teamId/players/:playerId/careers',
    as: (teamId: TEntityId, playerId: TEntityId) =>
      `/teams/${teamId}/players/${playerId}/careers`,
  },
  playerTeamAchievements: {
    url: '/teams/:teamId/players/:playerId/team-achievements',
    as: (teamId: TEntityId, playerId: TEntityId) =>
      `/teams/${teamId}/players/${playerId}/team-achievements`,
  },
  playerAchievements: {
    url: '/teams/:teamId/players/:playerId/achievements',
    as: (teamId: TEntityId, playerId: TEntityId) =>
      `/teams/${teamId}/players/${playerId}/achievements`,
  },

  createManager: {
    url: '/teams/:teamId/managers/new',
    as: (teamId: TEntityId) => `/teams/${teamId}/managers/new`,
  },
  editManager: {
    url: '/teams/:teamId/managers/:managerId',
    as: (teamId: TEntityId, managerId: TEntityId) =>
      `/teams/${teamId}/managers/${managerId}`,
  },
  managerSocialNetworks: {
    url: '/teams/:teamId/managers/:managerId/social-networks',
    as: (teamId: TEntityId, managerId: TEntityId) =>
      `/teams/${teamId}/managers/${managerId}/social-networks`,
  },
  managerCareers: {
    url: '/teams/:teamId/managers/:managerId/careers',
    as: (teamId: TEntityId, managerId: TEntityId) =>
      `/teams/${teamId}/managers/${managerId}/careers`,
  },
  managerTeamAchievements: {
    url: '/teams/:teamId/managers/:managerId/team-achievements',
    as: (teamId: TEntityId, managerId: TEntityId) =>
      `/teams/${teamId}/managers/${managerId}/team-achievements`,
  },

  settings: '/settings',

  categoryNews: '/settings/categories/news',
  categoryVideo: '/settings/categories/video',
  categoryPhotogallery: '/settings/categories/photogallery',

  labels: '/settings/labels',
};
