import { STATUSES } from 'pages/Stories/StoriesList/constants';
import { TStoriesFilter } from 'pages/Stories/StoriesList/types';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'shared/components/Button/Button';
import { Checkbox } from 'shared/components/Form';
import { Status } from 'shared/components/Status';
import { TContentStatus } from 'shared/types/common';

import styles from './StatusFilter.module.css';

type TValues = { [key in TContentStatus]: boolean };

type TProps = {
  filter: TStoriesFilter['status'];

  setFilter: (filter: TStoriesFilter['status']) => void;
};

export const StatusFilter = ({ filter, setFilter }: TProps) => {
  const { control, handleSubmit } = useForm<TValues>({
    defaultValues: STATUSES.reduce((acc, val) => {
      acc[val] = filter.includes(val);

      return acc;
    }, {} as TValues),
  });

  const submit = (values: TValues) => {
    const statusFilter: TContentStatus[] = [];
    (Object.keys(values) as TContentStatus[]).forEach((status) => {
      if (values[status]) {
        statusFilter.push(status);
      }
    });

    setFilter(statusFilter);
  };

  const onSubmit = useMemo(() => handleSubmit(submit), [submit]);

  return (
    <div className={styles.container}>
      {STATUSES.map((status) => (
        <Checkbox
          key={status}
          checkboxLabel={<Status size={14} status={status} />}
          className={styles.checkbox}
          control={control}
          fieldName={status}
        />
      ))}
      <Button onClick={onSubmit}>Подтвердить</Button>
    </div>
  );
};
