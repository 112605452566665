import { useQuery } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { newsCategoriesQueryKeys } from 'entities/newsCategories/consts';
import { TPaginatedParameters } from 'shared/api/types';

import { fetchNewsCategories } from './api';

export const useFetchNewsCategoriesQuery = (props: TPaginatedParameters | void) => {
  const page = props?.page || 1;
  const pageSize = props?.pageSize;

  const { defaultLocale } = useLocales();

  return useQuery({
    queryKey: [newsCategoriesQueryKeys.newsCategories, page, pageSize],
    queryFn: () => fetchNewsCategories({ page, pageSize, defaultLocale }),
    cacheTime: 0,
  });
};
