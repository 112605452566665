import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchManagersQuery } from 'entities/managers/api/queries';
import { useFetchPlayersQuery } from 'entities/players/api/queries';
import { useFetchTournamentsQuery } from 'entities/tournaments/queries';
import { TRightPanelMatchProps, TSelectFieldProps } from 'feature/right-panel/types';
import {
  generateNameOptionsArray,
  generateOptionsArray,
  generatePlaceholder,
} from 'feature/right-panel/utils';
import { useCallback, useEffect, useMemo } from 'react';
import {
  Control,
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';
import { DEFAULT_SELECT_PAGINATION_PARAMS } from 'shared/api/constants';
import { TEntityId } from 'shared/types/common';
import { compareIds } from 'shared/utils/entityIds';
import { TVideoPostFields } from 'widgets/video-form/types';

type TUseRightPanelConnections<T extends FieldValues> = {
  playersFieldName: Path<T>;
  tournamentFieldName: Path<T>;
  seasonFieldName: Path<T>;
  managersFieldName: Path<T>;
  matchFieldName: Path<T>;
  control: Control<T>;
};

type TUseRightPanelConnectionsReturn<T extends FieldValues> = {
  tournament: TSelectFieldProps<T>;
  season: TSelectFieldProps<T>;
  players: TSelectFieldProps<T>;
  managers: TSelectFieldProps<T>;
  match: TRightPanelMatchProps;
};

const paginationParams = {
  paginationParams: DEFAULT_SELECT_PAGINATION_PARAMS,
};

export const useRightPanelConnections = <T extends FieldValues>({
  tournamentFieldName,
  seasonFieldName,
  playersFieldName,
  managersFieldName,
  matchFieldName,
  control,
}: TUseRightPanelConnections<T>): TUseRightPanelConnectionsReturn<T> => {
  const { defaultLocale } = useLocales();
  const { setValue } = useFormContext<TVideoPostFields>();
  const { data: tournaments } = useFetchTournamentsQuery({ include: 'seasons' });

  const { data: players } = useFetchPlayersQuery(paginationParams);

  const { data: managers } = useFetchManagersQuery(paginationParams);

  const { field: selectedMatch } = useController({
    control,
    name: matchFieldName,
  });

  const { field: selectedTournamentId, fieldState: selectedTournamentState } =
    useController({
      control,
      name: tournamentFieldName,
    });

  const { field: selectedSeasonId, fieldState: selectedSeasonState } = useController(
    {
      control,
      name: seasonFieldName,
    },
  );

  const { field: selectedPlayers } = useController({
    control,
    name: playersFieldName,
  });

  const { field: selectedManagers } = useController({
    control,
    name: managersFieldName,
  });

  const selectedManagersCount = selectedManagers.value.length;
  const selectedPlayersCount = selectedPlayers.value.length;

  const selectedTournament = useMemo(() => {
    return tournaments?.find(({ id }) => compareIds(id, selectedTournamentId.value));
  }, [tournaments, selectedTournamentId.value]);

  const tournamentsOptions = useMemo(
    () => generateOptionsArray(defaultLocale, tournaments),
    [tournaments],
  );

  const tournamentsPlaceholder = useMemo(
    () =>
      selectedTournamentId.value
        ? tournamentsOptions.find(({ value }) =>
            compareIds(value, selectedTournamentId.value),
          )?.label ?? ''
        : 'Выбрать турнир из списка',
    [selectedTournamentId.value, tournamentsOptions],
  );

  const seasonsOptions = useMemo(() => {
    if (selectedTournament) {
      return generateOptionsArray(defaultLocale, selectedTournament.seasons);
    }

    return [];
  }, [selectedTournament]);

  const seasonsPlaceholder = useMemo(
    () =>
      selectedSeasonId.value && seasonsOptions.length
        ? seasonsOptions.find(({ value }) =>
            compareIds(value, selectedSeasonId.value),
          )?.label ?? ''
        : 'Выбрать сезон из списка',
    [selectedSeasonId.value, seasonsOptions],
  );

  const playersOptions = useMemo(
    () => generateNameOptionsArray(defaultLocale, players?.players),
    [players],
  );

  const playersPlaceholder = useMemo(
    () =>
      generatePlaceholder({
        count: selectedPlayersCount,
        singular: 'игрок',
        few: 'игрока',
        many: 'игроков',
        defaultPlaceholder: 'Выбрать игроков из списка',
      }),
    [selectedPlayersCount],
  );

  const managersOptions = useMemo(
    () => generateNameOptionsArray(defaultLocale, managers?.managers),
    [managers],
  );

  const managersPlaceholder = useMemo(
    () =>
      generatePlaceholder({
        count: selectedManagersCount,
        singular: 'тренер',
        few: 'тренера',
        many: 'тренеров',
        defaultPlaceholder: 'Выбрать из списка',
      }),
    [selectedManagersCount],
  );

  const changeMatchHandler = useCallback((id: TEntityId) => {
    if (!id) {
      setValue('review', false);
    }
    selectedMatch.onChange(id);
  }, []);

  useEffect(() => {
    if (selectedTournamentState.isDirty) {
      selectedSeasonId.onChange('');
    }
  }, [selectedTournamentId.value, selectedTournamentState.isDirty]);

  useEffect(() => {
    if (selectedSeasonState.isDirty) {
      changeMatchHandler('');
    }
  }, [selectedSeasonId.value, selectedSeasonState.isDirty]);

  return {
    match: {
      selectedTournamentId: selectedTournamentId.value,
      selectedSeasonId: selectedSeasonId.value,
      selectedMatchId: selectedMatch.value,
      onChangeMatch: changeMatchHandler,
      disabled: !selectedTournamentId.value || !selectedSeasonId.value,
      seasons: selectedTournament?.seasons ?? [],
    },
    season: {
      fieldName: seasonFieldName,
      data: seasonsOptions,
      placeholder: seasonsPlaceholder,
      disabled: !selectedTournamentId.value,
    },
    tournament: {
      fieldName: tournamentFieldName,
      data: tournamentsOptions,
      placeholder: tournamentsPlaceholder,
    },
    players: {
      fieldName: playersFieldName,
      placeholder: playersPlaceholder,
      data: playersOptions,
    },
    managers: {
      fieldName: managersFieldName,
      placeholder: managersPlaceholder,
      data: managersOptions,
    },
  };
};
