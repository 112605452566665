import { TEntityId } from 'shared/types/common';

export const managerTeamAchievementsQueryKeys = {
  managerTeamAchievements: 'managerTeamAchievements',
};

const managerTeamAchievementsBaseUrl = 'manager/team_achievements';

export const managerTeamAchievementsApiKeys = {
  getManagerTeamAchievements: managerTeamAchievementsBaseUrl,
  deleteManagerTeamAchievement: (managerId: TEntityId) =>
    `${managerTeamAchievementsBaseUrl}/${managerId}`,
};
