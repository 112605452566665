import { TManager } from 'entities/managers/model/types';
import { TMetaType } from 'shared/types/common';

type TMapManagersResponseArgs = {
  data: TManager[];
  meta?: TMetaType<true>;
};

export type TManagersResponse = {
  total: number;
  page: number;
  managers: TManager[];
};

export const mapFetchManagersResponse = ({
  data: managers,
  meta,
}: TMapManagersResponseArgs): TManagersResponse => {
  const page = meta!.page;

  return {
    total: page?.total_entries,
    page: page?.number,
    managers,
  };
};
