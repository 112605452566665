import { TEXT_MIN_LENGTH_MESSAGE } from 'shared/consts';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toNumber } from 'shared/lib/toNumber';
import { TLangType } from 'shared/types/common';
import { fileZodValidation } from 'shared/zod/fileZodValidation';
import z from 'zod';

const localesConverter = (locales: TLangType[]) => {
  return reduceTranslationObject(locales, () =>
    z.object({ name: z.string().min(1, TEXT_MIN_LENGTH_MESSAGE) }),
  );
};

const importanceValidation = z.string().refine((value) => {
  const intValue = toNumber(value);
  return intValue >= -2147483647 && intValue <= 2147483647;
});

export const addVideoCategoriesPostValidationSchema = (locales: TLangType[]) =>
  z.object({
    translations: z.object({ ...localesConverter(locales) }),
    picture: fileZodValidation('Загрузите превью-изображение'),
    importance: importanceValidation,
  });
