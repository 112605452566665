import { zodResolver } from '@hookform/resolvers/zod';
import { useLocales } from 'entities/config/lib/useLocales';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { FormInput } from 'shared/components/Form';
import { TFormInputProps } from 'shared/components/Form/types';
import { localizationModalValidationSchema } from 'shared/components/LocalizationModal/validationSchema';
import { Text } from 'shared/components/Text/Text';
import { langNameMap } from 'shared/consts';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TLangType, TTranslations } from 'shared/types/common';

import styles from './LocalizationModal.module.css';

export type TLocalizationModalProps = {
  title: string;
  subtitle?: string;
  closeModal: () => void;
  actionSubmit: (data: TTranslations<string>) => void;
  createInitialValue?: (locale: TLangType) => string;
  isOpened: boolean;
  inputProps?: Omit<TFormInputProps<TTranslations<string>>, 'control' | 'fieldName'>;
};

export function LocalizationModal({
  title,
  subtitle,
  closeModal,
  actionSubmit,
  createInitialValue,
  isOpened,
  inputProps,
}: TLocalizationModalProps) {
  const { locales } = useLocales();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<TTranslations<string>>({
    values: reduceTranslationObject(locales, createInitialValue ?? (() => '')),
    resolver: zodResolver(
      localizationModalValidationSchema(locales, inputProps?.required ?? false),
    ),
  });

  const submit = handleSubmit((data) => {
    actionSubmit(data);
    closeModal();
  });

  useEffect(() => {
    if (!isOpened) {
      reset();
    }
  }, [isOpened]);

  return (
    <ActionModal
      opened={isOpened}
      title={title}
      width={542}
      confirmButtonProps={{
        isDisabled: !isValid,
        onClick: submit,
      }}
      onClose={closeModal}
    >
      {subtitle && (
        <Text size={14} tag="h3" weight="SemiBold">
          {subtitle}
        </Text>
      )}
      <div className={styles.form}>
        {locales?.map((locale) => {
          return (
            <FormInput
              key={locale}
              className={inputProps?.className}
              control={control}
              fieldName={locale}
              label={langNameMap[locale]}
              {...(inputProps ?? {})}
            />
          );
        })}
      </div>
    </ActionModal>
  );
}
