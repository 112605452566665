import { newsCategoriesApiKeys } from 'entities/newsCategories/consts';
import { mapNewsCategoriesResponse } from 'entities/newsCategories/mapper/mapNewsCategoriesResponse';
import {
  TFetchNewsCategories,
  TNewsCategory,
  TNewsEditCategory,
} from 'entities/newsCategories/types';
import { del, get, patch, post } from 'shared/api/axios';
import { createPaginationParams } from 'shared/api/utils';
import { TEntityId, TTranslationsObject } from 'shared/types/common';

export const fetchNewsCategories = ({
  page,
  pageSize,
  defaultLocale,
}: TFetchNewsCategories) =>
  get<TNewsCategory[], true>(newsCategoriesApiKeys.getNewsCategories, {
    params: {
      ...createPaginationParams(page, pageSize),
      sort: 'name',
    },
  }).then((response) => {
    return mapNewsCategoriesResponse({ ...response.data, defaultLocale });
  });

export const createNewsCategory = (
  data: TTranslationsObject<{
    name: string;
  }>,
) =>
  post<TNewsCategory>(newsCategoriesApiKeys.createNewsCategory, {
    newsCategory: data,
  }).then((response) => response.data.data);

export const deleteNewsCategory = (categoryId: TEntityId) =>
  del(newsCategoriesApiKeys.deleteNewsCategory(categoryId)).then(
    (response) => response.data.data,
  );

export const editNewsCategory = ({ id, translations }: TNewsEditCategory) =>
  patch(newsCategoriesApiKeys.editNewsCategory(id), {
    newsCategory: { translations },
  }).then((response) => response.data.data);
