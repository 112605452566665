import { parseISO } from 'date-fns';
import { TVideoPost } from 'entities/video/types';
import { useActiveConfirmationModal } from 'entities/video/video-actions/hooks/useActiveConfirmationModal';
import { usePublishVideo } from 'entities/video/video-actions/hooks/usePublishVideo';
import { useUnpublishVideo } from 'entities/video/video-actions/hooks/useUnpublishVideo';
import { useVideoPostActions } from 'entities/video/video-actions/hooks/useVideoPostActions';
import { UnpublishConfirmationModal } from 'feature/video/ui/modals/UnpublishConfirmationModal';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { TEntityId } from 'shared/types/common';

type TProps = {
  activeVideoPostId: TEntityId | null;
  setActiveVideoPostId: Dispatch<SetStateAction<TEntityId | null>>;
  videoPost: TVideoPost;
};

export const VideoPostActions = ({
  activeVideoPostId,
  setActiveVideoPostId,
  videoPost,
}: TProps) => {
  const navigate = useNavigate();
  const {
    activeConfirmationModal,
    setActiveConfirmationModal,
    closeConfirmationModal,
  } = useActiveConfirmationModal();
  const { handleConfirmUnpublish, isUnpublishing } = useUnpublishVideo(videoPost);
  const { changeVideoPublishDate, isPublishing } = usePublishVideo({
    videoPost,
    closeConfirmationModal,
  });
  const { actions, isVideoDeleting } = useVideoPostActions(
    videoPost,
    setActiveConfirmationModal,
  );
  const { id, published } = videoPost;
  const isOpen = id === activeVideoPostId;
  const isLoadingAction = isVideoDeleting || isUnpublishing || isPublishing;

  const onConfirmUnpublish = useCallback(async () => {
    closeConfirmationModal();
    await handleConfirmUnpublish();
  }, []);

  const onClick = useCallback(() => {
    if (activeVideoPostId === id) {
      return setActiveVideoPostId(null);
    }

    setActiveVideoPostId(id);
  }, [id, activeVideoPostId]);

  return (
    <>
      <Actions
        actions={actions}
        activeItemId={activeVideoPostId}
        isLoading={isLoadingAction}
        isOpen={isOpen}
        navigate={navigate}
        onIconClick={onClick}
      />
      <DatePicker
        date={(videoPost && published && parseISO(published)) || undefined}
        isOpened={activeConfirmationModal === 'changePublishDate'}
        onClose={closeConfirmationModal}
        onDateChange={changeVideoPublishDate}
      />
      <ConfirmationModal
        description="Опубликовать видео сейчас?"
        isOpened={activeConfirmationModal === 'confirmPublishNow'}
        title="Подтвердите публикацию"
        onClose={closeConfirmationModal}
        onConfirm={() => changeVideoPublishDate(new Date())}
      />
      {published && (
        <UnpublishConfirmationModal
          isOpened={activeConfirmationModal === 'confirmUnpublish'}
          onClose={closeConfirmationModal}
          onConfirm={onConfirmUnpublish}
        />
      )}
    </>
  );
};
