import { useQuery } from '@tanstack/react-query';
import {
  fetchPlayerAchievements,
  TFetchPlayerAchievementsParams,
} from 'entities/playerAchievements/api';
import { playerAchievementsQueryKeys } from 'entities/playerAchievements/consts';

export const useFetchPlayerAchievementsQuery = ({
  page = 1,
  filter,
}: Partial<TFetchPlayerAchievementsParams>) => {
  return useQuery({
    queryKey: [playerAchievementsQueryKeys.playerAchievements, page, filter],
    queryFn: () => fetchPlayerAchievements({ page, filter }),
    cacheTime: 0,
  });
};
