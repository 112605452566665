import { parseISO } from 'date-fns';
import { TStoryGroupData } from 'entities/stories/types';
import { getStatusByPublicationDate } from 'feature/story/getStatusByPublicationDate';
import { reverse, sortBy } from 'lodash';
import { useTableSort } from 'pages/Stories/hooks/useTableSort';
import { StatusFilter } from 'pages/Stories/StoriesList/components/StatusFilter';
import { StoryGroupRow } from 'pages/Stories/StoriesList/components/StoryGroupRow/StoryGroupRow';
import { useStoryGroupActions } from 'pages/Stories/StoriesList/hooks/useStoryGroupActions';
import styles from 'pages/Stories/StoriesList/StoriesList.module.css';
import {
  EStoriesStatus,
  TSortParams,
  TStoriesFilter,
  TStoriesListModal,
  TStoryGroupAction,
  TStoryGroupActionsByStatus,
} from 'pages/Stories/StoriesList/types';
import { useState } from 'react';
import { Button } from 'shared/components/Button/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { Pagination } from 'shared/components/Pagination/Pagination';
import { Table } from 'shared/components/SimpleTable';
import { Text } from 'shared/components/Text/Text';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'shared/consts';
import { TContentStatus, TDefaultActions } from 'shared/types/common';

export const DEFAULT_STORIES_SORT_OPTIONS = {
  direction: 'desc',
  sortBy: 'createdAt',
} as const;

type TProps = {
  onCreateStories: () => void;
  storyGroups: TStoryGroupData[];
  isLoading?: boolean;
};

export const StoriesList = ({ onCreateStories, storyGroups, isLoading }: TProps) => {
  const {
    editStoryGroup,
    changePublicationDate,
    publishNow,
    unpublish,
    deleteStoryGroup,
  } = useStoryGroupActions();
  const [selectedPage, setSelectedPage] = useState(1);
  const [activeStoryGroup, setActiveStoryGroup] = useState<TStoryGroupData | null>(
    null,
  );
  const [activeModal, setActiveModal] = useState<TStoriesListModal | null>(null);
  const { sortOptions, handleSort } = useTableSort<TSortParams>(
    DEFAULT_STORIES_SORT_OPTIONS,
  );
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filter, setFilter] = useState<TStoriesFilter>({ status: [] });
  const getSortedStoryGroups = () => {
    const sorted = sortBy(storyGroups, (item) => {
      if (sortOptions.sortBy === 'screenCount') {
        return item.stories.length;
      }

      return item[sortOptions.sortBy as keyof TStoryGroupData];
    });
    if (sortOptions.direction === 'asc') {
      return sorted;
    }
    if (sortOptions.direction === 'desc') {
      return reverse(sorted);
    }

    return reverse(sortBy(storyGroups, 'dateCreated'));
  };

  const handleActionsClick = (storyGroup: TStoryGroupData) => {
    if (activeStoryGroup) {
      return setActiveStoryGroup(null);
    }
    return setActiveStoryGroup(storyGroup);
  };

  const filtered =
    filter.status.length > 0
      ? getSortedStoryGroups().filter((item) =>
          filter.status.includes(getStatusByPublicationDate(item.published)),
        )
      : getSortedStoryGroups();

  const paginated = filtered.slice(
    (selectedPage - 1) * DEFAULT_PAGINATION_PAGE_SIZE,
    (selectedPage - 1) * DEFAULT_PAGINATION_PAGE_SIZE + DEFAULT_PAGINATION_PAGE_SIZE,
  );

  const handleStatusFilter = (filter: TContentStatus[]) => {
    setFilter({ status: filter });
    setSelectedPage(1);
    setIsFilterOpen(false);
  };

  const handlePageChange = (page: number) => {
    setSelectedPage(page);
    window.scrollTo({ top: 0 });
  };

  const defaultStoryGroupActions: Record<TDefaultActions, TStoryGroupAction> = {
    edit: {
      title: 'Редактировать',
      icon: 'edit-02',
      onClick: () => {
        if (activeStoryGroup) {
          editStoryGroup(activeStoryGroup.id);
        }
      },
    },
    delete: {
      title: 'Удалить',
      icon: 'trash',
      onClick: () => setActiveModal('confirmDelete'),
    },
  };

  const storyGroupActions: TStoryGroupActionsByStatus = {
    [EStoriesStatus.Published]: [
      defaultStoryGroupActions.edit,
      {
        title: 'Снять с публикации',
        icon: 'no-eyes',
        onClick: () => handleUnpublishStory(),
      },
      defaultStoryGroupActions.delete,
    ],
    [EStoriesStatus.Postponed]: [
      {
        title: 'Опубликовать сейчас',
        icon: 'plus',
        onClick: () => setActiveModal('confirmPublishNow'),
      },
      defaultStoryGroupActions.edit,
      {
        title: 'Изменить время публикации',
        icon: 'clock',
        onClick: () => setActiveModal('changePublishDate'),
      },
      defaultStoryGroupActions.delete,
    ],
    [EStoriesStatus.Draft]: [
      defaultStoryGroupActions.edit,
      defaultStoryGroupActions.delete,
    ],
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  const handleDateChange = (date: Date | undefined) => {
    if (!activeStoryGroup || !date) {
      return;
    }
    changePublicationDate(activeStoryGroup, date);
    closeModal();
    setActiveStoryGroup(null);
  };

  const handleConfirm = () => {
    if (!activeModal || !activeStoryGroup) {
      return;
    }
    if (activeModal === 'confirmPublishNow') {
      publishNow(activeStoryGroup);
    }
    if (activeModal === 'confirmDelete') {
      deleteStoryGroup(activeStoryGroup);
    }
    closeModal();
    setActiveStoryGroup(null);
  };

  const handleUnpublishStory = () => {
    if (!activeStoryGroup) {
      return;
    }
    unpublish(activeStoryGroup);
    setActiveStoryGroup(null);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Text size={24} tag="h1" weight="Bold">
          Сторис
        </Text>
        <Button
          icon="plus"
          iconPosition="before"
          iconSize="xs"
          onClick={onCreateStories}
        >
          Создать сторис
        </Button>
      </div>
      <div className={styles.tableWrapper}>
        <Table>
          <Table.Head>
            <Table.Row
              className={styles.rowGridTemplate}
              hoverable={false}
              padding="s"
            >
              <Table.HeadCell className={styles.publicationNameColumn}>
                <Text>Публикации</Text>
              </Table.HeadCell>
              <Table.HeadCell
                sortOptions={sortOptions}
                sortParam="screenCount"
                sortable
                onSort={handleSort}
              >
                <Text>Кол. экранов</Text>
              </Table.HeadCell>
              <Table.HeadCell
                sortOptions={sortOptions}
                sortParam="createdAt"
                sortable
                onSort={handleSort}
              >
                <Text>Дата создания</Text>
              </Table.HeadCell>
              <Table.HeadCell>
                <Text>ID</Text>
              </Table.HeadCell>
              <Table.HeadCell
                filterable
                isFilterOpen={isFilterOpen}
                isFiltered={filter.status.length > 0}
                setFilterOpen={setIsFilterOpen}
                filterComponent={
                  <StatusFilter
                    filter={filter.status}
                    setFilter={handleStatusFilter}
                  />
                }
              >
                <Text>Статус</Text>
              </Table.HeadCell>
              <Table.HeadCell
                sortOptions={sortOptions}
                sortParam="published"
                sortable
                onSort={handleSort}
              >
                <Text>Дата публикации</Text>
              </Table.HeadCell>
              <Table.HeadCell
                sortOptions={sortOptions}
                sortParam="viewsCount"
                sortable
                onSort={handleSort}
              >
                <Text>Кол. просмотров</Text>
              </Table.HeadCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {paginated.map((item) => (
              <StoryGroupRow
                key={item.id}
                actions={storyGroupActions}
                activeStoryGroup={activeStoryGroup}
                storyGroup={item}
                onActionsClick={handleActionsClick}
              />
            ))}
          </Table.Body>
        </Table>
        {filtered.length > 0 &&
          filtered.length > DEFAULT_PAGINATION_PAGE_SIZE &&
          !isLoading && (
            <Pagination
              className={styles.pagination}
              selectedPage={selectedPage}
              totalItems={filtered.length}
              onPageChange={handlePageChange}
            />
          )}
      </div>
      <DatePicker
        isOpened={activeModal === 'changePublishDate'}
        date={
          (activeStoryGroup &&
            activeStoryGroup.published &&
            parseISO(activeStoryGroup.published)) ||
          undefined
        }
        onClose={closeModal}
        onDateChange={handleDateChange}
      />
      <ConfirmationModal
        description="Опубликовать сторис сейчас?"
        isOpened={activeModal === 'confirmPublishNow'}
        title="Подтвердите публикацию"
        onClose={closeModal}
        onConfirm={handleConfirm}
      />
      <ConfirmationModal
        description="После удаления, содержимое будет утеряно навсегда"
        isOpened={activeModal === 'confirmDelete'}
        title="Удаление публикации"
        onClose={closeModal}
        onConfirm={handleConfirm}
      />
    </div>
  );
};
