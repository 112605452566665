import { useQueryClient } from '@tanstack/react-query';
import { managerSocialNetworksQueryKeys } from 'entities/managerSocialNetworks/consts';
import { useDeleteManagerSocialNetworkMutation } from 'entities/managerSocialNetworks/mutations';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useDeleteManagerSocialNetwork = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteManagerSocialNetworkMutation, isLoading } =
    useDeleteManagerSocialNetworkMutation();

  const deleteManagerSocialNetwork = (managerSocialNetworkId: TEntityId) => {
    deleteManagerSocialNetworkMutation(managerSocialNetworkId, {
      onError: () =>
        notify('Не удалось удалить соц. сеть тренера', { type: 'error' }),
      onSuccess: () =>
        queryClient.resetQueries([
          managerSocialNetworksQueryKeys.managerSocialNetworks,
        ]),
    });
  };

  return {
    isDeletingManagerSocialNetwork: isLoading,
    deleteManagerSocialNetwork,
  };
};
