import { useDeletePlayerTeamAchievementMutation } from 'entities/playerTeamAchievements/mutations';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useDeletePlayerTeamAchievement = () => {
  const { mutate: deletePlayerTeamAchievementMutation, isLoading } =
    useDeletePlayerTeamAchievementMutation();

  const deletePlayerTeamAchievement = (teamAchievementId: TEntityId) => {
    deletePlayerTeamAchievementMutation(teamAchievementId, {
      onError: () =>
        notify('Не удалось удалить командное достижение игрока', { type: 'error' }),
    });
  };

  return {
    isDeletingPlayerTeamAchievement: isLoading,
    deletePlayerTeamAchievement,
  };
};
