import { TManagerSocialNetwork } from 'entities/managerSocialNetworks/types';
import { TSocialNetwork } from 'entities/socialNetworks/types';
import { TManagerSocialNetworkItem } from 'pages/manager/ManagerSocialNetworksPage/types';
import { toString } from 'shared/lib/toString';

export const combineManagerSocialNetworks = (
  managerSocialNetworks: TManagerSocialNetwork[],
  socialNetworks: TSocialNetwork[],
): TManagerSocialNetworkItem[] => {
  const serializedSocialNetworks = socialNetworks.reduce((acc, network) => {
    acc.set(toString(network.id), network);

    return acc;
  }, new Map<string, TSocialNetwork>());

  return managerSocialNetworks.map<TManagerSocialNetworkItem>(
    ({ socialNetworkId, ...managerSocialNetwork }) => {
      const socialNetwork = serializedSocialNetworks.get(
        toString(socialNetworkId),
      ) as TSocialNetwork;

      return {
        ...managerSocialNetwork,
        socialNetwork,
      };
    },
  );
};
