import {
  TEntityId,
  TImageContent,
  TImageUpload,
  TTranslationsObject,
} from 'shared/types/common';

export enum EPlayerStatus {
  PartOfTeam = 'part_of_team',
  Outstaff = 'outstaff',
}

export type TPlayer = {
  id: TEntityId;
  externalId: TEntityId;
  status: EPlayerStatus;
  number: number;
  height?: number;
  weight?: number;
  birthDate?: string;
  debutDate?: string;
  discardedAt?: string;
  picture?: TImageContent;
  photo?: TImageContent;
  nickname?: string;
  team: string;
  country: string;
  playerPosition: string;
} & TTranslationsObject<{
  firstName: string;
  lastName: string;
  sharingLink?: string;
  shopLink?: string;
  biography?: string;
  pupil?: string;
}>;

export type TCreatePlayerDTO = {
  status: EPlayerStatus;
  discardedAt?: string;
  playerPositionId: number;
  teamId: number;
  countryId: number;
  number: string;
  height?: number;
  weight?: number;
  debutDate?: string;
  birthDate?: string;
  nickname?: string;
  picture?: TImageUpload;
  photo?: TImageUpload;
} & TTranslationsObject<{
  firstName: string;
  lastName: string;
  pupil?: string;
  biography?: string;
  sharingLink?: string;
  shopLink?: string;
}>;

export type TEditPlayerDTO = {
  playerId: TEntityId;
} & TCreatePlayerDTO;
