import { AchievementModal } from 'feature/achievement-modal/AchievementModal';
import { usePlayerAchievementsActions } from 'pages/player/PlayerAchievementsPage/hooks/usePlayerAchievementsActions';
import { TPlayerAchievementsActions } from 'pages/player/PlayerAchievementsPage/types';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';

type TProps = TPlayerAchievementsActions;

export const PlayerAchievementsActions = (props: TProps) => {
  const { actionsProps, deleteModalProps, editModalProps } =
    usePlayerAchievementsActions(props);

  return (
    <>
      <Actions {...actionsProps} />
      {deleteModalProps.isOpened && <ConfirmationModal {...deleteModalProps} />}
      {editModalProps.isOpened && <AchievementModal {...editModalProps} />}
    </>
  );
};
