// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StatusFilter-module__container--fO7ZO {\n  display: flex;\n  flex-direction: column;\n  padding: 8px;\n  gap: 8px;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.StatusFilter-module__checkbox--oOOEY {\n  display: flex;\n}\n\n.StatusFilter-module__checkbox--oOOEY label {\n    align-items: center;\n  }\n", "",{"version":3,"sources":["webpack://./src/2-pages/Stories/StoriesList/components/StatusFilter/StatusFilter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,QAAQ;EACR,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;AAKf;;AAHE;IACE,mBAAmB;EACrB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  padding: 8px;\n  gap: 8px;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.checkbox {\n  display: flex;\n\n  label {\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "StatusFilter-module__container--fO7ZO",
	"checkbox": "StatusFilter-module__checkbox--oOOEY"
};
export default ___CSS_LOADER_EXPORT___;
