import { TVideoCategory } from 'entities/videoCategories/types';
import { TLangType, TMetaType } from 'shared/types/common';

type TMapVideoPostsResponseArgs = {
  data: TVideoCategory[];
  meta?: TMetaType<true>;
  defaultLocale: TLangType;
};

export const mapVideoCategoriesResponse = ({
  data: categories,
  meta,
}: TMapVideoPostsResponseArgs) => {
  const page = meta?.page;

  return {
    total: page?.total_entries,
    page: page?.number,
    categories,
  };
};
