import cn from 'classnames';
import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Button } from 'shared/components/Button/Button';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { useCreateVideoPost } from 'widgets/video-form/hooks/useCreateVideoPost';
import { useEditVideoPost } from 'widgets/video-form/hooks/useEditVideoPost';
import { useIsEditVideo } from 'widgets/video-form/hooks/useIsEditVideo';
import { useIsPublishVideo } from 'widgets/video-form/hooks/useIsPublishVideo';
import { TVideoPostFields } from 'widgets/video-form/types';
import { VideoFormPublishModal } from 'widgets/video-form/ui/VideoFormHeaderPanel/VideoFormPublish/VideoFormPublishModal';

import styles from './VideoFormPublishButton.module.css';

export const VideoFormPublishButton = () => {
  const { trigger, control, handleSubmit, formState } =
    useFormContext<TVideoPostFields>();

  const createVideoPost = useCreateVideoPost();
  const editVideoPost = useEditVideoPost();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenChooseDateModal, setIsOpenChooseDateModal] = useState(false);
  const isEdit = useIsEditVideo();
  const isPublish = useIsPublishVideo();
  const {
    field: { onChange: onPublishDateChange, value: publishDate },
  } = useController({
    control,
    name: 'publishDate',
  });

  const onClickPublish = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    if (isEdit && isPublish) {
      await handleSubmit(editVideoPost)();
      return;
    }

    setIsOpenModal(true);
  };

  const onDateChange = (date: Date | undefined) => {
    onPublishDateChange(date);
    setIsOpenModal(true);
    setIsOpenChooseDateModal(false);
  };

  const onPublish = () => {
    if (!publishDate) {
      onPublishDateChange(new Date());
    }

    if (isEdit) {
      handleSubmit(editVideoPost)();
    } else {
      handleSubmit(createVideoPost)();
    }
  };

  const onClickChooseDate = () => {
    setIsOpenModal(false);
    setIsOpenChooseDateModal(true);
  };

  return (
    <>
      <Button
        isDisabled={formState.isSubmitting}
        className={cn({
          [styles.disabled]: !formState.isValid,
        })}
        onClick={onClickPublish}
      >
        Подтвердить
      </Button>
      <VideoFormPublishModal
        isOpened={isOpenModal}
        onClickChooseDate={onClickChooseDate}
        onClose={() => setIsOpenModal(false)}
        onPublish={onPublish}
      />
      <DatePicker
        date={publishDate}
        isOpened={isOpenChooseDateModal}
        onDateChange={onDateChange}
        onClose={() => {
          setIsOpenChooseDateModal(false);
          setIsOpenModal(true);
        }}
      />
    </>
  );
};
