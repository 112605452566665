import { TPlayer } from 'entities/players/model/types';
import { TMetaType } from 'shared/types/common';

type TMapPlayersResponseArgs = {
  data: TPlayer[];
  meta?: TMetaType<true>;
};

export type TPlayersResponse = {
  total: number;
  page: number;
  players: TPlayer[];
};

export const mapFetchPlayersResponse = ({
  data: players,
  meta,
}: TMapPlayersResponseArgs): TPlayersResponse => {
  const page = meta!.page;

  return {
    total: page?.total_entries,
    page: page?.number,
    players,
  };
};
