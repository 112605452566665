import { ManagerLayout } from 'feature/manager-layout/ui/ManagerLayout/ManagerLayout';
import { noop } from 'lodash';
import { useManagerTeamAchievements } from 'pages/manager/ManagerTeamAchievementsPage/hooks/useManagerTeamAchievements';
import { ManagerTeamAchievementsTable } from 'pages/manager/ManagerTeamAchievementsPage/ManagerTeamAchievementsTable/ManagerTeamAchievementsTable';
import {
  calculateSelectedPage,
  calculateTotalItems,
} from 'pages/video/VideoPage/utils';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

import styles from './ManagerTeamAchievementsPage.module.css';

export const ManagerTeamAchievementsPage = () => {
  const {
    error,
    isLoading,
    handlePageChange,
    managerTeamAchievements = [],
    page,
    total,
    handleConfirmButtonClick,
  } = useManagerTeamAchievements();

  return (
    <ManagerLayout
      confirmButtonProps={{
        onClick: handleConfirmButtonClick,
      }}
    >
      <CommonListWrapper
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title="Командные достижения"
        createButtonProps={{
          content: 'Создать достижение',
          onClick: noop,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(managerTeamAchievements, page),
          totalItems: calculateTotalItems(managerTeamAchievements, total),
          onPageChange: handlePageChange,
        }}
      >
        <ManagerTeamAchievementsTable
          managerTeamAchievements={managerTeamAchievements}
        />
      </CommonListWrapper>
    </ManagerLayout>
  );
};
