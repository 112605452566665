import { useState } from 'react';

import { TSortOptions } from '../../../4-widgets/elements-list-table/types';

export const useTableSort = <SortBy extends string>(
  defaultSortOptions: TSortOptions<SortBy>,
) => {
  const [sortOptions, setSortOptions] =
    useState<TSortOptions<SortBy>>(defaultSortOptions);

  const handleSort = ({ sortBy, direction }: TSortOptions<SortBy>) => {
    switch (direction) {
      case 'desc':
        return setSortOptions({
          direction: 'desc',
          sortBy,
        });
      default:
        return setSortOptions({
          direction: 'asc',
          sortBy,
        });
    }
  };

  return {
    sortOptions,
    handleSort,
  };
};
