import { useFetchLabelsQuery } from 'entities/labels/queries';
import { useFetchNewsCategoriesQuery } from 'entities/newsCategories/queries';
import { useRightPanelLabel } from 'feature/right-panel/hooks/useRightPanelLabel';
import { TDefaultRightPanelProps } from 'feature/right-panel/types';
import { CommonLabel } from 'feature/right-panel/ui/CommonLabel/CommonLabel';
import { useController, useFormContext } from 'react-hook-form';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';

export const AddNewsPostRightPanelLabel = (commonProps: TDefaultRightPanelProps) => {
  const { control } = useFormContext<TEditNewsPostFields>();
  const { data: labelsData } = useFetchLabelsQuery({ pageSize: 100 });
  const { data: categoriesData } = useFetchNewsCategoriesQuery({ pageSize: 100 });

  const { field: selectedLabels } = useController({
    control,
    name: 'labels',
  });

  const { field: selectedCategories } = useController({
    control,
    name: 'categories',
  });

  const { labels, categories } = useRightPanelLabel<TEditNewsPostFields>({
    labels: labelsData?.categories,
    labelsFieldName: 'labels',
    categoriesFieldName: 'categories',
    categories: categoriesData?.categories,
    selectedLabelsCount: selectedLabels.value.length,
    selectedCategoriesCount: selectedCategories.value.length,
  });

  return (
    <CommonLabel
      categories={categories}
      control={control}
      description="“Лейбл” будет отображаться на новости. После выбора определенной “Категории”, новость будет отображаться в соответствующем списке."
      labels={labels}
      {...commonProps}
    />
  );
};
