import { TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE } from 'shared/consts';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TLangType } from 'shared/types/common';
import z from 'zod';

export const localizationModalValidationSchema = (
  locales: TLangType[],
  required: boolean,
) =>
  z.object({
    ...reduceTranslationObject(locales, () =>
      z
        .string()
        .refine(
          (value) => (required ? value.length >= TEXT_MIN_LENGTH : true),
          TEXT_MIN_LENGTH_MESSAGE,
        ),
    ),
  });
