import { TEntityId } from 'shared/types/common';

const managerCareersBaseUrl = 'manager/careers';

export const managerCareersQueryKeys = {
  managerCareers: 'managerCareers',
};

export const managerCareersApiKeys = {
  getManagerCareers: managerCareersBaseUrl,
  deleteCareer: (careerId: TEntityId) => `${managerCareersBaseUrl}/${careerId}`,
};
