import React from 'react';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';

type TProps = {
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const UnpublishConfirmationModal = ({
  isOpened,
  onClose,
  onConfirm,
}: TProps) => {
  return (
    <ConfirmationModal
      isOpened={isOpened}
      title="Снять с публикации?"
      description={
        <>
          После снятия публикации, ей будет <br /> присвоен статус
          &quot;Черновик&quot;
        </>
      }
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};
