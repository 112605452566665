// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Information-module__wrapper--MeEUZ {\n  height: 98px;\n  border-top: 1px solid var(--gray-200);\n  display: flex;\n  justify-content: space-between;\n}\n\n.Information-module__content--vh8ne {\n  padding: 16px;\n}\n\n\n.Information-module__position--uHqkK {\n  margin-top: 2px;\n}\n\n.Information-module__numberBlock--LyjLH {\n  border-left: 1px solid var(--gray-200);\n  padding: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.Information-module__numberBlock--LyjLH p {\n    color: var(--gray-500);\n    font-size: 30px;\n    font-weight: 600;\n  }\n\n.Information-module__wrapperTeam--YbD10 {\n  height: 80px;\n  padding: 0 16px;\n  display: flex;\n  align-items: center;\n}\n\n.Information-module__text--XZGFR {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Card/CardInformationPart/Information.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,qCAAqC;EACrC,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf;;;AAGA;EACE,eAAe;AACjB;;AAEA;EACE,sCAAsC;EACtC,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AAOrB;;AALE;IACE,sBAAsB;IACtB,eAAe;IACf,gBAAgB;EAClB;;AAGF;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".wrapper {\n  height: 98px;\n  border-top: 1px solid var(--gray-200);\n  display: flex;\n  justify-content: space-between;\n}\n\n.content {\n  padding: 16px;\n}\n\n\n.position {\n  margin-top: 2px;\n}\n\n.numberBlock {\n  border-left: 1px solid var(--gray-200);\n  padding: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  p {\n    color: var(--gray-500);\n    font-size: 30px;\n    font-weight: 600;\n  }\n}\n\n.wrapperTeam {\n  height: 80px;\n  padding: 0 16px;\n  display: flex;\n  align-items: center;\n}\n\n.text {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Information-module__wrapper--MeEUZ",
	"content": "Information-module__content--vh8ne",
	"position": "Information-module__position--uHqkK",
	"numberBlock": "Information-module__numberBlock--LyjLH",
	"wrapperTeam": "Information-module__wrapperTeam--YbD10",
	"text": "Information-module__text--XZGFR"
};
export default ___CSS_LOADER_EXPORT___;
