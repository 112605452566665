import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import {
  createPlayerCareer,
  deletePlayerCareer,
  editPlayerCareer,
  fetchPlayerCareers,
} from 'entities/playerCareers/api';
import { playerCareersQueryKeys } from 'entities/playerCareers/consts';
import { TFetchPlayerCareersParams } from 'entities/playerCareers/types';
import { notify } from 'shared/components/Notification';

export const useFetchPlayerCareersQuery = ({
  page = 1,
  filter,
}: TFetchPlayerCareersParams) => {
  return useQuery({
    queryKey: [playerCareersQueryKeys.playerCareers, page, filter],
    queryFn: () => fetchPlayerCareers({ page, filter }),
    cacheTime: 0,
  });
};

export const useDeletePlayerCareerMutation = () => {
  return useMutation({
    mutationFn: deletePlayerCareer,
    onSuccess: () => {
      return queryClient.invalidateQueries([playerCareersQueryKeys.playerCareers]);
    },
  });
};

export const useCreatePlayerCareerMutation = () => {
  return useMutation({
    mutationFn: createPlayerCareer,
    onSuccess: () => {
      return queryClient.invalidateQueries([playerCareersQueryKeys.playerCareers]);
    },
    onError: () => {
      notify('Не удалось создать карьеру игрока', { type: 'error' });
    },
  });
};

export const useEditPlayerCareerMutation = () => {
  return useMutation({
    mutationFn: editPlayerCareer,
    onSuccess: () => {
      return queryClient.invalidateQueries([playerCareersQueryKeys.playerCareers]);
    },
    onError: () => {
      notify('Не удалось отредактировать карьеру игрока', { type: 'error' });
    },
  });
};
