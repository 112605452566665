import { Identifier } from 'dnd-core';
import { StoryPreview } from 'pages/Stories/EditStory/components/Story/StoryPreview';
import { ComponentProps } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { DNDItemTypes } from 'shared/constants';

import styles from './CustomDragLayer.module.css';

const getItemStyles = (
  isDragging: boolean,
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
) => {
  if (!isDragging || !initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
};

const renderItem = <T extends ComponentProps<typeof StoryPreview>>(
  itemType: Identifier | null,
  itemProps: T,
) => {
  switch (itemType) {
    case DNDItemTypes.STORY_GROUP_IMAGE:
      return <StoryPreview {...itemProps} />;
    default:
      return null;
  }
};

export const CustomDragLayer = () => {
  const { itemType, item, isDragging, initialOffset, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }),
  );

  return (
    <div className={styles.layer}>
      <div style={getItemStyles(isDragging, initialOffset, currentOffset)}>
        {renderItem(itemType, item)}
      </div>
    </div>
  );
};
