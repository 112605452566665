import { useQueryClient } from '@tanstack/react-query';
import { managerTeamAchievementsQueryKeys } from 'entities/managerTeamAchievements/consts';
import { useDeleteManagerTeamAchievementMutation } from 'entities/managerTeamAchievements/mutations';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useDeleteManagerTeamAchievement = () => {
  const queryClient = useQueryClient();

  const { mutate: deleteManagerTeamAchievementMutation, isLoading } =
    useDeleteManagerTeamAchievementMutation();

  const deleteManagerTeamAchievement = (teamAchievementId: TEntityId) => {
    deleteManagerTeamAchievementMutation(teamAchievementId, {
      onError: () =>
        notify('Не удалось удалить командное достижение тренера', { type: 'error' }),
      onSuccess: () =>
        queryClient.resetQueries([
          managerTeamAchievementsQueryKeys.managerTeamAchievements,
        ]),
    });
  };

  return {
    isDeletingManagerTeamAchievement: isLoading,
    deleteManagerTeamAchievement,
  };
};
