import { useProtectedRoutes } from 'app/hooks/useProtectedRoutes';
import { TRouteWithPermission } from 'app/types';
import { allOperations } from 'entities/user/model/consts';
import { useSession } from 'feature/auth/model/useSession';
import { AuthRedirect } from 'feature/auth/ui/AuthRedirect/AuthRedirect';
import { AuthPage } from 'pages/AuthPage/AuthPage';
import { Dashboard } from 'pages/dashboard/Dashboard';
import { ErrorPage } from 'pages/error-page/ErrorPage';
import { CreateManagerPage } from 'pages/manager/CreateManagerPage/CreateManagerPage';
import { ManagerCareersPage } from 'pages/manager/ManagerCareersPage/ManagerCareersPage';
import { ManagerSocialNetworksPage } from 'pages/manager/ManagerSocialNetworksPage/ManagerSocialNetworksPage';
import { ManagerTeamAchievementsPage } from 'pages/manager/ManagerTeamAchievementsPage/ManagerTeamAchievementsPage';
import { AddNewsPost } from 'pages/news/AddNewsPost/AddNewsPost';
import { EditNewsPost } from 'pages/news/EditNewsPost/EditNewsPost';
import { NewsPage } from 'pages/news/NewsPage/NewsPage';
import { CreatePlayerPage } from 'pages/player/CreatePlayerPage/CreatePlayerPage';
import { EditPlayerPage } from 'pages/player/EditPlayerPage/EditPlayerPage/EditPlayerPage';
import { PlayerAchievementsPage } from 'pages/player/PlayerAchievementsPage/PlayerAchievementsPage';
import { PlayerCareersPage } from 'pages/player/PlayerCareersPage/PlayerCareersPage';
import { PlayerSocialNetworksPage } from 'pages/player/PlayerSocialNetworksPage/PlayerSocialNetworksPage';
import { PlayerTeamAchievementsPage } from 'pages/player/PlayerTeamAchievementsPage/PlayerTeamAchievementsPage';
import { NewsCategoriesPage } from 'pages/settings/categories/NewsCategoriesPage/NewsCategoriesPage';
import { PhotoGalleryCategoriesPage } from 'pages/settings/categories/PhotoGalleryCategoriesPage/PhotoGalleryCategoriesPage';
import { VideoCategoriesPage } from 'pages/settings/categories/VideoCategoriesPage/VideoCategoriesPage';
import { LabelsPage } from 'pages/settings/LabelsPage/LabelsPage';
import { EditStory } from 'pages/Stories/EditStory/EditStory';
import { StoriesListWrapper } from 'pages/Stories/StoriesList/StoriesListWrapper';
import { TeamPage } from 'pages/team/TeamPage/TeamPage';
import { TeamsPage } from 'pages/team/TeamsPage/TeamsPage';
import { UIKit } from 'pages/UIKit';
import { AddVideoPage } from 'pages/video/AddVideoPage/AddVideoPage';
import { EditVideo } from 'pages/video/EditVideo/EditVideo';
import { VideoPage } from 'pages/video/VideoPage/VideoPage';
import { useMemo } from 'react';
import { Navigate, RouteObject, RouterProvider } from 'react-router-dom';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { isProduction } from 'shared/constants';
import { routes } from 'shared/routes';
import { sentryCreateBrowserRouter } from 'shared/sentry';
import { RootLayout } from 'shared/ui/layouts/root-layout/RootLayout';
import { Navigation } from 'widgets/navigation/ui/Navigation/Navigation';
import { SettingsLayout } from 'widgets/settings-layout/ui/SettingsLayout/SettingsLayout';

const devRoutes = isProduction ? [] : [{ path: routes.uikit, element: <UIKit /> }];

const permissionRoutes: TRouteWithPermission[] = [
  {
    index: true,
    element: <Dashboard />,
  },
  {
    path: routes.stories,
    element: <StoriesListWrapper />,
    permissions: {
      StoryGroup: allOperations,
      Story: allOperations,
      'Story::Element': allOperations,
    },
  },
  {
    path: routes.editStory,
    element: <EditStory />,
    permissions: {
      StoryGroup: allOperations,
      Story: allOperations,
      'Story::Element': allOperations,
    },
  },
  {
    path: routes.news,
    element: <NewsPage />,
    permissions: {
      NewsPost: allOperations,
      Match: ['read'],
      Player: ['read'],
      Tournament: ['read'],
      Season: ['read'],
      Team: ['read'],
      Label: ['read'],
      NewsCategory: ['read'],
      Manager: ['read'],
    },
  },
  {
    path: routes.createNewsPost,
    element: <AddNewsPost />,
    permissions: {
      NewsPost: allOperations,
      Match: ['read'],
      Player: ['read'],
      Tournament: ['read'],
      Season: ['read'],
      Team: ['read'],
      Label: ['read'],
      NewsCategory: ['read'],
      Manager: ['read'],
    },
  },
  {
    path: routes.editNewsPost.url,
    element: <EditNewsPost />,
    permissions: {
      NewsPost: allOperations,
      Match: ['read'],
      Player: ['read'],
      Tournament: ['read'],
      Season: ['read'],
      Team: ['read'],
      Label: ['read'],
      NewsCategory: ['read'],
      Manager: ['read'],
    },
  },
  {
    path: routes.video,
    element: <VideoPage />,
    permissions: {
      VideoPost: allOperations,
      Match: ['read'],
      Player: ['read'],
      Tournament: ['read'],
      Season: ['read'],
      Team: ['read'],
      Label: ['read'],
      VideoCategory: ['read'],
      Manager: ['read'],
    },
  },
  {
    path: routes.createVideo,
    element: <AddVideoPage />,
    permissions: {
      VideoPost: allOperations,
      Match: ['read'],
      Player: ['read'],
      Tournament: ['read'],
      Season: ['read'],
      Team: ['read'],
      Label: ['read'],
      VideoCategory: ['read'],
      Manager: ['read'],
    },
  },
  {
    path: routes.editVideo.url,
    element: <EditVideo />,
    permissions: {
      VideoPost: allOperations,
      Match: ['read'],
      Player: ['read'],
      Tournament: ['read'],
      Season: ['read'],
      Team: ['read'],
      Label: ['read'],
      VideoCategory: ['read'],
      Manager: ['read'],
    },
  },
  {
    path: routes.createPlayer.url,
    element: <CreatePlayerPage />,
  },
  {
    path: routes.editPlayer.url,
    element: <EditPlayerPage />,
  },
  {
    path: routes.managerTeamAchievements.url,
    element: <ManagerTeamAchievementsPage />,
  },
  {
    path: routes.managerSocialNetworks.url,
    element: <ManagerSocialNetworksPage />,
  },
  {
    path: routes.managerCareers.url,
    element: <ManagerCareersPage />,
  },
  {
    path: routes.playerCareers.url,
    element: <PlayerCareersPage />,
  },
  {
    path: routes.createManager.url,
    element: <CreateManagerPage />,
  },
  {
    path: routes.playerSocialNetworks.url,
    element: <PlayerSocialNetworksPage />,
  },
  {
    path: routes.playerAchievements.url,
    element: <PlayerAchievementsPage />,
  },
  {
    path: routes.playerTeamAchievements.url,
    element: <PlayerTeamAchievementsPage />,
  },
  {
    path: routes.managerCareers.url,
    element: <ManagerCareersPage />,
  },
  {
    path: routes.settings,
    element: <SettingsLayout />,
    children: [
      {
        element: <LabelsPage />,
        path: routes.labels,
        permissions: {
          Label: allOperations,
        },
      },
      {
        element: <VideoCategoriesPage />,
        path: routes.categoryVideo,
        permissions: {
          VideoCategory: allOperations,
        },
      },
      {
        element: <NewsCategoriesPage />,
        path: routes.categoryNews,
        permissions: {
          NewsCategory: allOperations,
        },
      },
      {
        element: <PhotoGalleryCategoriesPage />,
        path: routes.categoryPhotogallery,
        permissions: {
          PhotoGalleryCategory: allOperations,
        },
      },
    ],
  },
  {
    path: routes.editTeam.url,
    element: <TeamPage />,
  },
  {
    path: routes.teams,
    element: <TeamsPage />,
  },
  {
    path: routes.auth,
    element: <Navigate replace={true} to={routes.root} />,
  },
];

const privateRoutesObject: RouteObject = {
  path: '/',
  element: <RootLayout navigation={<Navigation />} />,
  errorElement: <ErrorPage />,
};

const authRoutesObject: RouteObject = {
  path: '/',
  element: <RootLayout />,
  errorElement: <AuthRedirect />,
  children: [
    { index: true, element: <Navigate replace to={routes.auth} /> },
    { path: routes.auth, element: <AuthPage /> },
  ],
};

export const Router = () => {
  const { isAuthorized, isLoading } = useSession();
  const protectedRoutes = useProtectedRoutes(permissionRoutes);
  const privateRoutes = {
    ...privateRoutesObject,
    children: [...devRoutes, ...protectedRoutes],
  };
  const totalRoutes = isAuthorized ? privateRoutes : authRoutesObject;
  const router = useMemo(
    () => sentryCreateBrowserRouter([totalRoutes]),
    [isAuthorized],
  );
  return isLoading ? (
    <Centered>
      <Loader size="l" />
    </Centered>
  ) : (
    <RouterProvider router={router} />
  );
};
