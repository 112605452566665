import { useFetchPlayerSocialNetworksQuery } from 'entities/playerSocialNetworks/queries';
import { useFetchSocialNetworksQuery } from 'entities/socialNetworks/queries';
import { combinePlayerSocialNetworks } from 'pages/player/PlayerSocialNetworksPage/helpers';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePagination } from 'shared/hooks/usePagination';
import { routes } from 'shared/routes';

export const usePlayerSocialNetworks = () => {
  const navigate = useNavigate();
  const { teamId = '', playerId = '' } = useParams<{
    teamId: string;
    playerId: string;
  }>();
  const { selectedPage, handlePageChange } = usePagination();

  const {
    data: playerSocialNetworks,
    isLoading: isPlayerSocialNetworksLoading,
    error: playerSocialNetworksError,
  } = useFetchPlayerSocialNetworksQuery({
    page: selectedPage,
    filter: playerId ? { 'filter[player_id][eq]': playerId } : {},
  });
  const {
    data: socialNetworksData,
    isLoading: isSocialNetworksLoading,
    error: socialNetworksError,
  } = useFetchSocialNetworksQuery();

  const combinedSocialNetworks = useMemo(() => {
    if (!playerSocialNetworks || !socialNetworksData) {
      return [];
    }

    return combinePlayerSocialNetworks(
      playerSocialNetworks.socialLinks,
      socialNetworksData,
    );
  }, [playerSocialNetworks?.socialLinks, socialNetworksData]);

  const handleConfirmButtonClick = () => {
    navigate(routes.playerCareers.as(teamId, playerId), {
      replace: true,
    });
  };

  return {
    socialLinks: combinedSocialNetworks,
    isLoading: isPlayerSocialNetworksLoading || isSocialNetworksLoading,
    error: playerSocialNetworksError || socialNetworksError,
    page: playerSocialNetworks?.page,
    total: playerSocialNetworks?.total,
    handleConfirmButtonClick,
    handlePageChange,
  };
};
