import { useDeletePlayerCareer } from 'pages/player/PlayerCareersPage/hooks/useDeletePlayerCareer';
import { useEditPlayerCareer } from 'pages/player/PlayerCareersPage/hooks/useEditPlayerCareer';
import { TPlayerCareersActions } from 'pages/player/PlayerCareersPage/types';
import { useBoolean } from 'react-use';
import { TGroupAction } from 'shared/types/common';

export const usePlayerCareersActions = ({
  activeCareerId,
  setActiveCareerId,
  career,
}: TPlayerCareersActions) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const { isDeletingPlayerCareer, deletePlayerCareer } = useDeletePlayerCareer();
  const isActive = career.id === activeCareerId;

  const { openEditModal, editCareerModalProps } = useEditPlayerCareer(
    career.id,
    career,
  );

  const actions: TGroupAction[] = [
    {
      title: 'Редактировать',
      icon: 'edit-02',
      action: openEditModal,
    },
    {
      title: 'Удалить',
      icon: 'trash',
      action: () => setIsDeleteModalOpen(true),
    },
  ];

  const handleActionsButtonClick = () => {
    setActiveCareerId(isActive ? null : career.id);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setActiveCareerId(null);
  };

  const handleConfirmDeletion = () => {
    handleCloseDeleteModal();
    deletePlayerCareer(career.id);
  };

  return {
    actions,
    isDeleteModalOpen,
    isDeletingPlayerCareer,
    isActive,
    editCareerModalProps,
    handleActionsButtonClick,
    handleConfirmDeletion,
    handleCloseDeleteModal,
  };
};
