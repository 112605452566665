import { useQueryClient } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { teamsQueryKeys } from 'entities/teams/consts';
import { createTeamMutation } from 'entities/teams/mutations';
import { mapTeamModalFieldsToDTO } from 'feature/team-modal/mapper/mapTeamModalFieldsToDTO';
import { TTeamModalFields } from 'feature/team-modal/TeamModal';
import { useState } from 'react';
import { notify } from 'shared/components/Notification';

export const useCreateTeam = () => {
  const queryClient = useQueryClient();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { mutateAsync, isLoading } = createTeamMutation();
  const { locales } = useLocales();

  const closeModal = () => setIsOpenModal(false);
  const openModal = () => setIsOpenModal(true);

  const createTeam = async (createTeamModalFields: TTeamModalFields) => {
    const dto = await mapTeamModalFieldsToDTO(createTeamModalFields, locales);

    await mutateAsync(dto, {
      onSuccess: () => {
        queryClient.resetQueries([teamsQueryKeys.teams]);
      },
      onError: () => {
        notify('Не удалось создать команду', { type: 'error' });
      },
      onSettled: closeModal,
    });
  };

  return { isLoading, createTeam, openModal, closeModal, isOpenModal };
};
