import { TPlayerTeamAchievement } from 'entities/playerTeamAchievements/types';
import { PlayerTeamAchievementsActions } from 'pages/player/PlayerTeamAchievementsPage/PlayerTeamAchievementsActions/PlayerTeamAchievementsActions';
import React from 'react';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elements-list-table/types';

import styles from './PlayerTeamAchievementsPage.module.css';

export const getPlayerTeamAchievementsColumnsConfig: TConfigCreator<
  TPlayerTeamAchievement
> = (defaultLocale, activeSocialLinkId, setActiveSocialLinkId) => [
  {
    title: 'Название',
    cellClassName: styles.nameColumn,
    render: (item) => (
      <Text className={styles.text} color="var(--gray-500)">
        {item.translations?.[defaultLocale]?.title}
      </Text>
    ),
  },
  {
    title: 'Год',
    render: (item) => <Text color="var(--gray-500)">{item.year}</Text>,
  },
  {
    title: 'ID',
    render: (item) => <Text color="var(--gray-500)">{item.id}</Text>,
  },
  {
    title: '',
    render: (playerTeamAchievement) => (
      <PlayerTeamAchievementsActions
        activePlayerTeamAchievementId={activeSocialLinkId}
        playerTeamAchievement={playerTeamAchievement}
        setActivePlayerTeamAchievementId={setActiveSocialLinkId}
      />
    ),
  },
];
