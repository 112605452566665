import {
  TStoryEditorActionType,
  TStoryEditorState,
} from 'pages/Stories/EditStory/types';
import { exhaustiveCheck } from 'shared/lib/exhaustiveCheck';

export const storyEditorStateReducer = (
  state: TStoryEditorState,
  action: TStoryEditorActionType,
): TStoryEditorState => {
  switch (action.type) {
    case 'highlightElement': {
      return {
        ...state,
        highlightedElementId: action.payload,
      };
    }
    case 'selectLocale': {
      return {
        ...state,
        selectedLocale: action.payload,
      };
    }
    case 'selectStory': {
      return {
        ...state,
        selectedStory: action.payload,
      };
    }
    case 'selectPreviewSize': {
      return {
        ...state,
        selectedPreviewSize: action.payload,
      };
    }
    case 'selectStoryElement': {
      return {
        ...state,
        selectedElement: action.payload,
      };
    }
    case 'updateSelectedStoryElementSettings': {
      if (!state.selectedElement) {
        return state;
      }
      const { selectedLocale, selectedElement } = state;
      return {
        ...state,
        selectedElement: {
          ...selectedElement,
          translations: {
            ...selectedElement.translations,
            [selectedLocale]: {
              ...selectedElement?.translations[selectedLocale],
              settings: {
                ...selectedElement.translations[selectedLocale].settings,
                ...action.payload,
              },
            },
          },
        },
      };
    }
    case 'elementsWithErrors': {
      return {
        ...state,
        elementsWithErrors: action.payload,
      };
    }
    default: {
      return exhaustiveCheck(action);
    }
  }
};
