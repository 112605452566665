import { useStoryEditorContext } from 'pages/Stories/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/Stories/EditStory/hooks/useStoryEditorDispatchContext';
import { TPreviewSize } from 'pages/Stories/EditStory/types';
import { BlurredImage } from 'shared/components/BlurredImage/BlurredImage';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { OverlayModal } from 'shared/components/OverlayModal/OverlayModal';
import { toBoolean } from 'shared/lib/toBoolean';

import { PreviewElement } from './PreviewElement';
import styles from './StoryPreviewModal.module.css';

type TProps = {
  previewSize: TPreviewSize;
};
export const StoryPreviewModal = ({ previewSize }: TProps) => {
  const { selectedLocale, selectedStory, storyElements } = useStoryEditorContext();
  const dispatch = useStoryEditorDispatchContext();

  const storyElementsToShow = storyElements.filter(
    (element) =>
      element.story === selectedStory?.id.toString() &&
      !!element.translations[selectedLocale].elementPosition,
  );

  if (!selectedStory) {
    return null;
  }

  const handleClose = () => {
    dispatch({
      type: 'selectPreviewSize',
      payload: null,
    });
  };
  return (
    <OverlayModal opened={toBoolean(previewSize)} onClose={handleClose}>
      <div className={styles.root}>
        <div className={styles.closeButtonWrapper}>
          <IconButton
            className={styles.closeButton}
            icon="x"
            iconSize="s"
            onClick={handleClose}
          />
        </div>
        <div
          className={styles.storyImageWrapper}
          style={{
            width: previewSize?.width,
            height: previewSize?.height,
          }}
        >
          <BlurredImage alt="Story image" imageUrl={selectedStory.image.x3} />
          {storyElementsToShow.map((element) => (
            <PreviewElement
              key={element.id}
              element={element}
              selectedLocale={selectedLocale}
            />
          ))}
        </div>
      </div>
    </OverlayModal>
  );
};
