import { zodResolver } from '@hookform/resolvers/zod';
import isEmpty from 'lodash/isEmpty';
import {
  BUTTON_LINK_MAX_LENGTH,
  BUTTON_TITLE_MAX_LENGTH,
} from 'pages/Stories/EditStory/components/forms/ButtonElementForm/constants';
import { buttonElementValidationSchema } from 'pages/Stories/EditStory/components/forms/ButtonElementForm/validationSchema';
import { BackButton } from 'pages/Stories/EditStory/components/forms/components/BackButton/BackButton';
import { FontSettings } from 'pages/Stories/EditStory/components/forms/components/FontSettings/FontSettings';
import { sizeOptions } from 'pages/Stories/EditStory/components/forms/constants';
import { useFormErrors } from 'pages/Stories/EditStory/components/forms/hooks/useFormErrors';
import {
  TButtonElementFormValues,
  TCommonElementFormProps,
  TFormLabelResolver,
  TFormValueResolver,
  TOption,
} from 'pages/Stories/EditStory/components/forms/types';
import { useStoryEditorContext } from 'pages/Stories/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/Stories/EditStory/hooks/useStoryEditorDispatchContext';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Divider } from 'shared/components/Divider';
import { Form, FormInput, SimpleSelect } from 'shared/components/Form';
import { ColorInput } from 'shared/components/Form/ColorInput/ColorInput';
import { DEFAULT_APP_URL } from 'shared/consts';

import styles from '../ElementForm.module.css';

const labelResolver: TFormLabelResolver = (option: TOption) => option.name;
const valueResolver: TFormValueResolver = (option: TOption) => option.value;

export const ButtonElementForm = ({
  selectedElement,
}: TCommonElementFormProps<'button'>) => {
  const { selectedLocale } = useStoryEditorContext();
  const dispatch = useStoryEditorDispatchContext();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    getValues,
    trigger,
  } = useForm<TButtonElementFormValues>({
    mode: 'onSubmit',
    values: selectedElement?.translations[selectedLocale].settings,
    resolver: zodResolver(buttonElementValidationSchema),
    shouldFocusError: false,
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  useFormErrors({
    errors,
    selectedElementId: selectedElement.id,
    updateElement: () => {
      if (!isEmpty(errors)) {
        dispatch({
          type: 'updateSelectedStoryElementSettings',
          payload: getValues(),
        });
      }
    },
  });

  const submitForm = (data: TButtonElementFormValues) => {
    dispatch({ type: 'updateSelectedStoryElementSettings', payload: data });
  };

  const submit = useCallback(() => handleSubmit(submitForm), [submitForm]);

  useEffect(() => {
    const subscription = watch(() => handleSubmit(submitForm)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div className={styles.root}>
      <Form action={submit} className={styles.formComponent}>
        <FormInput
          control={control}
          fieldName="title"
          inputClassName={styles.input}
          label="Заголовок кнопки"
          maxLength={BUTTON_TITLE_MAX_LENGTH}
          placeholder="Введите заголовок"
          required
        />
        <FormInput
          control={control}
          fieldName="link"
          inputClassName={styles.input}
          label="Ссылка"
          maxLength={BUTTON_LINK_MAX_LENGTH}
          placeholder={DEFAULT_APP_URL}
          required
        />
        <Divider />
        <SimpleSelect
          control={control}
          fieldName="size"
          inline
          inlineInputClassName={styles.formField}
          label="Размер кнопки"
          labelResolver={labelResolver}
          menuWidth={116}
          options={sizeOptions}
          placeholder="Select"
          valueResolver={valueResolver}
        />
        <ColorInput
          control={control}
          fieldName="fill"
          inline
          inlineInputClassName={styles.formField}
          label="Цвет текста"
          placeholder=""
        />
        <ColorInput
          control={control}
          fieldName="background"
          inline
          inlineInputClassName={styles.formField}
          label="Фон"
          placeholder=""
        />
        <Divider />
        <FontSettings
          control={control}
          fontFamilyName="fontFamily"
          fontStyleName="fontStyle"
          labelResolver={labelResolver}
          valueResolver={valueResolver}
          withTitle
        />
      </Form>
      <BackButton disabled={!isEmpty(errors)} />
    </div>
  );
};
