import { TDefaultRightPanelProps } from 'feature/right-panel/types';
import { CommonReview } from 'feature/right-panel/ui/CommonReview/CommonReview';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TVideoPostFields } from 'widgets/video-form/types';

export const VideoReview = (commonProps: TDefaultRightPanelProps) => {
  const { control } = useFormContext<TVideoPostFields>();

  const { field: matchField } = useController({
    control,
    name: 'match',
  });

  const { fieldState: reviewFieldState } = useController({
    control,
    name: 'review',
  });

  return (
    <CommonReview
      control={control}
      description="Видео будет привязано к кнопке “Видеообзор” на карточке матча."
      disabledSwitch={!matchField.value}
      fieldName="review"
      isError={reviewFieldState.invalid}
      label="“Видеообзор” матча"
      switchLabel="Сделать видеообзором"
      title="Сделайте видео “Видеообзором“ матча"
      switchTip={
        'Активно после выбора связи с матчем \n Деактивируется после удаления связи с матчем'
      }
      {...commonProps}
    />
  );
};
