import axios from 'axios';
import { useEditNewsPostMutation } from 'entities/news/mutations';
import { useController, useFormContext } from 'react-hook-form';
import {
  TAddNewsPostFields,
  TEditNewsPostFields,
} from 'shared/components/NewsPostForm/types';
import { notify } from 'shared/components/Notification';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_TITLE } from 'shared/validation';

export const useEditNewsPost = () => {
  const { getFieldState, control } = useFormContext<TEditNewsPostFields>();
  const {
    field: { value: created },
    fieldState: { isDirty: isCreatedDirty },
  } = useController({
    control,
    name: 'locale.created',
  });

  const isChangeTitle =
    created.some(
      (locale) => getFieldState(`content.title.translations.${locale}`).isDirty,
    ) || isCreatedDirty;
  const isChangeMainImage =
    created.some(
      (locale) => getFieldState(`content.preview.translations.${locale}`).isDirty,
    ) || isCreatedDirty;

  const { mutateAsync } = useEditNewsPostMutation({
    isChangeMainImage,
    isChangeTitle,
  });

  return async (data: TAddNewsPostFields) => {
    try {
      await mutateAsync(data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        notify(DEFAULT_ERROR_MESSAGE, { type: 'error', title: DEFAULT_ERROR_TITLE });
      }
    }
  };
};
