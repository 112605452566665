import { TDefaultRightPanelProps } from 'feature/right-panel/types';
import { RightPanelCollapseWrapper } from 'feature/right-panel/ui/RightPanelCollapseWrapper/RightPanelCollapseWrapper';
import { UploadImage } from 'feature/right-panel/ui/UploadImage/UploadImage';
import { Control, ControllerFieldState, FieldValues, Path } from 'react-hook-form';

type TProps<T extends FieldValues> = {
  fieldState: ControllerFieldState;
  control: Control<T>;
  previewDataUrl: string;
  previewFile?: File;
  description: string;
  fileFieldName: Path<T>;
  aspectRatio: number;
};

export const CommonPreview = <T extends FieldValues>({
  fieldState,
  description,
  control,
  previewDataUrl,
  previewFile,
  fileFieldName,
  aspectRatio,
  ...restProps
}: TDefaultRightPanelProps & TProps<T>) => {
  const isEmptyValue = !previewFile;

  return (
    <RightPanelCollapseWrapper
      description={description}
      disabledNext={isEmptyValue}
      isError={fieldState.invalid}
      label="Превью"
      title="Загрузите изображение"
      {...restProps}
    >
      <UploadImage
        aspectRatio={aspectRatio}
        control={control}
        fieldName={fileFieldName}
        imageUrl={previewDataUrl}
        label="Превью"
        modalTitle="Настройка превью"
        required
      />
    </RightPanelCollapseWrapper>
  );
};
