import { TCreatePlayerDTO } from 'entities/players/model/types';
import { mapFileToUpload } from 'shared/api/mappers';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toNumber } from 'shared/lib/toNumber';
import { TLangType } from 'shared/types/common';
import { TPlayerFormFields } from 'widgets/player-form/types/types';

export const mapPlayerFormFieldsToDTO = async (
  playerFormFields: TPlayerFormFields,
  locales: TLangType[],
): Promise<TCreatePlayerDTO> => {
  return {
    status: playerFormFields.status,
    discardedAt:
      playerFormFields.discarded === 'true' && playerFormFields.discardedAt
        ? playerFormFields.discardedAt.toISOString()
        : undefined,
    playerPositionId: toNumber(playerFormFields.playerPositionId),
    teamId: toNumber(playerFormFields.teamId),
    countryId: toNumber(playerFormFields.countryId),
    number: playerFormFields.number,
    height: playerFormFields.height ? toNumber(playerFormFields.height) : undefined,
    weight: playerFormFields.weight ? toNumber(playerFormFields.weight) : undefined,
    debutDate: playerFormFields.debutDate
      ? playerFormFields.debutDate.toISOString()
      : undefined,
    birthDate: playerFormFields.birthDate
      ? playerFormFields.birthDate.toISOString()
      : undefined,
    nickname: playerFormFields.nickname ? playerFormFields.nickname : undefined,
    translations: reduceTranslationObject(locales, (locale) => ({
      firstName: playerFormFields.firstName[locale],
      lastName: playerFormFields.lastName[locale],
      biography: playerFormFields.biography
        ? playerFormFields.biography[locale]
        : undefined,
      pupil: playerFormFields.pupil ? playerFormFields.pupil[locale] : undefined,
      sharingLink: playerFormFields.sharingLink
        ? playerFormFields.sharingLink
        : undefined,
      shopLink: playerFormFields.shopLink ? playerFormFields.shopLink : undefined,
    })),
    picture: playerFormFields.picture
      ? await mapFileToUpload(playerFormFields.picture)
      : undefined,
    photo: playerFormFields.photo
      ? await mapFileToUpload(playerFormFields.photo)
      : undefined,
  };
};
