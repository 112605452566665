import commonElementFormStyles from 'pages/Stories/EditStory/components/forms/ElementForm.module.css';
import { TQuizElementFormValues } from 'pages/Stories/EditStory/components/forms/types';
import React, { ReactElement } from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import { ColorInput } from 'shared/components/Form/ColorInput/ColorInput';
import { Text } from 'shared/components/Text/Text';

type TProps = {
  control: Control<TQuizElementFormValues>;
};

export const TitleColors = ({ control }: TProps): ReactElement => {
  return (
    <>
      <Text
        className={commonElementFormStyles.subtitle}
        size={14}
        tag="h2"
        weight="Regular"
      >
        Цвет заголовка
      </Text>
      <ColorInput
        control={control}
        fieldName="fill"
        inline
        inlineInputClassName={commonElementFormStyles.formField}
        label="Заливка"
        placeholder=""
      />
      <ColorInput
        control={control}
        fieldName="background"
        inline
        inlineInputClassName={commonElementFormStyles.formField}
        label="Фон"
        placeholder=""
      />
    </>
  );
};
