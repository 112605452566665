import { useLocales } from 'entities/config/lib/useLocales';
import { useCreateNewsCategoryMutation } from 'entities/newsCategories/mutations';
import { useFetchNewsCategoriesQuery } from 'entities/newsCategories/queries';
import { NewsCategoriesPageTable } from 'pages/settings/categories/NewsCategoriesPage/NewsCategoriesPageTable';
import React, { useCallback, useState } from 'react';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { usePagination } from 'shared/hooks/usePagination';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TTranslations } from 'shared/types/common';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

import styles from './NewsCategoriesPage.module.css';

export const NewsCategoriesPage = () => {
  const { locales } = useLocales();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const createNewsCategories = useCreateNewsCategoryMutation();

  const { selectedPage, handlePageChange } = usePagination();
  const { data, isLoading, error } = useFetchNewsCategoriesQuery({
    page: selectedPage,
  });

  const closeModal = useCallback(() => {
    setShowCreateModal(false);
  }, []);

  const openModal = useCallback(() => {
    setShowCreateModal(true);
  }, []);

  const createNewsCategory = (translations: TTranslations<string>) => {
    createNewsCategories.mutate({
      translations: reduceTranslationObject(locales, (locale) => ({
        name: translations[locale],
      })),
    });
    closeModal();
  };

  return (
    <div className={styles.root}>
      <CommonListWrapper
        error={error}
        isEmpty={!data?.categories || (data?.total === 0 && !isLoading)}
        isLoading={isLoading}
        title="Категории новости"
        createButtonProps={{
          content: 'Создать категорию',
          onClick: openModal,
        }}
        paginationProps={{
          selectedPage: data?.page ?? 1,
          totalItems: data?.total ?? 0,
          onPageChange: handlePageChange,
        }}
      >
        <NewsCategoriesPageTable categories={data?.categories || []} />
      </CommonListWrapper>
      <LocalizationModal
        actionSubmit={createNewsCategory}
        closeModal={closeModal}
        isOpened={showCreateModal}
        subtitle="Название категории"
        title="Создание категории"
        inputProps={{
          maxLength: 255,
          placeholder: 'Введите заголовок',
          required: true,
          size: 's',
        }}
      />
    </div>
  );
};
