import { TEntityId } from 'shared/types/common';

export const managersQueryKeys = {
  managers: 'managers',
  manager: 'manager',
};

const managerBaseUrl = 'managers';

export const managersApiKeys = {
  fetchManagers: managerBaseUrl,
  createManager: managerBaseUrl,
  fetchManager: (managerId: TEntityId) => `${managerBaseUrl}/${managerId}`,
  deleteManager: (managerId: TEntityId) => `${managerBaseUrl}/${managerId}`,
};
