import { toBoolean } from 'shared/lib/toBoolean';
import { routes } from 'shared/routes';
import { TEntityId } from 'shared/types/common';

export type TPlayerNavigationLink = {
  name: string;
  route: string;
  disabled?: boolean;
};

export const getPlayerNavigationLinks = (
  teamId: TEntityId = '',
  playerId: TEntityId = '',
): TPlayerNavigationLink[] => [
  {
    name: 'Основные данные',
    route: playerId
      ? routes.editPlayer.as(teamId, playerId)
      : routes.createPlayer.as(teamId),
  },
  {
    name: 'Ссылки на соц.сети',
    route: routes.playerSocialNetworks.as(teamId, playerId),
    disabled: !toBoolean(playerId),
  },
  {
    name: 'Карьера',
    route: routes.playerCareers.as(teamId, playerId),
    disabled: !toBoolean(playerId),
  },
  {
    name: 'Командные достижения',
    route: routes.playerTeamAchievements.as(teamId, playerId),
    disabled: !toBoolean(playerId),
  },
  {
    name: 'Личные достижения',
    route: routes.playerAchievements.as(teamId, playerId),
    disabled: !toBoolean(playerId),
  },
];
