import { managersApiKeys } from 'entities/managers/api/consts';
import { mapFetchManagersResponse } from 'entities/managers/mapper/mapFetchManagersResponse';
import { TCreateManagerDTO, TManager } from 'entities/managers/model/types';
import { del, get, post } from 'shared/api/axios';
import { TCreatePaginationParamsReturnType } from 'shared/api/utils';
import { TEntityId } from 'shared/types/common';

export type TFetchManagersArgs = {
  paginationParams: TCreatePaginationParamsReturnType;
  filter?: Record<string, string>;
};

export const fetchManagers = ({ paginationParams, filter }: TFetchManagersArgs) =>
  get<TManager[], true>(managersApiKeys.fetchManagers, {
    params: { ...paginationParams, ...filter },
  }).then((response) => mapFetchManagersResponse(response.data));

export const fetchManager = (id: TEntityId) =>
  get<TManager>(managersApiKeys.fetchManager(id)).then(
    (response) => response.data.data,
  );

export const deleteManager = (managerId: TEntityId) =>
  del(managersApiKeys.deleteManager(managerId));

export const createManager = (data: TCreateManagerDTO) =>
  post<TManager>(managersApiKeys.createManager, data).then(
    (response) => response.data.data,
  );
