import { useUserStore } from 'entities/user/model/store';
import { TUserOperation, TUserResource } from 'entities/user/model/types';
import isEmpty from 'lodash/isEmpty';
import { isSubset } from 'shared/lib/isSubset';

export const usePermissions = () => {
  const permissions = useUserStore((state) => state.user?.permissions);

  return (operations: TUserOperation[], resources: TUserResource[]) => {
    if (!permissions || isEmpty(permissions)) {
      return false;
    }

    if (!resources.every((resource) => resource in permissions)) {
      return false;
    }

    for (const resource of resources) {
      if (!isSubset(operations, permissions[resource] as TUserOperation[])) {
        return false;
      }
    }

    return true;
  };
};
