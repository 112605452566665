import {
  TDefaultRightPanelProps,
  TSelectFieldProps,
} from 'feature/right-panel/types';
import { RightPanelCollapseWrapper } from 'feature/right-panel/ui/RightPanelCollapseWrapper/RightPanelCollapseWrapper';
import { Control, FieldValues } from 'react-hook-form';
import { FormSelect } from 'shared/components/Form/Select/FormSelect';

type TProps<T extends FieldValues> = {
  labels: TSelectFieldProps<T>;
  categories: TSelectFieldProps<T>;
  description: string;
  control: Control<T>;
};

export const CommonLabel = <T extends FieldValues>({
  labels,
  categories,
  control,
  description,
  ...restProps
}: TDefaultRightPanelProps & TProps<T>) => {
  return (
    <RightPanelCollapseWrapper
      description={description}
      label="Категоризация"
      title="Выберите категоризацию"
      {...restProps}
    >
      <FormSelect
        control={control}
        fieldName={labels.fieldName}
        label="Лейблы"
        multiple={true}
        options={labels.data}
        placeholder={labels.placeholder}
        search
        tip="Можно выбрать несколько лейблов"
      />
      <FormSelect
        control={control}
        fieldName={categories.fieldName}
        label="Категории"
        multiple={true}
        options={categories.data}
        placeholder={categories.placeholder}
        search
        tip="Можно выбрать несколько категорий"
      />
    </RightPanelCollapseWrapper>
  );
};
