import { useQuery } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { mapApiNewsPostToNewsPost } from 'entities/news/mapper/mapApiNewsPostToNewsPost';
import { mapNewsPostToEdit } from 'pages/news/EditNewsPost/mapper';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

import { fetchNews, fetchNewsPost } from './api';
import { newsQueryKeys } from './consts';

export const useFetchNewsQuery = (page = 1) => {
  const { defaultLocale } = useLocales();

  return useQuery({
    queryKey: [newsQueryKeys.news, page],
    queryFn: () => fetchNews(page, defaultLocale),
    cacheTime: 0,
  });
};

export const useFetchNewsPostQuery = (newsPostId: TEntityId = '') => {
  const { defaultLocale } = useLocales();

  return useQuery({
    queryKey: [newsQueryKeys.newsPost, newsPostId],
    queryFn: async () =>
      mapApiNewsPostToNewsPost(await fetchNewsPost(newsPostId), defaultLocale),
    enabled: toBoolean(newsPostId),
  });
};

export const useFetchNewsPostForEditQuery = (newsPostId: TEntityId = '') => {
  const { defaultLocale } = useLocales();
  return useQuery({
    queryKey: [newsQueryKeys.newsPostForEdit, newsPostId],
    queryFn: async () =>
      mapNewsPostToEdit(
        mapApiNewsPostToNewsPost(await fetchNewsPost(newsPostId), defaultLocale),
        defaultLocale,
      ),
    enabled: toBoolean(newsPostId),
    cacheTime: 0,
  });
};
