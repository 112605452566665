import { useDeletePlayerSocialNetworkMutation } from 'entities/playerSocialNetworks/mutations';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useDeletePlayerSocialNetwork = () => {
  const { mutate: deletePlayerSocialNetworkMutation, isLoading } =
    useDeletePlayerSocialNetworkMutation();

  const deletePlayerSocialNetwork = (playerSocialNetworkId: TEntityId) => {
    deletePlayerSocialNetworkMutation(playerSocialNetworkId, {
      onError: () =>
        notify('Не удалось удалить соц. сеть игрока', { type: 'error' }),
    });
  };

  return {
    isDeletingPlayerSocialNetwork: isLoading,
    deletePlayerSocialNetwork,
  };
};
