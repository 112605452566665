import { useQuery } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { videoCategoriesQueryKeys } from 'entities/videoCategories/consts';
import { TPaginatedParameters } from 'shared/api/types';

import { fetchVideoCategories } from './api';

type TVideoFetchParams = TPaginatedParameters & {
  sort?: 'name' | 'importance' | '-name' | '-importance';
};

export const useFetchVideoCategoriesQuery = (props: TVideoFetchParams | void) => {
  const page = props?.page || 1;
  const pageSize = props?.pageSize;
  const { defaultLocale } = useLocales();
  const sort = props?.sort || 'name';

  return useQuery({
    queryKey: [videoCategoriesQueryKeys.videoCategories, page, pageSize, sort],
    queryFn: () => fetchVideoCategories({ page, pageSize, sort, defaultLocale }),
    cacheTime: 0,
  });
};
