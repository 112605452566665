import { zodResolver } from '@hookform/resolvers/zod';
import { useLocales } from 'entities/config/lib/useLocales';
import { UploadImage } from 'feature/right-panel/ui/UploadImage/UploadImage';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { Form, FormInput } from 'shared/components/Form';
import { Text } from 'shared/components/Text/Text';
import { imageSettings } from 'shared/constants';
import { langNameMap } from 'shared/consts';
import { TVideoCategoriesFormFields } from 'widgets/videoCategoriesForm/types';
import { addVideoCategoriesPostValidationSchema } from 'widgets/videoCategoriesForm/validationSchema';

import styles from './VideoCategoriesFormModal.module.css';

type TProps = {
  title: string;
  subtitle: string;
  closeModal: () => void;
  actionSubmit: (data: TVideoCategoriesFormFields) => void;
  isOpened: boolean;
  initialValues: TVideoCategoriesFormFields;
};

export const VideoCategoriesFormModal = ({
  title,
  subtitle,
  closeModal,
  actionSubmit,
  isOpened,
  initialValues,
}: TProps) => {
  const { locales } = useLocales();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<TVideoCategoriesFormFields>({
    values: initialValues,
    mode: 'onChange',
    resolver: zodResolver(addVideoCategoriesPostValidationSchema(locales)),
  });

  const submit = handleSubmit((data) => {
    actionSubmit(data);
    reset();
  });

  const onCloseModal = () => {
    closeModal();
    reset();
  };

  return (
    <ActionModal
      opened={isOpened}
      title={title}
      width={542}
      confirmButtonProps={{
        isDisabled: !isValid,
        onClick: submit,
      }}
      onClose={onCloseModal}
    >
      <Text size={14} tag="h3" weight="SemiBold">
        {subtitle}
      </Text>
      <Form action={submit} className={styles.form}>
        {locales?.map((locale) => {
          return (
            <FormInput
              key={locale}
              control={control}
              fieldName={`translations.${locale}.name`}
              inputClassName={styles.inputForm}
              label={langNameMap[locale]}
              maxLength={255}
              placeholder="Введите заголовок"
              required
              size="s"
            />
          );
        })}
        <Text className={styles.text} size={14} tag="h3" weight="SemiBold">
          Важность
        </Text>
        <FormInput
          control={control}
          fieldName="importance"
          inputClassName={styles.inputForm}
          placeholder="Введите номер соответствующий важности"
          size="s"
          type="number"
        />
        <Text className={styles.text} size={14} tag="h3" weight="SemiBold">
          Обложка Категории
        </Text>
        <UploadImage
          aspectRatio={imageSettings.video.importance.aspectRatio}
          control={control}
          fieldName="picture"
          label="Обложка"
          modalTitle="Обложка категории"
          required
          showIcon={false}
        />
      </Form>
    </ActionModal>
  );
};
