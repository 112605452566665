import { useFetchManagerCareersQuery } from 'entities/managerCareers/api/queries';
import { noop } from 'lodash';
import { getManagerCareersColumnsConfig } from 'pages/manager/ManagerCareersPage/ManagerCareersTable/columnsConfig';
import React from 'react';
import { useParams } from 'react-router-dom';
import { usePagination } from 'shared/hooks/usePagination';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './ManagerCareersTable.module.css';

export const ManagerCareersTable = () => {
  const { managerId = '' } = useParams<{ managerId: string }>();

  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getManagerCareersColumnsConfig,
  });

  const { selectedPage, handlePageChange } = usePagination();
  const { data, isLoading, error } = useFetchManagerCareersQuery({
    page: selectedPage,
    filter: managerId ? { 'filter[manager_id]': managerId } : {},
  });

  return (
    <div className={styles.root}>
      <CommonListWrapper
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title="Карьера"
        createButtonProps={{
          content: 'Создать карьеру',
          onClick: noop,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: data?.page ?? 1,
          totalItems: data?.total ?? 0,
          onPageChange: handlePageChange,
        }}
      >
        <CommonListTable
          activeItemId={activeItemId}
          columns={columnsConfig}
          data={data?.careers || []}
          rowClassName={styles.rowGridTemplate}
        />
      </CommonListWrapper>
    </div>
  );
};
