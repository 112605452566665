import { TStoryToolKind } from 'entities/stories/types';
import { ButtonElement } from 'pages/Stories/EditStory/components/elements/ButtonElement/ButtonElement';
import { CopyElement } from 'pages/Stories/EditStory/components/elements/CopyElement/CopyElement';
import { QuizElement } from 'pages/Stories/EditStory/components/elements/QuizElement/QuizElement';
import { TextElement } from 'pages/Stories/EditStory/components/elements/TextElement/TextElement';
import { VotingElement } from 'pages/Stories/EditStory/components/elements/VotingElement/VotingElement';
import { exhaustiveCheck } from 'shared/lib/exhaustiveCheck';

import { TElementProps } from './types';

const createElementPropsGuard =
  <K extends TStoryToolKind>(kind: K) =>
  (
    elementKind: TStoryToolKind,
    props: TElementProps<TStoryToolKind>,
  ): props is TElementProps<K> =>
    elementKind === kind;

const isPendingElement = (
  kind: TStoryToolKind,
): kind is 'feedback' | 'swipeUp' | 'countdown' => {
  return kind === 'swipeUp' || kind === 'feedback' || kind === 'countdown';
};

export const renderElement = <T extends TStoryToolKind>(
  kind: T,
  props: TElementProps<T>,
) => {
  if (createElementPropsGuard('text')(kind, props)) {
    return <TextElement {...props} />;
  } else if (createElementPropsGuard('button')(kind, props)) {
    return <ButtonElement {...props} />;
  } else if (createElementPropsGuard('copy')(kind, props)) {
    return <CopyElement {...props} />;
  } else if (createElementPropsGuard('quiz')(kind, props)) {
    return <QuizElement {...props} />;
  } else if (createElementPropsGuard('voting')(kind, props)) {
    return <VotingElement {...props} />;
  } else if (isPendingElement(kind)) {
    return null;
  }
  exhaustiveCheck(kind);
};
