import { TVideoPost } from 'entities/video/types';
import { VideoPostActions } from 'entities/video/video-actions/ui/VideoPostActions';
import { TVideoFilter } from 'feature/filters/types';
import { CategoryFilterWrapper as CategoryFilter } from 'feature/filters/ui/CategoryFilter/CategoryFilterWrapper';
import { ImportanceFilter } from 'feature/filters/ui/ImportanceFilter/ImportanceFilter';
import { DateTimeCell } from 'pages/Stories/StoriesList/components/DateTimeCell';
import { TVideoTableSortParams } from 'pages/video/VideoPage/types';
import React, { Dispatch, SetStateAction } from 'react';
import { Badge } from 'shared/components/Badge/Badge';
import { Icon } from 'shared/components/Icon/Icon';
import { Status } from 'shared/components/Status';
import { Text } from 'shared/components/Text/Text';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';
import { TEntityId, TLangType } from 'shared/types/common';
import { TConfigCreator } from 'widgets/elements-list-table/types';

import styles from './VideoTable.module.css';

export const getVideoColumnsConfig: TConfigCreator<
  TVideoPost,
  TVideoTableSortParams,
  TVideoFilter
> = (
  defaultLocale: TLangType,
  activeVideoPostId: TEntityId | null,
  setActiveVideoPostId: Dispatch<SetStateAction<TEntityId | null>>,
) => [
  {
    title: 'Публикации',
    render: (item) => (
      <div className={styles.publicationNameColumn}>
        {item.pictures.base ? (
          <div className={styles.previewWrapper}>
            <img
              alt={`${item.title} preview`}
              className={styles.preview}
              src={item.pictures.base.x1}
            />
          </div>
        ) : (
          <div className={styles.previewWrapper}>
            <Icon kind="picture" size="s" />
          </div>
        )}
        <Text className={styles.title}>{item.title}</Text>
      </div>
    ),
    headCellProps: {
      className: styles.publicationNameColumn,
    },
  },
  {
    title: 'Категория',
    render: (item) => (
      <Text className={styles.categories}>
        {item.categories?.[0]?.translations[defaultLocale]?.name ?? ''}
      </Text>
    ),
    headCellProps: {
      filterName: 'categories',
      filterable: true,
      getIsFiltered: (filter: TVideoFilter) =>
        toBoolean(filter.categories && filter.categories.length > 0),
      getFilterBadge: (filter: TVideoFilter) =>
        filter.categories?.length ? toString(filter.categories?.length) : null,
      filterComponent: CategoryFilter,
    },
  },
  {
    title: 'Дата создания',
    render: (item) => <DateTimeCell dateTimeString={item.createdAt ?? ''} />,
    headCellProps: {
      sortable: true,
      sortParam: 'created_at',
    },
  },
  {
    title: 'ID',
    render: (item) => item.id,
  },
  {
    title: 'Важность',
    render: (item) =>
      item.importance > 0 ? (
        <Badge color="green" size="s" text="Слайдер" variant="outlined" />
      ) : null,
    headCellProps: {
      filterName: 'importance',
      filterable: true,
      filterWidth: 162,
      getIsFiltered: (filter: TVideoFilter) => toBoolean(filter.importance),
      getFilterBadge: (filter: TVideoFilter) => (filter.importance ? ' ' : null),
      filterComponent: ImportanceFilter,
    },
  },
  {
    title: 'Статус',
    render: (item) => <Status status={item.status} />,
    /* TODO: расскоментировать в рамках доработки фильтра по статусу.
        Сейчас бек рассчитан только на выбор 1го фильтра, необходимо реализовать
        выбор нескольких фильтров на сервере и доработать под это клиент */
    // headCellProps: {
    //   filterName: 'status',
    //   filterable: true,
    //   getIsFiltered: (filter: TVideoFilter) =>
    //     filter.status && filter.status !== EListStatus.All,
    //   getFilterBadge: (filter: TVideoFilter) => (filter.status ? ' ' : null),
    //   filterComponent: StatusFilter,
    // },
  },
  {
    title: 'Дата публикации',
    render: (item) => <DateTimeCell dateTimeString={item?.published ?? ''} />,
    headCellProps: {
      sortable: true,
      sortParam: 'published',
    },
  },
  {
    title: '',
    cellClassName: styles.actionsColumn,
    render: (item) => (
      <VideoPostActions
        activeVideoPostId={activeVideoPostId}
        setActiveVideoPostId={setActiveVideoPostId}
        videoPost={item}
      />
    ),
  },
];
