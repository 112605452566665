import { RightPanelCollapseWrapper } from 'feature/right-panel/ui/RightPanelCollapseWrapper/RightPanelCollapseWrapper';
import { Control, FieldValues } from 'react-hook-form';
import { FormSelect } from 'shared/components/Form/Select/FormSelect';

import {
  TDefaultRightPanelProps,
  TRightPanelMatchProps,
  TSelectFieldProps,
} from '../../types';
import { MatchSelect } from '../MatchSelect/MatchSelect';

type TProps<T extends FieldValues> = {
  tournament: TSelectFieldProps<T>;
  season: TSelectFieldProps<T>;
  players: TSelectFieldProps<T>;
  managers: TSelectFieldProps<T>;
  match: TRightPanelMatchProps;
  description: string;
  control: Control<T>;
};

export const CommonConnections = <T extends FieldValues>({
  control,
  tournament,
  season,
  description,
  players,
  managers,
  match,
  ...restProps
}: TDefaultRightPanelProps & TProps<T>) => {
  return (
    <RightPanelCollapseWrapper
      description={description}
      label="Связи"
      title="Выберите связи"
      {...restProps}
    >
      <FormSelect
        control={control}
        fieldName={tournament.fieldName}
        label="Турнир"
        multiple={false}
        options={tournament.data}
        placeholder={tournament.placeholder}
        search
      />
      <FormSelect
        control={control}
        disabled={season.disabled}
        fieldName={season.fieldName}
        label="Сезон"
        multiple={false}
        options={season.data}
        placeholder={season.placeholder}
        search
      />
      <MatchSelect {...match} />
      <FormSelect
        control={control}
        fieldName={players.fieldName}
        label="Игрок"
        multiple={true}
        options={players.data}
        placeholder={players.placeholder}
        search
      />
      <FormSelect
        control={control}
        fieldName={managers.fieldName}
        label="Тренерский состав"
        multiple={true}
        options={managers.data}
        placeholder={managers.placeholder}
        search
      />
    </RightPanelCollapseWrapper>
  );
};
