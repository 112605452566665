import cn from 'classnames';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { Field } from 'shared/components/Form';
import { Icon } from 'shared/components/Icon/Icon';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { ImageUploadModal } from 'shared/components/ImageUploadModal/ImageUploadModal';
import { Text } from 'shared/components/Text/Text';
import { TWorkspaceProps } from 'shared/components/Workspace/Workspace';
import { imageSettings } from 'shared/constants';
import { formatSizeUnits } from 'shared/lib/formatSizeUnits';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';

import styles from './WorkspacePreview.module.css';

export const WorkspacePreview = ({ control, currentLocale }: TWorkspaceProps) => {
  const [isOpenImageLoadModal, setIsOpenImageLoadModal] = useState(false);
  const { field: previewFile, fieldState } = useController({
    control,
    name: `content.preview.translations.${currentLocale}.file`,
  });
  const { field: previewData } = useController({
    control,
    name: `content.preview.translations.${currentLocale}.data`,
  });

  const openLoadImageModal = () => {
    setIsOpenImageLoadModal(true);
  };

  const closeLoadImageModal = () => {
    setIsOpenImageLoadModal(false);
  };

  const loadFile = (files: File[]) => {
    const file = files[0];
    if (!file) {
      return;
    }
    previewFile.onChange(file);
    getBase64FromFile(file).then((data) => {
      previewData.onChange(data);
    });
    closeLoadImageModal();
  };

  const deleteImage = () => {
    previewFile.onChange(null);
    previewData.onChange(null);
  };

  const changeImageHandler = () => {
    setIsOpenImageLoadModal(true);
  };

  return (
    <>
      <Field
        className={styles.preview}
        error={fieldState.error}
        label="Обложка новости"
        required
        showErrors
        tip="Основное изображение на экране новости"
      >
        {previewFile.value ? (
          <div className={cn(styles.zone, styles.previewImage)}>
            <div className={styles.imageWrapper}>
              <img
                alt="news post preview"
                className={styles.image}
                src={previewData.value}
              />
            </div>
            <div className={styles.infoWrapper}>
              <Text className={styles.filename} color="var(--gray-600)" size={16}>
                {previewFile.value.name}
              </Text>
              <Text color="var(--gray-400)" size={14}>
                {formatSizeUnits(previewFile.value.size)}
              </Text>
            </div>
            <div className={styles.buttonWrapper}>
              <IconButton
                className={styles.button}
                icon="edit-02"
                iconSize="s"
                onClick={changeImageHandler}
              />
              <IconButton
                className={styles.button}
                icon="trash"
                iconSize="s"
                onClick={deleteImage}
              />
            </div>
          </div>
        ) : (
          <div
            className={cn(styles.zone, styles.loadImage, {
              [styles.zoneError]: fieldState.error,
            })}
            onClick={openLoadImageModal}
          >
            <Icon className={styles.icon} kind="file-download-03" />
            <Text color="var(--gray-400)" size={14}>
              Загрузить изображение
            </Text>
          </div>
        )}
      </Field>
      <ImageUploadModal
        aspectRatio={imageSettings.news.cover.aspectRatio}
        opened={isOpenImageLoadModal}
        title="Загрузить изображение"
        withCropping
        onClose={closeLoadImageModal}
        onConfirm={loadFile}
      />
    </>
  );
};
