import z from 'zod';

export const addSocialNetworkValidationSchema = z.object({
  url: z
    .string()
    .url({ message: 'Некорректная ссылка' })
    .startsWith('https://', { message: 'Должен быть безопасный URL' })
    .max(4096),
  socialNetworkId: z.string().min(1, 'Выберите соц. сеть'),
});
