import { noop } from 'lodash';
import { useDeleteManagerSocialNetwork } from 'pages/manager/ManagerSocialNetworksPage/hooks/useDeleteManagerSocialNetwork';
import { TUseManagerSocialNetworksActions } from 'pages/manager/ManagerSocialNetworksPage/types';
import { useMemo } from 'react';
import { useBoolean } from 'react-use';
import { TGroupAction } from 'shared/types/common';

export const useManagerSocialNetworksActions = ({
  managerSocialNetwork,
  activeSocialLinkId,
  setActiveSocialLinkId,
}: TUseManagerSocialNetworksActions) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const { id } = managerSocialNetwork;

  const isActive = id === activeSocialLinkId;

  const { isDeletingManagerSocialNetwork, deleteManagerSocialNetwork } =
    useDeleteManagerSocialNetwork();

  const actions: TGroupAction[] = useMemo(
    () => [
      {
        title: 'Редактировать',
        icon: 'edit-02',
        action: noop,
      },
      {
        title: 'Удалить',
        icon: 'trash',
        action: () => setIsDeleteModalOpen(true),
      },
    ],
    [],
  );

  const handleActionsButtonClick = () => {
    setActiveSocialLinkId(isActive ? null : id);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setActiveSocialLinkId(null);
  };

  const handleConfirmDeletion = () => {
    handleCloseDeleteModal();
    deleteManagerSocialNetwork(id);
  };

  return {
    actionsProps: {
      actions,
      activeItemId: activeSocialLinkId,
      isLoading: isDeletingManagerSocialNetwork,
      isOpen: isActive,
      onIconClick: handleActionsButtonClick,
    },
    deleteModalProps: {
      onClose: handleCloseDeleteModal,
      onConfirm: handleConfirmDeletion,
      isOpened: isDeleteModalOpen,
      description: 'После удаления, содержимое \n будет утеряно навсегда',
      title: 'Удаление социальной сети',
    },
  };
};
