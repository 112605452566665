import { useCreateStoryElementMutation } from 'entities/stories/mutations';
import { TStoryElementData, TStoryToolKind } from 'entities/stories/types';
import { max } from 'lodash';
import { fontSizeOptions } from 'pages/Stories/EditStory/components/forms/constants';
import {
  defaultElementWidthByKind,
  defaultSettingsByKind,
} from 'pages/Stories/EditStory/constants';
import { useStoryEditorContext } from 'pages/Stories/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/Stories/EditStory/hooks/useStoryEditorDispatchContext';
import { useStoryElements } from 'pages/Stories/hooks/useStoryElements';
import { useCallback } from 'react';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TLangType } from 'shared/types/common';

export const useCreateElement = (
  createdLocales: TLangType[],
  selectedLocale: TLangType,
) => {
  const { mutateAsync: createMutation, isLoading } = useCreateStoryElementMutation();
  const { selectedStory } = useStoryEditorContext();
  const storyElements = useStoryElements();
  const dispatch = useStoryEditorDispatchContext();

  const maxLayer = max(storyElements.map((element) => element.layer));

  const createElement = useCallback(
    (kind: TStoryToolKind) => {
      if (selectedStory) {
        const elementObj = {
          kind,
          layer: maxLayer ? maxLayer + 1 : 0,
          translations: reduceTranslationObject(createdLocales, () => ({
            editorPosition: { x: 200, y: 200 },
            width: defaultElementWidthByKind[kind],
            height: fontSizeOptions[0].value as number,
            settings: defaultSettingsByKind[kind],
          })),
          story: selectedStory.id,
        } as TStoryElementData;

        createMutation(elementObj).then(() => {
          dispatch({ type: 'selectStoryElement', payload: elementObj });
        });
      }
    },
    [selectedStory, maxLayer, selectedLocale],
  );

  return {
    storyElements,
    createElement,
    isLoading,
  } as const;
};
