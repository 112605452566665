import { PlayerCard } from 'entities/players/ui/PlayerCard/PlayerCard';
import { DEFAULT_PAGINATION_TEAM_SIZE } from 'pages/team/constants';
import { usePlayers } from 'pages/team/hooks/usePlayers';
import styles from 'pages/team/TeamPage/TeamPage.module.css';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CardButton } from 'shared/components/CardButton/CardButton';
import { Pagination } from 'shared/components/Pagination/Pagination';
import { usePagination } from 'shared/hooks/usePagination';
import { routes } from 'shared/routes';

export const PlayerTab = () => {
  const navigate = useNavigate();
  const { selectedPage, handlePageChange } = usePagination();

  const { teamId } = useParams<{
    teamId: string;
  }>();

  const { playersList, isShowPagination } = usePlayers(selectedPage, teamId);

  return (
    <>
      <div className={styles.listWrapper}>
        <CardButton
          title="Создать игрока"
          onClick={() => teamId && navigate(routes.createPlayer.as(teamId))}
        />
        {playersList?.players.map((item) => {
          return <PlayerCard key={item.id} player={item} />;
        })}
      </div>
      {isShowPagination && (
        <Pagination
          className={styles.pagination}
          pageSize={DEFAULT_PAGINATION_TEAM_SIZE}
          selectedPage={selectedPage}
          totalItems={playersList?.total ?? 0}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};
