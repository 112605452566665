import { zodResolver } from '@hookform/resolvers/zod';
import { useLocales } from 'entities/config/lib/useLocales';
import { playerCareerModalValidationSchema } from 'feature/player-career-modal/validationSchema';
import { UploadImage } from 'feature/right-panel/ui/UploadImage/UploadImage';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { FormInput } from 'shared/components/Form';
import { Text } from 'shared/components/Text/Text';
import { imageSettings } from 'shared/constants';
import { langNameMap } from 'shared/consts';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TTranslations } from 'shared/types/common';

import styles from './PlayerCareerModal.module.css';

export type TPlayerCareerModalFields = {
  season: string;
  championshipCount: string;
  cupsCount: string;
  eurocupsCount: string;
  gamesCount: string;
  teamPicture?: File;
  translations: TTranslations<{
    teamName: string;
  }>;
};

type TProps = {
  title: string;
  closeModal: () => void;
  actionSubmit: (data: TPlayerCareerModalFields) => void;
  isOpened: boolean;
  initialValues?: TPlayerCareerModalFields;
  isLoading?: boolean;
};

export function PlayerCareerModal({
  closeModal,
  actionSubmit,
  initialValues,
  isOpened,
  title,
  isLoading,
}: TProps) {
  const { locales } = useLocales();

  const defaultValues = {
    season: '',
    championshipCount: '',
    cupsCount: '',
    eurocupsCount: '',
    gamesCount: '',
    teamPicture: undefined,
    translations: reduceTranslationObject(locales, () => ({ teamName: '' })),
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<TPlayerCareerModalFields>({
    values: initialValues ?? defaultValues,
    mode: 'onChange',
    resolver: zodResolver(playerCareerModalValidationSchema(locales)),
  });

  const submit = handleSubmit((data) => {
    actionSubmit(data);
  });

  useEffect(() => {
    if (!isOpened) {
      reset();
    }
  }, [isOpened]);

  return (
    <ActionModal
      contentClassName={styles.modal}
      opened={isOpened}
      title={title}
      width={542}
      confirmButtonProps={{
        isDisabled: !isValid,
        onClick: submit,
        isLoading,
      }}
      onClose={closeModal}
    >
      <Text size={14} tag="h3" weight="SemiBold">
        Название клуба
      </Text>
      <div className={styles.form}>
        {locales?.map((locale) => {
          return (
            <FormInput
              key={locale}
              control={control}
              fieldName={`translations.${locale}.teamName`}
              inputClassName={styles.inputForm}
              label={langNameMap[locale]}
              maxLength={255}
              placeholder="Введите заголовок"
              required
              size="s"
            />
          );
        })}
      </div>
      <div className={styles.form}>
        <Text size={14} tag="h3" weight="SemiBold">
          Данные игр
        </Text>
        <FormInput
          control={control}
          fieldName="season"
          inputClassName={styles.inputForm}
          label="Сезон"
          maxLength={9}
          placeholder="Введите период сезона"
          required
          size="s"
        />
        <FormInput
          control={control}
          fieldName="championshipCount"
          inputClassName={styles.inputForm}
          label="Чемпионат"
          placeholder="Введите количество игр"
          required
          size="s"
          type="number"
        />
        <FormInput
          control={control}
          fieldName="cupsCount"
          inputClassName={styles.inputForm}
          label="Кубок"
          placeholder="Введите количество игр"
          required
          size="s"
          type="number"
        />
        <FormInput
          control={control}
          fieldName="eurocupsCount"
          inputClassName={styles.inputForm}
          label="Еврокубок"
          placeholder="Введите количество игр"
          required
          size="s"
          type="number"
        />
        <FormInput
          control={control}
          fieldName="gamesCount"
          inputClassName={styles.inputForm}
          label="Сыгранных игр за сезон"
          placeholder="Введите количество игр"
          required
          size="s"
          type="number"
        />
        <UploadImage
          aspectRatio={imageSettings.playerCareer.teamPicture.aspectRatio}
          control={control}
          fieldName="teamPicture"
          hint={imageSettings.playerCareer.teamPicture.hint}
          label="Логотип клуба"
          modalTitle="Настройка изображения"
          required
          showIcon={false}
        />
      </div>
    </ActionModal>
  );
}
