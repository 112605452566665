import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchTeam, fetchTeams, TFetchTeamParams } from 'entities/teams/api';
import { teamsQueryKeys } from 'entities/teams/consts';
import { TTeamsResponse } from 'entities/teams/mapper/mapTeamsResponse';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

export const useFetchTeamsQuery = (
  { paginationParams }: TFetchTeamParams,
  options: UseQueryOptions<TTeamsResponse> = {},
) =>
  useQuery({
    queryKey: [teamsQueryKeys.teams, paginationParams],
    queryFn: () => fetchTeams(paginationParams),
    ...options,
  });

export const useFetchTeamQuery = (id: TEntityId = '') =>
  useQuery({
    queryKey: [teamsQueryKeys.team(id)],
    queryFn: () => fetchTeam(id),
    enabled: toBoolean(id),
  });
