import styles from 'pages/Stories/EditStory/components/forms/ElementForm.module.css';
import { useStoryEditorDispatchContext } from 'pages/Stories/EditStory/hooks/useStoryEditorDispatchContext';
import { MouseEventHandler } from 'react';
import { Button } from 'shared/components/Button/Button';

type TProps = {
  disabled?: boolean;
};

export const BackButton = ({ disabled }: TProps) => {
  const dispatch = useStoryEditorDispatchContext();
  const handleCancel: MouseEventHandler = () => {
    dispatch({ type: 'selectStoryElement', payload: null });
  };

  return (
    <div className={styles.backButton}>
      <Button isDisabled={disabled} kind="outlined" size="l" onClick={handleCancel}>
        Назад
      </Button>
    </div>
  );
};
