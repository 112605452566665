import { TNewsCategory } from 'entities/newsCategories/types';
import { NewsCategoriesActionWrapper } from 'pages/settings/categories/NewsCategoriesPage/NewsCategoriesActionWrapper';
import React from 'react';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elements-list-table/types';

import styles from './NewsCategoriesPage.module.css';

export const getNewsCategoriesColumnsConfig: TConfigCreator<TNewsCategory> = (
  defaultLocale,
  activeItemId,
  setActiveItemId,
) => [
  {
    title: 'Категория',
    cellClassName: styles.publicationNameColumn,
    render: (item) => (
      <Text className={styles.text}>{item?.translations[defaultLocale]?.name}</Text>
    ),
  },
  {
    title: '',
    render: (item) => (
      <NewsCategoriesActionWrapper
        activeItemId={activeItemId}
        newsCategory={item}
        setActiveItemId={setActiveItemId}
      />
    ),
  },
];
