import { FileRejection } from 'react-dropzone';

export const getUploadImageModalErrorMessage = (
  fileRejections: FileRejection[],
  maxFiles: number,
): string => {
  if (fileRejections.length === 0) {
    return '';
  }

  if (fileRejections[0].errors[0].code === 'too-many-files') {
    return `Максимальное количество файлов ${maxFiles}.`;
  }

  if (fileRejections[0].errors[0].code === 'file-too-large') {
    return 'Максимальный размер файла 7MB.';
  }

  if (fileRejections[0].errors[0].code === 'file-invalid-type') {
    return (
      'Этот формат файлов не поддерживается. Вы можете загрузить любое изображение \n' +
      'в формате JPEG, JPG, PNG или WebP.'
    );
  }

  return '';
};
