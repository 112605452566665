// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SwitcherTeams-module__switchWrapper--AHc8j {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  border-radius: var(--border-radius-default);\n  border: 1px solid var(--gray-250);\n  padding: 0 1px;\n  height: 34px;\n  width: -moz-max-content;\n  width: max-content;\n}\n\n.SwitcherTeams-module__control--JnbeX {\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 6px 16px;\n  border-radius: 6px;\n}\n\n.SwitcherTeams-module__isSelected--xcVfJ {\n  color: var(--green-400);\n  border: 1px solid var(--green-400);\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/team/switcher/SwitcherTeams.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,2CAA2C;EAC3C,iCAAiC;EACjC,cAAc;EACd,YAAY;EACZ,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,kCAAkC;AACpC","sourcesContent":[".switchWrapper {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  border-radius: var(--border-radius-default);\n  border: 1px solid var(--gray-250);\n  padding: 0 1px;\n  height: 34px;\n  width: max-content;\n}\n\n.control {\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 6px 16px;\n  border-radius: 6px;\n}\n\n.isSelected {\n  color: var(--green-400);\n  border: 1px solid var(--green-400);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switchWrapper": "SwitcherTeams-module__switchWrapper--AHc8j",
	"control": "SwitcherTeams-module__control--JnbeX",
	"isSelected": "SwitcherTeams-module__isSelected--xcVfJ"
};
export default ___CSS_LOADER_EXPORT___;
