import { TConfigData } from 'entities/config/model/types';
import { TEXT_MIN_LENGTH_MESSAGE } from 'shared/consts';
import { TLangType } from 'shared/types/common';
import { isAllowedVideoHosting, isSecuredLink } from 'shared/utils/links';
import { fileZodValidation } from 'shared/zod/fileZodValidation';
import z from 'zod';

export const videoFormValidationSchema = (config?: TConfigData) =>
  z
    .object({
      videoLink: z
        .string()
        .min(1, TEXT_MIN_LENGTH_MESSAGE)
        .refine(
          (videoLink) =>
            isAllowedVideoHosting(videoLink, config?.whitelistVideoHostings),
          'Этот домен отсутствует в списке допустимых. Для его добавления обратитесь к администратору.',
        )
        .refine(
          (videoLink) => isSecuredLink(videoLink),
          'Ссылка должна начинаться с  https://',
        ),
      locale: z.object({
        created: z.array(z.string()),
        selected: z.string(),
      }),
      preview: z.object({
        file: fileZodValidation('Загрузите превью-изображение'),
        data: z.string(),
      }),
      slider: z.object({
        file: z.optional(fileZodValidation('Загрузите обложку слайдера')),
        data: z.optional(z.string()),
      }),
      important: z.boolean(),
    })
    .passthrough()
    .superRefine((fields, context) => {
      const validatedContent = translationsValidation(
        fields.locale.selected as TLangType,
      ).safeParse(fields);

      if (!validatedContent.success) {
        validatedContent.error.issues.forEach((issue) => {
          context.addIssue(issue);
        });
      }
    });

const translationsValidation = (selected: TLangType) =>
  z.object({
    translations: z.object({
      [selected]: z.object({
        title: z.string().min(1, TEXT_MIN_LENGTH_MESSAGE),
      }),
    }),
  });
