import { useQuery } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { photoGalleryCategoriesQueryKeys } from 'entities/photoGalleryCategories/consts';

import { fetchPhotoGalleryCategories } from './api';

export const useFetchPhotoGalleryCategoriesQuery = (page = 1) => {
  const { defaultLocale } = useLocales();

  return useQuery({
    queryKey: [photoGalleryCategoriesQueryKeys.photoGalleryCategories, page],
    queryFn: () => fetchPhotoGalleryCategories(page, defaultLocale),
    cacheTime: 0,
  });
};
