import cn from 'classnames';
import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Button } from 'shared/components/Button/Button';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { AddNewsPostPublishModal } from 'shared/components/NewsPostForm/components/AddNewsPostPublishButton/AddNewsPostPublishModal';
import { useCreateNewsPost } from 'shared/components/NewsPostForm/hooks/useCreateNewsPost';
import { useEditNewsPost } from 'shared/components/NewsPostForm/hooks/useEditNewsPost';
import { useIsEditNewsPost } from 'shared/components/NewsPostForm/hooks/useIsEditNewsPost';
import { useIsPublishNewsPost } from 'shared/components/NewsPostForm/hooks/useIsPublishNewsPost';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';

import styles from './AddNewsPostPublishButton.module.css';

export const AddNewsPostPublishButton = () => {
  const { trigger, control, handleSubmit, formState } =
    useFormContext<TEditNewsPostFields>();

  const createNewsPost = useCreateNewsPost();
  const editNewsPost = useEditNewsPost();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenChooseDateModal, setIsOpenChooseDateModal] = useState(false);
  const isEdit = useIsEditNewsPost();
  const isPublish = useIsPublishNewsPost();
  const {
    field: { onChange: onPublishDateChange, value: publishDate },
  } = useController({
    control,
    name: 'publishDate',
  });

  const onClickPublish = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    if (isEdit && isPublish) {
      await handleSubmit(editNewsPost)();
      return;
    }

    setIsOpenModal(true);
  };

  const onDateChange = (date: Date | undefined) => {
    onPublishDateChange(date);
    setIsOpenModal(true);
    setIsOpenChooseDateModal(false);
  };

  const onPublish = () => {
    if (!publishDate) {
      onPublishDateChange(new Date());
    }

    if (isEdit) {
      handleSubmit(editNewsPost)();
    } else {
      handleSubmit(createNewsPost)();
    }
  };

  const onClickChooseDate = () => {
    setIsOpenModal(false);
    setIsOpenChooseDateModal(true);
  };

  return (
    <>
      <Button
        isDisabled={formState.isSubmitting}
        className={cn({
          [styles.disabled]: !formState.isValid,
        })}
        onClick={onClickPublish}
      >
        Подтвердить
      </Button>

      <AddNewsPostPublishModal
        isOpened={isOpenModal}
        onClickChooseDate={onClickChooseDate}
        onClose={() => setIsOpenModal(false)}
        onPublish={onPublish}
      />

      <DatePicker
        date={publishDate}
        isOpened={isOpenChooseDateModal}
        onDateChange={onDateChange}
        onClose={() => {
          setIsOpenChooseDateModal(false);
          setIsOpenModal(true);
        }}
      />
    </>
  );
};
