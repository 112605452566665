// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlayerCareersPage-module__text--BP1NM {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.PlayerCareersPage-module__previewWrapper--QQOVu {\n  width: 32px;\n  height: 32px;\n  background: var(--gray-150);\n  border-radius: var(--border-radius-max);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: var(--gray-250);\n  margin-right: 20px;\n  flex-shrink: 0;\n  overflow: hidden;\n}\n\n.PlayerCareersPage-module__createButton--QMYJR {\n  padding: 7px 11px;\n}\n\n.PlayerCareersPage-module__season--oWaK7 {\n  max-width: 70px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/player/PlayerCareersPage/PlayerCareersPage.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,2BAA2B;EAC3B,uCAAuC;EACvC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".text {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.previewWrapper {\n  width: 32px;\n  height: 32px;\n  background: var(--gray-150);\n  border-radius: var(--border-radius-max);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: var(--gray-250);\n  margin-right: 20px;\n  flex-shrink: 0;\n  overflow: hidden;\n}\n\n.createButton {\n  padding: 7px 11px;\n}\n\n.season {\n  max-width: 70px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "PlayerCareersPage-module__text--BP1NM",
	"previewWrapper": "PlayerCareersPage-module__previewWrapper--QQOVu",
	"createButton": "PlayerCareersPage-module__createButton--QMYJR",
	"season": "PlayerCareersPage-module__season--oWaK7"
};
export default ___CSS_LOADER_EXPORT___;
