import { TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE } from 'shared/consts';
import { declination } from 'shared/lib/declination';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TLangType } from 'shared/types/common';
import z from 'zod';

const MAX_PLAYER_FIRST_NAME_LENGTH = 255;
const maxLengthTextGenerate = (length: number) =>
  `Максимальная длина ${length} ${declination(length, ['символ', 'символа', 'символов'])}`;

export const teamModalValidationSchema = (locales: TLangType[]) =>
  z
    .object({
      name: z.object({
        ...reduceTranslationObject(locales, () =>
          z
            .string()
            .min(TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE)
            .max(
              MAX_PLAYER_FIRST_NAME_LENGTH,
              maxLengthTextGenerate(MAX_PLAYER_FIRST_NAME_LENGTH),
            ),
        ),
      }),
      clubName: z.object({
        ...reduceTranslationObject(locales, () =>
          z
            .string()
            .min(TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE)
            .max(
              MAX_PLAYER_FIRST_NAME_LENGTH,
              maxLengthTextGenerate(MAX_PLAYER_FIRST_NAME_LENGTH),
            ),
        ),
      }),
      defaultTeam: z.boolean(),
      countryId: z.string().min(TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE),
    })
    .passthrough();
