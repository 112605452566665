import { useQuery } from '@tanstack/react-query';
import {
  fetchPlayerTeamAchievements,
  TFetchPlayerTeamAchievementsParams,
} from 'entities/playerTeamAchievements/api';
import { playerTeamAchievementsQueryKeys } from 'entities/playerTeamAchievements/consts';

export const useFetchPlayerTeamAchievementsQuery = ({
  page = 1,
  filter,
}: Partial<TFetchPlayerTeamAchievementsParams>) => {
  return useQuery({
    queryKey: [playerTeamAchievementsQueryKeys.playerTeamAchievements, page, filter],
    queryFn: () => fetchPlayerTeamAchievements({ page, filter }),
    cacheTime: 0,
  });
};
