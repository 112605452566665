import { TPlayerAchievement } from 'entities/playerAchievements/types';
import { TMetaType } from 'shared/types/common';

type TMapPlayerAchievementsResponseArgs = {
  data: TPlayerAchievement[];
  meta?: TMetaType<true>;
};

export const mapPlayerAchievementsResponse = ({
  data: playerAchievements,
  meta,
}: TMapPlayerAchievementsResponseArgs) => {
  const page = meta!.page;

  return {
    total: page.total_entries,
    page: page.number,
    playerAchievements,
  };
};
