import { useManagerCareersActions } from 'pages/manager/ManagerCareersPage/hooks/useManagerCareersActions';
import { TManagerCareersActions } from 'pages/manager/ManagerCareersPage/types';
import React from 'react';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';

import styles from './ManagerCareersActions.module.css';

export const ManagerCareersActions = (props: TManagerCareersActions) => {
  const {
    actions,
    isActive,
    handleActionsButtonClick,
    isDeletingManagerCareer,
    isDeleteModalOpen,
    handleConfirmDeleteCareer,
    handleCloseDeleteModal,
  } = useManagerCareersActions(props);

  return (
    <>
      <Actions
        actions={actions}
        activeItemId={props.activeCareerId}
        className={styles.dropdown}
        isLoading={isDeletingManagerCareer}
        isOpen={isActive}
        onIconClick={handleActionsButtonClick}
      />
      <ConfirmationModal
        description={'После удаления, содержимое \n будет утеряно навсегда'}
        isOpened={isDeleteModalOpen}
        title="Удаление карьеры"
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteCareer}
      />
    </>
  );
};
