import { useFetchTeamsQuery } from 'entities/teams/queries';
import { TeamCard } from 'entities/teams/ui/TeamCard/TeamCard';
import { TeamsPageCreateButton } from 'pages/team/TeamsPage/TeamsPageCreateButton';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createPaginationParams } from 'shared/api/utils';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { Pagination } from 'shared/components/Pagination/Pagination';
import { Text } from 'shared/components/Text/Text';
import { usePagination } from 'shared/hooks/usePagination';
import { routes } from 'shared/routes';

import styles from './TeamsPage.module.css';

const DEFAULT_PAGINATION_PAGE_SIZE = 7;

export const TeamsPage = () => {
  const navigate = useNavigate();
  const { selectedPage, handlePageChange } = usePagination();

  const { data, error, isLoading } = useFetchTeamsQuery(
    {
      paginationParams: createPaginationParams(
        selectedPage,
        DEFAULT_PAGINATION_PAGE_SIZE,
      ),
    },
    { cacheTime: 0 },
  );

  if (isLoading) {
    return (
      <div className={styles.root}>
        <Centered>
          <Loader showLabel />
        </Centered>
      </div>
    );
  }

  if (error) {
    throw error;
  }

  const isShowPagination =
    (data?.total ?? 0) > 0 &&
    (data?.total ?? 0) > DEFAULT_PAGINATION_PAGE_SIZE &&
    !isLoading;

  return (
    <div className={styles.root}>
      <Text size={24} tag="h1" weight="Bold">
        Команды
      </Text>
      <div className={styles.listWrapper}>
        <TeamsPageCreateButton />
        {data?.teams.map((item) => {
          return (
            <TeamCard
              key={item.id}
              team={item}
              onClick={() => navigate(routes.editTeam.as(item.id))}
            />
          );
        })}
      </div>
      {isShowPagination && (
        <Pagination
          className={styles.pagination}
          pageSize={DEFAULT_PAGINATION_PAGE_SIZE}
          selectedPage={selectedPage}
          totalItems={data?.total ?? 0}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};
