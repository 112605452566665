import { useFetchManagerTeamAchievementsQuery } from 'entities/managerTeamAchievements/queries';
import { useNavigate, useParams } from 'react-router-dom';
import { usePagination } from 'shared/hooks/usePagination';
import { routes } from 'shared/routes';

export const useManagerTeamAchievements = () => {
  const navigate = useNavigate();
  const { teamId = '', managerId = '' } = useParams<{
    teamId: string;
    managerId: string;
  }>();

  const { selectedPage, handlePageChange } = usePagination();
  const {
    data: managerTeamAchievementsData,
    isLoading: isManagerTeamAchievementsLoading,
    error: managerTeamAchievementsError,
  } = useFetchManagerTeamAchievementsQuery({
    page: selectedPage,
    filter: managerId ? { 'filter[manager_id][eq]': managerId } : {},
  });

  const handleConfirmButtonClick = () => {
    navigate(routes.editTeam.as(teamId), {
      replace: true,
    });
  };

  return {
    ...managerTeamAchievementsData,
    isLoading: isManagerTeamAchievementsLoading,
    error: managerTeamAchievementsError,
    handleConfirmButtonClick,
    handlePageChange,
  };
};
