// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VideoWorkspace-module__content--aHMp_ {\n  display: flex;\n  max-width: 100%;\n  width: 700px;\n  flex-direction: column;\n  padding: 16px;\n  gap: 16px;\n  border: var(--default-border);\n  border-radius: var(--border-radius-l);\n  background: var(--bg-white);\n}\n\n.VideoWorkspace-module__icon--Mog3q {\n  width: 72px;\n  height: 72px;\n}\n\n.VideoWorkspace-module__videoWrapper--uyW05,\n.VideoWorkspace-module__emptyVideo--QldEz {\n  width: 668px;\n  height: 344px;\n}\n\n.VideoWorkspace-module__hintText--k3exG {\n  color: var(--dark-blue-250);\n}\n\n.VideoWorkspace-module__emptyVideo--QldEz {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: var(--border-radius-l);\n  border: 1px solid var(--gray-250);\n  color: var(--gray-200);\n  background: var(--gray-100);\n}\n", "",{"version":3,"sources":["webpack://./src/4-widgets/video-form/ui/VideoWorkspace/VideoWorkspace.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,SAAS;EACT,6BAA6B;EAC7B,qCAAqC;EACrC,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;;EAEE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qCAAqC;EACrC,iCAAiC;EACjC,sBAAsB;EACtB,2BAA2B;AAC7B","sourcesContent":[".content {\n  display: flex;\n  max-width: 100%;\n  width: 700px;\n  flex-direction: column;\n  padding: 16px;\n  gap: 16px;\n  border: var(--default-border);\n  border-radius: var(--border-radius-l);\n  background: var(--bg-white);\n}\n\n.icon {\n  width: 72px;\n  height: 72px;\n}\n\n.videoWrapper,\n.emptyVideo {\n  width: 668px;\n  height: 344px;\n}\n\n.hintText {\n  color: var(--dark-blue-250);\n}\n\n.emptyVideo {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: var(--border-radius-l);\n  border: 1px solid var(--gray-250);\n  color: var(--gray-200);\n  background: var(--gray-100);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "VideoWorkspace-module__content--aHMp_",
	"icon": "VideoWorkspace-module__icon--Mog3q",
	"videoWrapper": "VideoWorkspace-module__videoWrapper--uyW05",
	"emptyVideo": "VideoWorkspace-module__emptyVideo--QldEz",
	"hintText": "VideoWorkspace-module__hintText--k3exG"
};
export default ___CSS_LOADER_EXPORT___;
