import { getTextMaxLengthMessage } from 'pages/Stories/EditStory/components/forms/utils';
import { TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE, URL_REGEXP } from 'shared/consts';
import z from 'zod';

import { BUTTON_LINK_MAX_LENGTH, BUTTON_TITLE_MAX_LENGTH } from './constants';

export const buttonElementValidationSchema = z.object({
  title: z
    .string()
    .min(TEXT_MIN_LENGTH, { message: TEXT_MIN_LENGTH_MESSAGE })
    .max(
      BUTTON_TITLE_MAX_LENGTH,
      getTextMaxLengthMessage(BUTTON_TITLE_MAX_LENGTH, 'title'),
    ),
  link: z
    .string()
    .min(TEXT_MIN_LENGTH, { message: TEXT_MIN_LENGTH_MESSAGE })
    .url('Должен быть правильный URL')
    .startsWith('https://', { message: 'Должен быть безопасный URL' })
    .regex(URL_REGEXP, { message: 'Должен быть правильный URL' })
    .max(
      BUTTON_LINK_MAX_LENGTH,
      getTextMaxLengthMessage(BUTTON_LINK_MAX_LENGTH, 'link'),
    ),
  fontStyle: z.string(),
  fill: z.string(),
  fontFamily: z.string(),
  size: z.string(),
  background: z.string(),
});
