import { playersApiKeys } from 'entities/players/api/consts';
import { mapFetchPlayersResponse } from 'entities/players/api/mapper/mapFetchPlayersResponse';
import {
  TCreatePlayerDTO,
  TEditPlayerDTO,
  TPlayer,
} from 'entities/players/model/types';
import { del, get, patch, post } from 'shared/api/axios';
import { TCreatePaginationParamsReturnType } from 'shared/api/utils';
import { TEntityId } from 'shared/types/common';

export type TFetchPlayersArgs = {
  paginationParams: TCreatePaginationParamsReturnType;
  filter?: Record<string, string>;
};

export const fetchPlayers = ({ paginationParams, filter = {} }: TFetchPlayersArgs) =>
  get<TPlayer[], true>(playersApiKeys.getPlayers, {
    params: { ...paginationParams, ...filter },
  }).then((response) => mapFetchPlayersResponse(response.data));

export const fetchPlayer = (id: TEntityId) =>
  get<TPlayer>(playersApiKeys.getPlayer(id)).then((response) => response.data.data);

export const createPlayer = (createPlayerDTO: TCreatePlayerDTO) =>
  post<TPlayer>(playersApiKeys.createPlayer, createPlayerDTO).then(
    (response) => response.data.data,
  );

export const editPlayer = ({ playerId, ...editPlayerDTO }: TEditPlayerDTO) =>
  patch<TPlayer>(playersApiKeys.editPlayer(playerId), editPlayerDTO).then(
    (response) => response.data.data,
  );

export const deletePlayer = (playerId: TEntityId) =>
  del(playersApiKeys.deletePlayer(playerId));
