import cn from 'classnames';
import { forwardRef, MouseEventHandler } from 'react';
import { Loader } from 'shared/components/Loader/Loader';
import type { TLoaderSize } from 'shared/components/Loader/types';
import { preventDefaultEvent } from 'shared/lib/preventDefaultEvent';
import { TWithChildren } from 'shared/types/common';

import styles from './BaseButton.module.css';

export type TBaseButtonProps = {
  className?: string;
  htmlType?: 'submit' | 'reset' | 'button';
  loaderSize?: TLoaderSize;
  isDisabled?: boolean;
  isLoading?: boolean;
  isExternal?: boolean;
  isSingleLineTitle?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & TWithChildren;

export const BaseButton = forwardRef<HTMLButtonElement, TBaseButtonProps>(
  (
    {
      className,
      htmlType = 'button',
      children,
      onClick,
      loaderSize = 'xs',
      isDisabled = false,
      isLoading = false,
      isSingleLineTitle = true,
    },
    ref,
  ) => {
    return (
      <button
        disabled={isDisabled || isLoading}
        ref={ref}
        type={htmlType}
        className={cn(
          styles.container,
          {
            [styles.isLoading]: isLoading,
            [styles.singleLineTitle]: isSingleLineTitle,
          },
          className,
        )}
        onClick={onClick}
        onMouseDown={preventDefaultEvent}
      >
        {children}
        {isLoading && <Loader size={loaderSize} />}
      </button>
    );
  },
);

BaseButton.displayName = 'BaseButton';
