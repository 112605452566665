import { useFetchPlayerCareersQuery } from 'entities/playerCareers/queries';
import { PlayerCareerModal } from 'feature/player-career-modal/PlayerCareerModal';
import { getPlayerCareersColumnsConfig } from 'pages/player/PlayerCareersPage/columnsConfig';
import { useCreatePlayerCareer } from 'pages/player/PlayerCareersPage/hooks/useCreatePlayerCareer';
import React from 'react';
import { useParams } from 'react-router-dom';
import { usePagination } from 'shared/hooks/usePagination';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './PlayerCareersTable.module.css';

export const PlayerCareersTable = () => {
  const { playerId = '' } = useParams();

  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getPlayerCareersColumnsConfig,
  });

  const { selectedPage, handlePageChange } = usePagination();
  const { data, isLoading, error } = useFetchPlayerCareersQuery({
    page: selectedPage,
    filter: playerId ? { 'filter[player_id]': playerId } : {},
  });

  const { openCreateModal, createCareerModalProps } = useCreatePlayerCareer();

  return (
    <div className={styles.root}>
      <CommonListWrapper
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title="Карьера"
        createButtonProps={{
          content: 'Создать карьеру',
          onClick: openCreateModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: data?.page ?? 1,
          totalItems: data?.total ?? 0,
          onPageChange: handlePageChange,
        }}
      >
        <CommonListTable
          activeItemId={activeItemId}
          columns={columnsConfig}
          data={data?.careers || []}
          rowClassName={styles.rowGridTemplate}
        />
      </CommonListWrapper>
      <PlayerCareerModal {...createCareerModalProps} />
    </div>
  );
};
