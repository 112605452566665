import cn from 'classnames';
import { useLocales } from 'entities/config/lib/useLocales';
import { useDeleteStoryElementMutation } from 'entities/stories/mutations';
import { TStoryElementData, TStoryToolKind } from 'entities/stories/types';
import { STORY_TITLES_BY_KIND } from 'pages/Stories/EditStory/constants';
import { useStoryEditorContext } from 'pages/Stories/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/Stories/EditStory/hooks/useStoryEditorDispatchContext';
import React, { MouseEvent, MouseEventHandler, useCallback } from 'react';
import { Dropzone } from 'shared/components/Dropzone/Dropzone';
import { Icon } from 'shared/components/Icon/Icon';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { DNDItemTypes } from 'shared/constants';
import { useSortableDND } from 'shared/hooks/useSortableDND';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';

import styles from './LayersPanel.module.css';

type TProps = {
  id: TEntityId;
  kind: TStoryToolKind;
  storyElement: TStoryElementData<TStoryToolKind>;
  index: number;
  text: string | null;
  onClick: (id: TStoryElementData<TStoryToolKind>) => void;
  onHover?: MouseEventHandler;
  updateLayers: (dragIndex: number, hoverIndex: number, dragItemId?: string) => void;
};

export const LayerItem = ({
  id: elementId,
  kind,
  storyElement,
  index,
  updateLayers,
  text,
  onClick,
}: TProps) => {
  const { selectedLocale, selectedElement, selectedStory, elementsWithErrors } =
    useStoryEditorContext();
  const dispatch = useStoryEditorDispatchContext();
  const { defaultLocale } = useLocales();
  const deleteElementMutation = useDeleteStoryElementMutation(
    selectedStory?.id.toString(),
  );
  const isDisabled = selectedLocale !== defaultLocale;
  const hasErrors = elementsWithErrors?.includes(storyElement.id);
  const { handlerId, drop, ref, preview, drag, isDragging } = useSortableDND({
    index,
    move: updateLayers,
    dragItemType: DNDItemTypes.LAYER_ITEM,
    canDrag: !isDisabled,
    item: {
      id: toString(storyElement.id),
    },
  });

  const handleMouseEnter = useCallback(() => {
    dispatch({ type: 'highlightElement', payload: storyElement.id });
  }, [dispatch, storyElement.id]);

  const handleMouseLeave = useCallback(() => {
    dispatch({ type: 'highlightElement', payload: null });
  }, [dispatch]);

  const handleClick = useCallback(() => {
    dispatch({ type: 'highlightElement', payload: null });
    onClick(storyElement);
  }, [dispatch, onClick, storyElement]);

  const handleDelete = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      deleteElementMutation.mutate(storyElement.id);

      // Удаляем ошибки, если были
      if (hasErrors) {
        const updatedErrors =
          elementsWithErrors?.filter((id) => toString(id) !== toString(elementId)) ||
          [];
        dispatch({
          type: 'elementsWithErrors',
          payload: updatedErrors.length > 0 ? updatedErrors : null,
        });
      }

      if (storyElement.id === selectedElement?.id) {
        dispatch({ type: 'selectStoryElement', payload: null });
      }
    },
    [deleteElementMutation, dispatch, selectedElement?.id, storyElement.id],
  );

  const preventClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  drag(drop(ref));

  if (isDragging) {
    return (
      <Dropzone className={styles.dropZone} dragItemType={DNDItemTypes.LAYER_ITEM} />
    );
  }

  return (
    <div
      ref={preview}
      className={cn(styles.layerItem, {
        [styles.isError]: hasErrors,
      })}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={cn(styles.dragArea, { [styles.dragDisable]: isDisabled })}
        data-handlerid={handlerId}
        ref={ref}
        onClick={preventClick}
      >
        <Icon kind="dots-grag" size="xs" />
      </div>
      <div className={styles.layerTitle}>
        {STORY_TITLES_BY_KIND[kind]}
        {text && ` - ${text}`}
      </div>
      <div className={styles.buttonsWrapper}>
        {hasErrors && <Icon kind="alert-circle" size="xs" />}
        <IconButton
          disabled={isDisabled}
          icon="trash"
          iconSize="xs"
          className={cn(styles.deleteButton, {
            [styles.buttonDisabled]: isDisabled,
          })}
          onClick={handleDelete}
        />
      </div>
    </div>
  );
};
