import { useLocales } from 'entities/config/lib/useLocales';
import { useCreateLabelsMutation } from 'entities/labels/mutations';
import { useFetchLabelsQuery } from 'entities/labels/queries';
import { LabelsPageTable } from 'pages/settings/LabelsPage/LabelsPageTable';
import React, { useCallback, useState } from 'react';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { usePagination } from 'shared/hooks/usePagination';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TTranslations } from 'shared/types/common';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

import styles from './LabelsPage.module.css';

export const LabelsPage = () => {
  const { locales } = useLocales();
  const { selectedPage, handlePageChange } = usePagination();
  const { data, isLoading, error } = useFetchLabelsQuery({ page: selectedPage });

  const [showCreateModal, setShowCreateModal] = useState(false);
  const createLabels = useCreateLabelsMutation();

  const openModal = useCallback(() => {
    setShowCreateModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowCreateModal(false);
  }, []);

  const createLabel = (data: TTranslations<string>) => {
    createLabels.mutate({
      translations: reduceTranslationObject(locales, (locale) => ({
        name: data[locale],
      })),
    });
    closeModal();
  };

  return (
    <div className={styles.root}>
      <CommonListWrapper
        error={error}
        isEmpty={!data?.categories || (data?.total === 0 && !isLoading)}
        isLoading={isLoading}
        title="Список Лейблов"
        createButtonProps={{
          content: 'Создать лейбл',
          onClick: openModal,
        }}
        paginationProps={{
          selectedPage: data?.page ?? 1,
          totalItems: data?.total ?? 0,
          onPageChange: handlePageChange,
        }}
      >
        <LabelsPageTable data={data?.categories || []} />
        <LocalizationModal
          actionSubmit={createLabel}
          closeModal={closeModal}
          isOpened={showCreateModal}
          subtitle="Название лейбла"
          title="Создание лейбла"
          inputProps={{
            maxLength: 255,
            placeholder: 'Введите заголовок',
            required: true,
            size: 's',
          }}
        />
      </CommonListWrapper>
    </div>
  );
};
