import isEmpty from 'lodash/isEmpty';
import { useStoryEditorContext } from 'pages/Stories/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/Stories/EditStory/hooks/useStoryEditorDispatchContext';
import { useEffect } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { TEntityId } from 'shared/types/common';

type TUseFormErrorsArgs<T extends FieldValues> = {
  errors: FieldErrors<T>;
  selectedElementId: TEntityId;
  updateElement: () => void;
};
export const useFormErrors = <T extends FieldValues>({
  errors,
  selectedElementId,
  updateElement,
}: TUseFormErrorsArgs<T>) => {
  const { elementsWithErrors } = useStoryEditorContext();
  const dispatch = useStoryEditorDispatchContext();

  useEffect(() => {
    const errorsExist = !isEmpty(errors);
    const isElementNotInErrors = !elementsWithErrors?.includes(selectedElementId);

    if (errorsExist && isElementNotInErrors) {
      // Добавляем ID элемента в список ошибок, если он ещё не добавлен
      const newErrors = elementsWithErrors
        ? [...elementsWithErrors, selectedElementId]
        : [selectedElementId];
      updateElement();
      dispatch({ type: 'elementsWithErrors', payload: newErrors });
    } else if (!errorsExist && !isElementNotInErrors) {
      // Удаляем ID элемента из списка ошибок
      const updatedErrors =
        elementsWithErrors?.filter((id) => id !== selectedElementId) || [];
      dispatch({
        type: 'elementsWithErrors',
        payload: updatedErrors.length > 0 ? updatedErrors : null,
      });
    }
  }, [dispatch, errors, elementsWithErrors, selectedElementId, updateElement]);
};
