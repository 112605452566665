import { useQuery } from '@tanstack/react-query';
import { fetchSocialNetworks } from 'entities/socialNetworks/api';
import { socialNetworksQueryKeys } from 'entities/socialNetworks/consts';

export const useFetchSocialNetworksQuery = () => {
  return useQuery({
    queryKey: [socialNetworksQueryKeys.socialNetworks],
    queryFn: fetchSocialNetworks,
    cacheTime: 0,
  });
};
