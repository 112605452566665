import axios from 'axios';
import { useEditVideoPostMutation } from 'entities/video/mutations';
import { notify } from 'shared/components/Notification';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_TITLE } from 'shared/validation';
import { TVideoPostFields } from 'widgets/video-form/types';

export const useEditVideoPost = () => {
  const { mutateAsync } = useEditVideoPostMutation();

  return async (data: TVideoPostFields) => {
    try {
      await mutateAsync(data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        notify(DEFAULT_ERROR_MESSAGE, { type: 'error', title: DEFAULT_ERROR_TITLE });
      }
    }
  };
};
