import cn from 'classnames';
import { useLocales } from 'entities/config/lib/useLocales';
import { Fragment, useState } from 'react';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Divider } from 'shared/components/Divider';
import { TLangType } from 'shared/types/common';

import { LocaleControl } from './LocaleControl';
import styles from './LocalesControl.module.css';

type TProps = {
  selectedLocale: TLangType;
  onLocaleSelect: (locale: TLangType) => void;
  onLocaleAdd: (locale: TLangType) => void;
  onLocaleDelete: (locale: TLangType) => void;
  createdLocales: TLangType[];
  className?: string;
  disabled?: boolean;
};

export const LocalesControl = ({
  selectedLocale,
  onLocaleSelect,
  onLocaleDelete,
  onLocaleAdd,
  createdLocales,
  disabled = false,
  className,
}: TProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [localeToDelete, setLocaleToDelete] = useState<TLangType | null>(null);
  const { locales } = useLocales();

  const handleLocaleDelete = (locale: TLangType) => {
    setIsDeleting(true);
    setLocaleToDelete(locale);
  };

  const onConfirm = () => {
    if (localeToDelete) {
      onLocaleDelete(localeToDelete);
    }

    setIsDeleting(false);
  };
  return (
    <div className={cn(styles.localesControl, className)}>
      {locales.map((locale, index) => (
        <Fragment key={locale}>
          <LocaleControl
            isCreated={createdLocales.includes(locale)}
            isDisabled={disabled}
            isSelected={selectedLocale === locale}
            locale={locale}
            onLocaleAdd={onLocaleAdd}
            onLocaleDelete={handleLocaleDelete}
            onLocaleSelect={onLocaleSelect}
          />
          {index !== locales.length - 1 &&
            locales?.[index + 1] !== selectedLocale &&
            selectedLocale !== locale && (
              <Divider className={styles.divider} variant="vertical" />
            )}
        </Fragment>
      ))}
      <ConfirmationModal
        description="При удаление данной версии весь контент внутри этой локализации будет утерян"
        isOpened={isDeleting}
        title="Удалить локализацию?"
        onClose={() => setIsDeleting(false)}
        onConfirm={onConfirm}
      />
    </div>
  );
};
