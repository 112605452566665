import { TEXT_MIN_LENGTH_MESSAGE } from 'shared/consts';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toNumber } from 'shared/lib/toNumber';
import { TLangType } from 'shared/types/common';
import z from 'zod';

const LEADING_ZERO_REGEXP = /^0/;

export const ACHIEVEMENT_TEXT_MIN_LENGTH = 1;
export const ACHIEVEMENT_TITLE_MAX_LENGTH = 255;
export const ACHIEVEMENT_YEAR_MAX_LENGTH = 4;
export const ACHIEVEMENT_YEAR_MIN_VALUE = 1;
export const ACHIEVEMENT_YEAR_MAX_VALUE = 3000;

export const ACHIEVEMENT_YEAR_INVALID_MESSAGE = `Год должен быть числом от ${ACHIEVEMENT_YEAR_MIN_VALUE} до ${ACHIEVEMENT_YEAR_MAX_VALUE}`;
export const ACHIEVEMENT_TITLE_MAX_LENGTH_MESSAGE =
  'Превышена максимальная длина названия';
export const ACHIEVEMENT_YEAR_MAX_LENGTH_MESSAGE =
  'Превышена максимальная длина года';

const createTitleSchema = (locales: TLangType[]) => {
  return reduceTranslationObject(locales, () =>
    z.object({
      title: z
        .string()
        .min(ACHIEVEMENT_TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE)
        .max(ACHIEVEMENT_TITLE_MAX_LENGTH, ACHIEVEMENT_TITLE_MAX_LENGTH_MESSAGE),
    }),
  );
};

const isValidYear = (value: string) => {
  const year = toNumber(value);
  return (
    !isNaN(year) &&
    year >= ACHIEVEMENT_YEAR_MIN_VALUE &&
    year <= ACHIEVEMENT_YEAR_MAX_VALUE
  );
};

const isLeadingZeroNumber = (value: string) => !LEADING_ZERO_REGEXP.test(value);

export const achievementModalValidationSchema = (locales: TLangType[]) =>
  z.object({
    translations: z.object(createTitleSchema(locales)),
    year: z
      .string()
      .max(ACHIEVEMENT_YEAR_MAX_LENGTH, ACHIEVEMENT_YEAR_MAX_LENGTH_MESSAGE)
      .refine(isLeadingZeroNumber, {
        message: 'Год не должен начинаться с нуля',
      })
      .refine(isValidYear, {
        message: ACHIEVEMENT_YEAR_INVALID_MESSAGE,
      }),
  });
