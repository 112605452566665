import { managerSocialNetworksApiKeys } from 'entities/managerSocialNetworks/consts';
import { mapManagerSocialNetworksResponse } from 'entities/managerSocialNetworks/mapper/mapManagerSocialNetworksResponse';
import { TManagerSocialNetwork } from 'entities/managerSocialNetworks/types';
import { del, get } from 'shared/api/axios';
import { createPaginationParams } from 'shared/api/utils';
import { TEntityId } from 'shared/types/common';

export type TFetchManagerSocialNetworksParams = {
  page: number;
  filter?: Record<string, string>;
};

export const fetchManagerSocialNetworks = ({
  page,
  filter,
}: TFetchManagerSocialNetworksParams) =>
  get<TManagerSocialNetwork[], true>(
    managerSocialNetworksApiKeys.getManagerSocialNetworks,
    {
      params: {
        ...createPaginationParams(page),
        ...filter,
      },
    },
  ).then((response) => mapManagerSocialNetworksResponse(response.data));

export const deleteManagerSocialNetwork = (managerId: TEntityId) =>
  del(managerSocialNetworksApiKeys.deleteManagerSocialNetwork(managerId));
