import { ManagerLayout } from 'feature/manager-layout/ui/ManagerLayout/ManagerLayout';
import { ManagerCareersTable } from 'pages/manager/ManagerCareersPage/ManagerCareersTable/ManagerCareersTable';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'shared/routes';

export const ManagerCareersPage = () => {
  const navigate = useNavigate();
  const { teamId = '', managerId = '' } = useParams<{
    teamId: string;
    managerId: string;
  }>();

  const onClick = () => {
    navigate(routes.managerTeamAchievements.as(teamId, managerId));
  };

  return (
    <ManagerLayout
      confirmButtonProps={{
        onClick,
      }}
    >
      <ManagerCareersTable />
    </ManagerLayout>
  );
};
