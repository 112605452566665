import { ToolsListButton } from 'pages/Stories/EditStory/components/ToolsList/ToolsListButton';
import { STORY_TOOLS } from 'pages/Stories/EditStory/constants';

import styles from './ToolsList.module.css';

export const ToolsList = () => {
  return (
    <div className={styles.root}>
      {STORY_TOOLS.map((tool) => (
        <ToolsListButton
          key={tool.kind}
          icon={tool.icon}
          kind={tool.kind}
          limit={tool.limit}
          title={tool.title}
        />
      ))}
    </div>
  );
};
