import { indexToLetterMap } from 'pages/Stories/EditStory/components/elements/constants';
import {
  TQuizAnswersItem,
  TQuizControl,
} from 'pages/Stories/EditStory/components/forms/QuizElementForm/types';
import React, { memo } from 'react';
import { Controller } from 'react-hook-form';
import { RadioButton } from 'shared/components/Form/RadioButton';
import type { TChangeRadioButtonHandler } from 'shared/components/Form/RadioButton/types';

type TProps = {
  answerField: TQuizAnswersItem;
  index: number;
  control: TQuizControl;
  onChange: TChangeRadioButtonHandler;
};

export const AnswerRadioButton = memo(
  ({ answerField, index, control, onChange }: TProps) => {
    return (
      <Controller
        key={answerField.id}
        control={control}
        name="correctAnswerIndex"
        render={({ field }) => (
          <RadioButton
            checked={field.value === index}
            disabled={answerField.disabled}
            label={`Вариант - ${indexToLetterMap[index]}`}
            name={field.name}
            size="s"
            value={index}
            onChange={onChange}
          />
        )}
      />
    );
  },
);
