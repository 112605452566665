import { ChangeEvent } from 'react';
import { Controller } from 'react-hook-form';
import TextAreaAutosize from 'react-textarea-autosize';
import { Field } from 'shared/components/Form';
import { MAX_WORKSPACE_TITLE_LENGTH } from 'shared/components/NewsPostForm/validationSchema';
import { Text } from 'shared/components/Text/Text';
import { TWorkspaceProps } from 'shared/components/Workspace/Workspace';
import { toBoolean } from 'shared/lib/toBoolean';

import styles from './WorkspaceTitle.module.css';

export const WorkspaceTitle = ({ control, currentLocale }: TWorkspaceProps) => (
  <Controller
    key={`content.title.translations.${currentLocale}.title`}
    control={control}
    name={`content.title.translations.${currentLocale}.title`}
    rules={{ required: true, maxLength: 255 }}
    render={(props) => {
      const { field, fieldState } = props;

      const onChangeHandler = (value: ChangeEvent<HTMLTextAreaElement>) => {
        const findLineBreakRegexp = /[\r\n\v]+/g;

        field.onChange(value.target.value.replace(findLineBreakRegexp, ''));
      };

      const onBlurHandler = (value: ChangeEvent<HTMLTextAreaElement>) => {
        if (toBoolean(value.target.value)) {
          field.onChange(value.target.value.trim());
        }
      };

      return (
        <Field
          className={styles.field}
          error={fieldState.error}
          label="Заголовок новости"
          required
          showErrors
          tip="Заголовок будет отображаться в превью и на детальном экране новости"
        >
          <>
            <TextAreaAutosize
              className={styles.input}
              placeholder="Заголовок новости"
              {...field}
              maxLength={MAX_WORKSPACE_TITLE_LENGTH}
              minRows={1}
              onBlur={onBlurHandler}
              onChange={onChangeHandler}
            />
            <Text className={styles.counter} size={12} weight="SemiBold">
              {field.value?.length}/{MAX_WORKSPACE_TITLE_LENGTH}
            </Text>
          </>
        </Field>
      );
    }}
  />
);
