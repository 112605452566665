import { UnpublishConfirmationModal } from 'feature/video/ui/modals/UnpublishConfirmationModal';
import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Button } from 'shared/components/Button/Button';
import { useEditVideoPost } from 'widgets/video-form/hooks/useEditVideoPost';
import { useIsEditVideo } from 'widgets/video-form/hooks/useIsEditVideo';
import { useIsPublishVideo } from 'widgets/video-form/hooks/useIsPublishVideo';
import { TVideoPostFields } from 'widgets/video-form/types';

export const VideoFormUnpublishButton = () => {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const { formState, control, handleSubmit } = useFormContext<TVideoPostFields>();
  const { field } = useController({
    control,
    name: 'publishDate',
  });
  const editVideoPost = useEditVideoPost();

  const isEdit = useIsEditVideo();
  const isPublish = useIsPublishVideo();

  if (!isEdit || !isPublish) {
    return null;
  }

  const onUnpublish = () => {
    setIsOpenConfirmModal(true);
  };

  const onConfirm = () => {
    setIsOpenConfirmModal(false);
    field.onChange(null);
    handleSubmit(editVideoPost)();
  };

  return (
    <>
      <Button
        color="gray"
        isDisabled={formState.isSubmitting}
        kind="outlined"
        onClick={onUnpublish}
      >
        Снять с публикации
      </Button>
      <UnpublishConfirmationModal
        isOpened={isOpenConfirmModal}
        onClose={() => setIsOpenConfirmModal(false)}
        onConfirm={onConfirm}
      />
    </>
  );
};
