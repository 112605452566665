import { useQueries, useQuery } from '@tanstack/react-query';
import { TEntityId } from 'shared/types/common';

import {
  fetchStory,
  fetchStoryElement,
  fetchStoryGroup,
  fetchStoryGroups,
} from './api';
import { storiesQueryKeys } from './constants';

export const useFetchStoryGroupsQuery = () =>
  useQuery({
    queryKey: [storiesQueryKeys.storyGroups],
    queryFn: fetchStoryGroups,
  });

export const useFetchStoryGroupById = (id: TEntityId) =>
  useQuery({
    queryKey: [storiesQueryKeys.storyGroup, id],
    queryFn: () => fetchStoryGroup(id),
  });

export const useFetchStoriesByIds = (ids?: string[]) =>
  useQueries({
    queries: ids
      ? ids.map((id) => {
          return {
            queryKey: [storiesQueryKeys.story, id],
            queryFn: () => fetchStory(id),
          };
        })
      : [],
  });

export const useFetchElementsByIds = (ids?: string[]) =>
  useQueries({
    queries: ids
      ? ids.map((id) => {
          return {
            queryKey: [storiesQueryKeys.storyElement, id],
            queryFn: () => fetchStoryElement(id),
          };
        })
      : [],
  });
