import { useRightPanelPreview } from 'feature/right-panel/hooks/useRightPanelPreview';
import { TDefaultRightPanelProps } from 'feature/right-panel/types';
import { CommonPreview } from 'feature/right-panel/ui/CommonPreview/CommonPreview';
import { useController, useFormContext } from 'react-hook-form';
import { imageSettings } from 'shared/constants';
import { TVideoPostFields } from 'widgets/video-form/types';

export const VideoPreview = (commonProps: TDefaultRightPanelProps) => {
  const { control } = useFormContext<TVideoPostFields>();
  const { field: previewFile, fieldState } = useController({
    control,
    name: 'preview.file',
  });
  const { field: previewData } = useController({
    control,
    name: 'preview.data',
  });

  useRightPanelPreview({
    file: previewFile.value,
    onChange: previewData.onChange,
  });

  return (
    <CommonPreview
      aspectRatio={imageSettings.video.preview.aspectRatio}
      control={control}
      description="Изображение будет отображаться в верхней части экрана видео."
      fieldState={fieldState}
      fileFieldName="preview.file"
      previewDataUrl={previewData.value}
      previewFile={previewFile.value}
      {...commonProps}
    />
  );
};
