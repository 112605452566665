import { Button } from 'shared/components/Button/Button';
import { Text } from 'shared/components/Text/Text';

import styles from './EmptyList.module.css';

type TProps = {
  onCreateStories: () => void;
};

export const EmptyList = ({ onCreateStories }: TProps) => {
  return (
    <div className={styles.root}>
      <Text size={24} weight="SemiBold">
        Создайте свою первую сторис
      </Text>
      <Button icon="plus" onClick={onCreateStories}>
        Создать сторис
      </Button>
    </div>
  );
};
