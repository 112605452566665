import { TStoryElementData, TStoryToolKind } from 'entities/stories/types';
import { ElementForm } from 'pages/Stories/EditStory/components/forms/ElementForm';
import { LayersPanel } from 'pages/Stories/EditStory/components/LayersPanel/LayersPanel';
import { ToolsList } from 'pages/Stories/EditStory/components/ToolsList';
import { useStoryEditorContext } from 'pages/Stories/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/Stories/EditStory/hooks/useStoryEditorDispatchContext';
import { useLayoutEffect, useState } from 'react';
import { Tabs } from 'shared/components/Tabs';

import styles from './ToolsPanel.module.css';

const elementNameMap: { [key in TStoryToolKind]: string } = {
  text: 'Текст',
  button: 'Кнопка',
  feedback: 'Обратная связь',
  countdown: 'Таймер',
  voting: 'Голосование',
  copy: 'Копирование',
  swipeUp: 'Свайп вверх',
  quiz: 'Викторина',
};

export const ToolsPanel = () => {
  const { selectedElement } = useStoryEditorContext();
  const [selectedTab, setSelectedTab] = useState('tools');
  const dispatch = useStoryEditorDispatchContext();

  useLayoutEffect(() => {
    if (selectedElement && selectedTab === 'layers') {
      return;
    }
    if (selectedElement) {
      return setSelectedTab('element');
    }

    if (!selectedElement && selectedTab === 'element') {
      return setSelectedTab('tools');
    }

    if (!selectedElement && selectedTab === 'layers') {
      return;
    }
  }, [selectedElement]);

  const handleElementSelect = (storyElement: TStoryElementData) => {
    dispatch({ type: 'selectStoryElement', payload: storyElement });
    setSelectedTab('element');
  };

  return (
    <div className={styles.root}>
      <Tabs className={styles.tabs} selected={selectedTab} onSelect={setSelectedTab}>
        <Tabs.Tab label="Слои" value="layers">
          <LayersPanel onElementSelect={handleElementSelect} />
        </Tabs.Tab>
        {selectedElement ? (
          <Tabs.Tab label={elementNameMap[selectedElement.kind]} value="element">
            <ElementForm selectedElement={selectedElement} />
          </Tabs.Tab>
        ) : (
          <Tabs.Tab label="Панель контента" value="tools">
            <ToolsList />
          </Tabs.Tab>
        )}
      </Tabs>
    </div>
  );
};
