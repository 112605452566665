import { AchievementModal } from 'feature/achievement-modal/AchievementModal';
import { usePlayerTeamAchievementsActions } from 'pages/player/PlayerTeamAchievementsPage/hooks/usePlayerTeamAchievementsActions';
import { TPlayerTeamAchievementsActions } from 'pages/player/PlayerTeamAchievementsPage/types';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';

type TProps = TPlayerTeamAchievementsActions;

export const PlayerTeamAchievementsActions = (props: TProps) => {
  const { actionsProps, deleteModalProps, editModalProps } =
    usePlayerTeamAchievementsActions(props);

  return (
    <>
      <Actions {...actionsProps} />
      {deleteModalProps.isOpened && <ConfirmationModal {...deleteModalProps} />}
      {editModalProps.isOpened && <AchievementModal {...editModalProps} />}
    </>
  );
};
