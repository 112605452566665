import { useState } from 'react';

export const usePagination = (initialPage = 1) => {
  const [selectedPage, setSelectedPage] = useState(initialPage);

  const handlePageChange = (page: number) => {
    setSelectedPage(page);
  };

  return { selectedPage, handlePageChange };
};
