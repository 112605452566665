// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".THeadCell-module__cell--qrSmd {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 9px 12px;\n  color: var(--dark-blue-500);\n  cursor: pointer;\n}\n\n.THeadCell-module__icon--EKsHJ {\n  padding: 0 4px;\n  color: var(--gray-400);\n}\n\n.THeadCell-module__icon--EKsHJ svg {\n    width: 12px;\n    height: 12px;\n  }\n\n.THeadCell-module__cell--qrSmd:hover:not(.THeadCell-module__active--MACCL) .THeadCell-module__icon--EKsHJ,\n.THeadCell-module__icon--EKsHJ:hover:not(.THeadCell-module__active--MACCL) {\n  color: var(--dark-blue-500);\n}\n\n.THeadCell-module__active--MACCL {\n  color: var(--green-400);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/SimpleTable/components/THeadCell/THeadCell.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,iBAAiB;EACjB,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,sBAAsB;AAMxB;;AAJE;IACE,WAAW;IACX,YAAY;EACd;;AAGF;;EAEE,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".cell {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 9px 12px;\n  color: var(--dark-blue-500);\n  cursor: pointer;\n}\n\n.icon {\n  padding: 0 4px;\n  color: var(--gray-400);\n\n  svg {\n    width: 12px;\n    height: 12px;\n  }\n}\n\n.cell:hover:not(.active) .icon,\n.icon:hover:not(.active) {\n  color: var(--dark-blue-500);\n}\n\n.active {\n  color: var(--green-400);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": "THeadCell-module__cell--qrSmd",
	"icon": "THeadCell-module__icon--EKsHJ",
	"active": "THeadCell-module__active--MACCL"
};
export default ___CSS_LOADER_EXPORT___;
