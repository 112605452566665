import { PlayerLayout } from 'feature/player-layout/ui/PlayerLayout/PlayerLayout';
import { PlayerCareersTable } from 'pages/player/PlayerCareersPage/PlayerCareersTable/PlayerCareersTable';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'shared/routes';

export const PlayerCareersPage = () => {
  const navigate = useNavigate();
  const { teamId = '', playerId = '' } = useParams();
  const onClick = () => {
    navigate(routes.playerTeamAchievements.as(teamId, playerId));
  };

  return (
    <PlayerLayout
      confirmButtonProps={{
        onClick: onClick,
      }}
    >
      <PlayerCareersTable />
    </PlayerLayout>
  );
};
