import { useContext } from 'react';
import { AttachVideoLinkModal } from 'shared/components/AttachVideoLinkModal/AttachVideoLinkModal';
import { CropperModal } from 'shared/components/cropper/CropperModal/CropperModal';
import { Divider } from 'shared/components/Divider';
import { ImageUploadModal } from 'shared/components/ImageUploadModal/ImageUploadModal';
import { ToolbarButton } from 'shared/components/ToolbarButton/ToolbarButton';
import { useWorkspacePanelBulletedListActions } from 'shared/components/Workspace/components/WorkspacePanel/useWorkspacePanelBulletedListActions';
import { useWorkspacePanelLinkActions } from 'shared/components/Workspace/components/WorkspacePanel/useWorkspacePanelLinkActions';
import { useWorkspacePanelMarksActions } from 'shared/components/Workspace/components/WorkspacePanel/useWorkspacePanelMarksActions';
import { WorkspacePanelAddLinkModal } from 'shared/components/Workspace/components/WorkspacePanel/WorkspacePanelAddLinkModal/WorkspacePanelAddLinkModal';
import { WorkspacePanelHeadingSelect } from 'shared/components/Workspace/components/WorkspacePanel/WorkspacePanelHeadingSelect/WorkspacePanelHeadingSelect';
import { useIsDisabledStructure } from 'shared/components/Workspace/hooks';
import { WorkspaceContext } from 'shared/components/Workspace/WorkspaceContext/WorkspaceContext';
import { imageSettings } from 'shared/constants';
import { TLangType } from 'shared/types/common';
import { useSlate } from 'slate-react';

import styles from './WorkspacePanel.module.css';

export const WorkspacePanel = ({ currentLocale }: { currentLocale: TLangType }) => {
  const isDisableStructureChange = useIsDisabledStructure(currentLocale);
  const editor = useSlate();

  const {
    image: {
      croppingImageUrl,
      isOpenCropImageModal,
      closeCropImageModal,
      isOpenImageModal,
      closeLoadImageModal,
      openLoadImageModal,
      isEditImage,
      loadImage,
      replaceCroppedImage,
    },
    slider: {
      isOpenCropSliderImageModal,
      croppingSliderImageUrl,
      closeCropSliderImageModal,
      isOpenSliderImagesModal,
      openSliderImagesModal,
      isEditSlider,
      closeSliderImagesModal,
      loadSliderImages,
      replaceCroppedSliderImage,
    },
    video: {
      isOpenAttachVideoLinkModal,
      openAttachVideoLinkModal,
      closeAttachVideoLinkModal,
      attachLink,
    },
  } = useContext(WorkspaceContext);

  const {
    isBold,
    isItalic,
    isUnderline,
    isDeleted,
    toggleBold,
    toggleItalic,
    toggleUnderline,
    toggleDeleted,
  } = useWorkspacePanelMarksActions(editor);

  const {
    selectedLink,
    selectedText,
    openedAddLinkModal,
    openAddLinkModal,
    closeAddLinkModal,
    createLinkElement,
    isActiveLink,
    deleteLinkElement,
  } = useWorkspacePanelLinkActions(editor);
  const { toggleBulletedList } = useWorkspacePanelBulletedListActions(editor);

  return (
    <div className={styles.root}>
      <div className={styles.actions}>
        <WorkspacePanelHeadingSelect
          disabled={isDisableStructureChange}
          editor={editor}
        />
        <ToolbarButton active={isBold()} icon="bold-01" onClick={toggleBold} />
        <ToolbarButton active={isItalic()} icon="italic-01" onClick={toggleItalic} />
        <ToolbarButton
          active={isUnderline()}
          icon="underline-01"
          onClick={toggleUnderline}
        />
        <ToolbarButton
          active={isDeleted()}
          icon="strikethrough-01"
          onClick={toggleDeleted}
        />
        <Divider className={styles.divider} variant="vertical" />
        <ToolbarButton
          disabled={isDisableStructureChange}
          icon="dotpoints-01"
          onClick={toggleBulletedList}
        />
        <Divider className={styles.divider} variant="vertical" />
        <ToolbarButton
          disabled={isDisableStructureChange}
          icon="image-01"
          onClick={openLoadImageModal}
        />
        <ToolbarButton
          disabled={isDisableStructureChange}
          icon="sliders-image"
          onClick={openSliderImagesModal}
        />
        <ToolbarButton
          disabled={isDisableStructureChange}
          icon="video"
          onClick={openAttachVideoLinkModal}
        />
        <ToolbarButton icon="link-01" onClick={openAddLinkModal} />
        <ToolbarButton
          disabled={!isActiveLink()}
          icon="link-broken-02"
          onClick={deleteLinkElement}
        />
      </div>
      <ImageUploadModal
        opened={isOpenImageModal}
        title={isEditImage ? 'Замена изображения' : 'Добавление изображения'}
        onClose={closeLoadImageModal}
        onConfirm={loadImage}
      />
      <ImageUploadModal
        multiple
        opened={isOpenSliderImagesModal}
        title={
          isEditSlider
            ? 'Замена изображений в слайдере'
            : 'Добавления изображений в слайдер'
        }
        onClose={closeSliderImagesModal}
        onConfirm={loadSliderImages}
      />
      <WorkspacePanelAddLinkModal
        defaultText={selectedText}
        defaultUrl={selectedLink?.url}
        opened={openedAddLinkModal}
        onClose={closeAddLinkModal}
        onCreate={createLinkElement}
      />
      <AttachVideoLinkModal
        isOpened={isOpenAttachVideoLinkModal}
        title="Вставить видео"
        onClose={closeAttachVideoLinkModal}
        onConfirm={attachLink}
      />
      {croppingImageUrl && (
        <CropperModal
          defaultAspectRatio={imageSettings.news.paragraph.image.aspectRatio}
          imageBase64Url={croppingImageUrl}
          isOpen={isOpenCropImageModal}
          onClose={closeCropImageModal}
          onCrop={replaceCroppedImage}
        />
      )}
      {croppingSliderImageUrl && (
        <CropperModal
          defaultAspectRatio={imageSettings.news.paragraph.slider.aspectRatio}
          imageBase64Url={croppingSliderImageUrl}
          isOpen={isOpenCropSliderImageModal}
          onClose={closeCropSliderImageModal}
          onCrop={replaceCroppedSliderImage}
        />
      )}
    </div>
  );
};
