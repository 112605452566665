// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Actions-module__dropdown--s9Mcb {\n  padding: 0 8px;\n}\n\n.Actions-module__actionButton--YLhMa {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 13px 16px;\n  width: 243px;\n}\n\n.Actions-module__actionButton--YLhMa > span {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n  }\n\n.Actions-module__actionButton--YLhMa > i {\n    width: 12px;\n    height: 12px;\n  }\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Actions/Actions.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;AAYd;;AAVE;IACE,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;EACrB;;AAEA;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":[".dropdown {\n  padding: 0 8px;\n}\n\n.actionButton {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 13px 16px;\n  width: 243px;\n\n  & > span {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n  }\n\n  & > i {\n    width: 12px;\n    height: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "Actions-module__dropdown--s9Mcb",
	"actionButton": "Actions-module__actionButton--YLhMa"
};
export default ___CSS_LOADER_EXPORT___;
