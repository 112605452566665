import {
  TDefaultVideoActions,
  TPostponedVideoActions,
} from 'entities/video/video-actions/types';
import { routes } from 'shared/routes';
import { TActionByStatus, TEntityId } from 'shared/types/common';

export const getDefaultVideoActions = (
  deleteAction: (id: TEntityId) => void,
): TDefaultVideoActions => ({
  edit: {
    title: 'Редактировать',
    icon: 'edit-02',
    action: (id, navigate) => {
      navigate?.(routes.editVideo.as(id));
    },
  },
  delete: {
    title: 'Удалить',
    icon: 'trash',
    action: deleteAction,
  },
});
const createPublishAction = (action: () => void): TActionByStatus => ({
  title: 'Снять с публикации',
  icon: 'no-eyes',
  action,
});

export const getVideoActions = (
  deleteAction: (id: TEntityId) => void,
  publishAction: (() => void) | null,
  postponedActions: TPostponedVideoActions | null,
) => {
  const defaultVideoActions = getDefaultVideoActions(deleteAction);

  if (publishAction) {
    return [
      defaultVideoActions.edit,
      createPublishAction(publishAction),
      defaultVideoActions.delete,
    ];
  }
  if (postponedActions) {
    return [
      postponedActions.publishNow,
      defaultVideoActions.edit,
      postponedActions.changePublishDate,
      defaultVideoActions.delete,
    ];
  }

  return Object.values(defaultVideoActions);
};
