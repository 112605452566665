import { playerTeamAchievementsApiKeys } from 'entities/playerTeamAchievements/consts';
import { mapPlayerTeamAchievementsResponse } from 'entities/playerTeamAchievements/mapper/mapPlayerTeamAchievementsResponse';
import {
  TCreatePlayerTeamAchievementDTO,
  TPlayerTeamAchievement,
} from 'entities/playerTeamAchievements/types';
import { del, get, patch, post } from 'shared/api/axios';
import { createPaginationParams } from 'shared/api/utils';
import { TEntityId } from 'shared/types/common';

export type TFetchPlayerTeamAchievementsParams = {
  page: number;
  filter?: Record<string, string>;
};

export const fetchPlayerTeamAchievements = ({
  page,
  filter,
}: TFetchPlayerTeamAchievementsParams) =>
  get<TPlayerTeamAchievement[], true>(
    playerTeamAchievementsApiKeys.getPlayerTeamAchievements,
    {
      params: {
        ...createPaginationParams(page),
        ...filter,
      },
    },
  ).then((response) => mapPlayerTeamAchievementsResponse(response.data));

export const createPlayerTeamAchievement = (
  teamAchievement: TCreatePlayerTeamAchievementDTO,
) =>
  post(playerTeamAchievementsApiKeys.createPlayerTeamAchievement, {
    teamAchievement,
  }).then((response) => response.data.data);

export const deletePlayerTeamAchievement = (teamAchievementId: TEntityId) =>
  del(playerTeamAchievementsApiKeys.deletePlayerTeamAchievement(teamAchievementId));

export const editPlayerTeamAchievement = ({
  id,
  ...playerTeamAchievement
}: TPlayerTeamAchievement) =>
  patch(
    playerTeamAchievementsApiKeys.editPlayerTeamAchievement(id),
    playerTeamAchievement,
  ).then((response) => response.data.data);
