import { compareAsc } from 'date-fns';
import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchMatchesQuery } from 'entities/matches/queries';
import { TMatch } from 'entities/matches/types';
import { useFetchOpponentTeamQuery } from 'entities/opponentTeams/queries';
import { getEndSeasonYear, getStartSeasonYear } from 'entities/seasons/helpers';
import { useFetchTeamQuery } from 'entities/teams/queries';
import { getMatchTeamsTitle } from 'feature/right-panel/getMatchTeamsTitle';
import { TRightPanelMatchProps } from 'feature/right-panel/types';
import { TMatchDatePickerProps } from 'feature/right-panel/ui/MatchDatePicker/MatchDatePicker';
import { range } from 'lodash';
import { useMemo, useState } from 'react';
import { toBoolean } from 'shared/lib/toBoolean';
import { compareIds } from 'shared/utils/entityIds';

const FIRST_MONTH_INDEX = 0;
const LAST_MONTH_INDEX = 11;
const YEAR_STEP = 1;

type TUseRightPanelMatchSelectReturn = {
  placeholder: string;
  hasMatch: boolean;
  openDatePicker: () => void;
  matchDatePickerProps?: TMatchDatePickerProps;
};

export const useRightPanelMatchSelect = ({
  selectedMatchId,
  onChangeMatch,
  seasons,
  selectedTournamentId,
  selectedSeasonId,
}: Omit<TRightPanelMatchProps, 'disabled'>): TUseRightPanelMatchSelectReturn => {
  const { defaultLocale } = useLocales();
  const [opened, setOpened] = useState(false);

  const { data: matches } = useFetchMatchesQuery(
    {
      seasonId: selectedSeasonId,
      tournamentId: selectedTournamentId,
    },
    { enabled: toBoolean(selectedTournamentId) && toBoolean(selectedSeasonId) },
  );

  const selectedSeason = useMemo(
    () => seasons.find((s) => compareIds(s.id, selectedSeasonId)),
    [seasons, selectedSeasonId],
  );

  const selectedMatch = useMemo(
    () =>
      matches ? matches.find((m) => compareIds(m.id, selectedMatchId)) : undefined,
    [matches, selectedMatchId],
  );

  const { data: team } = useFetchTeamQuery(selectedMatch?.team ?? '');
  const { data: opponentTeam } = useFetchOpponentTeamQuery({
    id: selectedMatch?.opponentTeam ?? '',
  });

  const oldestSeasonYear = useMemo(
    () => selectedSeason && getStartSeasonYear(selectedSeason),
    [selectedSeason],
  );
  const newestSeasonYear = useMemo(
    () => selectedSeason && getEndSeasonYear(selectedSeason),
    [selectedSeason],
  );

  const onChangeMatchHandler = (match: TMatch | undefined) => {
    onChangeMatch(match?.id ?? '');
    setOpened(false);
  };

  const fromDate = oldestSeasonYear
    ? new Date(oldestSeasonYear, FIRST_MONTH_INDEX)
    : undefined;

  const maxDate = useMemo(() => {
    if (newestSeasonYear) {
      const currentDate = new Date();
      const date = new Date(newestSeasonYear, LAST_MONTH_INDEX);

      if (compareAsc(date, currentDate) > 0) {
        return currentDate;
      }
      return date;
    }

    return undefined;
  }, [newestSeasonYear]);

  const years =
    newestSeasonYear && oldestSeasonYear
      ? range(oldestSeasonYear, newestSeasonYear + YEAR_STEP)
      : undefined;

  const hasMatch = selectedMatch && team && opponentTeam;
  const canShowPicker = matches && fromDate && maxDate && years;
  const showDatePicker = canShowPicker && opened;

  const placeholder = useMemo(
    () =>
      hasMatch
        ? getMatchTeamsTitle({
            match: selectedMatch,
            team,
            opponentTeam,
            defaultLocale,
          })
        : 'Выбрать матч из списка',
    [selectedMatch, team, opponentTeam, defaultLocale],
  );

  return {
    placeholder,
    hasMatch: toBoolean(hasMatch),
    openDatePicker: () => setOpened(true),
    matchDatePickerProps: showDatePicker
      ? {
          fromDate,
          matches,
          maxDate,
          selectedMatch,
          years,
          onChangeMatch: onChangeMatchHandler,
          onClose: () => setOpened(false),
        }
      : undefined,
  };
};
