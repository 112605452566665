import { managerTeamAchievementsApiKeys } from 'entities/managerTeamAchievements/consts';
import { mapManagerTeamAchievementsResponse } from 'entities/managerTeamAchievements/mapper/mapManagerTeamAchievementsResponse';
import { TManagerTeamAchievement } from 'entities/managerTeamAchievements/types';
import { del, get } from 'shared/api/axios';
import { createPaginationParams } from 'shared/api/utils';
import { TEntityId } from 'shared/types/common';

export type TFetchManagerTeamAchievementsParams = {
  page: number;
  filter?: Record<string, string>;
};

export const fetchManagerTeamAchievements = ({
  page,
  filter,
}: TFetchManagerTeamAchievementsParams) =>
  get<TManagerTeamAchievement[], true>(
    managerTeamAchievementsApiKeys.getManagerTeamAchievements,
    {
      params: {
        ...createPaginationParams(page),
        ...filter,
      },
    },
  ).then((response) => mapManagerTeamAchievementsResponse(response.data));

export const deleteManagerTeamAchievement = (teamAchievementId: TEntityId) =>
  del(
    managerTeamAchievementsApiKeys.deleteManagerTeamAchievement(teamAchievementId),
  );
