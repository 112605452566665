import { playerAchievementsApiKeys } from 'entities/playerAchievements/consts';
import { mapPlayerAchievementsResponse } from 'entities/playerAchievements/mapper/mapPlayerAchievementsResponse';
import {
  TCreatePlayerAchievementDTO,
  TPlayerAchievement,
} from 'entities/playerAchievements/types';
import { del, get, patch, post } from 'shared/api/axios';
import { createPaginationParams } from 'shared/api/utils';
import { TEntityId } from 'shared/types/common';

export type TFetchPlayerAchievementsParams = {
  page: number;
  filter?: Record<string, string>;
};

export const fetchPlayerAchievements = ({
  page,
  filter,
}: TFetchPlayerAchievementsParams) =>
  get<TPlayerAchievement[], true>(playerAchievementsApiKeys.getPlayerAchievements, {
    params: {
      ...filter,
      ...createPaginationParams(page),
    },
  }).then((response) => mapPlayerAchievementsResponse(response.data));

export const createPlayerAchievement = (achievement: TCreatePlayerAchievementDTO) =>
  post(playerAchievementsApiKeys.createPlayerAchievement, {
    achievement,
  }).then((response) => response.data.data);

export const deletePlayerAchievement = (achievementId: TEntityId) =>
  del(playerAchievementsApiKeys.deletePlayerAchievement(achievementId));

export const editPlayerAchievement = ({
  id,
  ...playerAchievement
}: TPlayerAchievement) =>
  patch(playerAchievementsApiKeys.editPlayerAchievement(id), playerAchievement).then(
    (response) => response.data.data,
  );
