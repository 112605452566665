import { EPlayerStatus } from 'entities/players/model/types';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Field } from 'shared/components/Form';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { TPlayerFormFields } from 'widgets/player-form/types/types';
import styles from 'widgets/player-form/ui/PlayerForm/PlayerForm.module.css';

type TProps = {
  control: Control<TPlayerFormFields>;
};

const playerStatusLabels = {
  [EPlayerStatus.PartOfTeam]: 'Игрок команды',
  [EPlayerStatus.Outstaff]: 'Приглашенный игрок',
};

export const PlayerFormStatus = ({ control }: TProps) => {
  return (
    <Controller
      control={control}
      name="status"
      render={({ field }) => (
        <Field label="Статус" required>
          <div className={styles.status}>
            {Object.values(EPlayerStatus).map((status) => (
              <RadioButton
                key={status}
                checked={field.value === status}
                label={playerStatusLabels[status]}
                name={field.name}
                size="s"
                value={status}
                onChange={(n, value) => {
                  field.onChange(value);
                }}
              />
            ))}
          </div>
        </Field>
      )}
    />
  );
};
