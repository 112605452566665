import { TPlayerAchievement } from 'entities/playerAchievements/types';
import { PlayerAchievementsActions } from 'pages/player/PlayerAchievementsPage/PlayerAchievementsActions/PlayerAchievementsActions';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elements-list-table/types';

import styles from './PlayerAchievementsPage.module.css';

export const getPlayerAchievementsColumnsConfig: TConfigCreator<
  TPlayerAchievement
> = (defaultLocale, activeSocialLinkId, setActiveSocialLinkId) => [
  {
    title: 'Название',
    cellClassName: styles.nameColumn,
    render: (item) => (
      <Text className={styles.text} color="var(--gray-500)">
        {item.translations?.[defaultLocale]?.title}
      </Text>
    ),
  },
  {
    title: 'Год',
    render: (item) => <Text color="var(--gray-500)">{item.year}</Text>,
  },
  {
    title: 'ID',
    render: (item) => <Text color="var(--gray-500)">{item.id}</Text>,
  },
  {
    title: '',
    render: (playerAchievement) => (
      <PlayerAchievementsActions
        activeAchievementId={activeSocialLinkId}
        playerAchievement={playerAchievement}
        setActiveAchievementId={setActiveSocialLinkId}
      />
    ),
  },
];
