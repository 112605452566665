import {
  useDeletePhotoGalleryCategoryMutation,
  useEditPhotoGalleryCategoryMutation,
} from 'entities/photoGalleryCategories/mutations';
import { TPhotoGalleryCategory } from 'entities/photoGalleryCategories/types';
import { CategoriesActions } from 'feature/categories/CategoriesActions/Actions';
import { useCategoriesPageTable } from 'feature/categories/hooks/useCategoriesPageTable';
import React, { useCallback } from 'react';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { TEntityId, TGroupAction } from 'shared/types/common';

type TProps = {
  activeItemId: TEntityId | null;
  setActiveItemId: (id: TEntityId | null) => void;
  photoGalleryCategory: TPhotoGalleryCategory;
};

export const PhotoGalleryCategoriesActionWrapper = ({
  activeItemId,
  setActiveItemId,
  photoGalleryCategory,
}: TProps) => {
  const { mutateAsync: deleteCategory, isLoading: isLoadingDelete } =
    useDeletePhotoGalleryCategoryMutation();
  const { mutateAsync: editCategory, isLoading: isLoadingEdit } =
    useEditPhotoGalleryCategoryMutation();

  const {
    showConfirmModal,
    setShowConfirmModal,
    showEditModal,
    deleteCategoryHandler,
    openEditModalHandler,
    closeEditModalHandler,
    onConfirm,
    onEdit,
    currentCategory,
  } = useCategoriesPageTable({
    category: photoGalleryCategory,
    deleteCategory: async (data) => {
      await deleteCategory(data);
      setActiveItemId(null);
    },
    editCategory: async (data) => {
      await editCategory(data);
      setActiveItemId(null);
    },
  });

  const actions: TGroupAction[] = [
    {
      title: 'Редактировать',
      icon: 'edit-02',
      action: openEditModalHandler,
    },
    {
      title: 'Удалить',
      icon: 'trash',
      action: deleteCategoryHandler,
    },
  ];

  const isLoading = isLoadingDelete || isLoadingEdit;

  const closeDeleteModalHandler = useCallback(() => {
    setActiveItemId(null);
    setShowConfirmModal(false);
  }, []);

  const closeEditModal = useCallback(() => {
    setActiveItemId(null);
    closeEditModalHandler();
  }, []);

  const confirmDeleteHandler = () => {
    onConfirm();
    setActiveItemId(null);
  };

  return (
    <>
      <CategoriesActions
        actions={actions}
        activeItemId={activeItemId}
        id={photoGalleryCategory?.id}
        isLoading={isLoading}
        setActiveItemId={setActiveItemId}
      />
      <ConfirmationModal
        isOpened={showConfirmModal}
        title="Удалить категорию?"
        onClose={closeDeleteModalHandler}
        onConfirm={confirmDeleteHandler}
      />
      <LocalizationModal
        actionSubmit={onEdit}
        closeModal={closeEditModal}
        isOpened={showEditModal}
        subtitle="Название категории"
        title="Редактирование категории"
        createInitialValue={(locale) =>
          currentCategory?.translations[locale].name ?? ''
        }
        inputProps={{
          maxLength: 255,
          placeholder: 'Введите заголовок',
          required: true,
          size: 's',
        }}
      />
    </>
  );
};
