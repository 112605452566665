import { omitExtraLocalesFromElement } from 'entities/stories/helpers';
import {
  useFetchElementsByIds,
  useFetchStoriesByIds,
  useFetchStoryGroupById,
} from 'entities/stories/queries';
import { notFalsy } from 'shared/guards';

export const useStoryEditorData = (storyGroupId: string) => {
  const {
    data: storyGroup,
    isInitialLoading: isStoryGroupLoading,
    error,
  } = useFetchStoryGroupById(storyGroupId);
  const storiesQueries = useFetchStoriesByIds(
    storyGroup ? storyGroup.stories : undefined,
  );

  const createdLocales = storyGroup?.availableLocales ?? [];

  const stories = storiesQueries.map((query) => query.data).filter(notFalsy);

  const loadings = storiesQueries.map((query) => query.isInitialLoading);
  const isStoriesInitialLoading =
    !loadings.length || loadings.some((loading) => loading);

  const elementsQueries = useFetchElementsByIds(
    stories.reduce(
      (previousValue, currentValue) => [...previousValue, ...currentValue.elements],
      [] as string[],
    ),
  );

  const storyElements = elementsQueries
    .map((query) => {
      if (createdLocales.length > 0 && query.data) {
        return omitExtraLocalesFromElement(query.data, createdLocales);
      }
      return query.data;
    })
    .filter(notFalsy);

  return {
    storyGroup,
    stories,
    storyElements,
    createdLocales,
    isStoryGroupLoading,
    isStoriesInitialLoading,
    error,
  };
};
