import { TRules } from './types';

export const emailRegExp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const loginRegExp = /^[^@]+@[^@]+$/;
export const phoneRegExp = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
export const notEmptyString = /\S$/;

export const defaultEmailRules: TRules = {
  required: 'Поле не должно быть пустым',
  pattern: {
    value: emailRegExp,
    message: 'Введите корректный email',
  },
};

export const defaultLoginRules: TRules = {
  required: 'Поле не должно быть пустым',
  pattern: {
    value: loginRegExp,
    message: 'Неверный логин или пароль',
  },
};

export const defaultRequiredRules: TRules = {
  required: 'Поле не должно быть пустым',
};

export const defaultNotEmptyRules: TRules = {
  required: 'Поле не должно быть пустым',
  pattern: {
    value: notEmptyString,
    message: 'Поле не должно быть пустым',
  },
};

export const defaultPhoneRules: TRules = {
  required: 'Please enter phone number',
  pattern: {
    value: phoneRegExp,
    message: 'Phone is invalid',
  },
};
