// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StoryHeaderPanel-module__root--SkJFn {\n  display: flex;\n  background-color: var(--bg-white);\n  justify-content: space-between;\n  align-items: center;\n  height: var(--second-header-height);\n  padding: 12px 24px;\n  border-bottom: 1px solid var(--gray-200);\n}\n\n.StoryHeaderPanel-module__nameInput--WUNbl {\n  padding: 8px 12px;\n  background-color: var(--bg-white);\n  border: 1px solid var(--gray-150);\n  display: block;\n}\n\n.StoryHeaderPanel-module__nameInput--WUNbl::-moz-placeholder {\n    text-align: center;\n  }\n\n.StoryHeaderPanel-module__nameInput--WUNbl::placeholder {\n    text-align: center;\n  }\n\n.StoryHeaderPanel-module__buttons--ReGk5 {\n  flex: 0 1 260px;\n  gap: 20px;\n  padding-left: 12px;\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/Stories/EditStory/components/StoryHeaderPanel/StoryHeaderPanel.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iCAAiC;EACjC,8BAA8B;EAC9B,mBAAmB;EACnB,mCAAmC;EACnC,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,iBAAiB;EACjB,iCAAiC;EACjC,iCAAiC;EACjC,cAAc;AAKhB;;AAHE;IACE,kBAAkB;EACpB;;AAFA;IACE,kBAAkB;EACpB;;AAGF;EACE,eAAe;EACf,SAAS;EACT,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".root {\n  display: flex;\n  background-color: var(--bg-white);\n  justify-content: space-between;\n  align-items: center;\n  height: var(--second-header-height);\n  padding: 12px 24px;\n  border-bottom: 1px solid var(--gray-200);\n}\n\n.nameInput {\n  padding: 8px 12px;\n  background-color: var(--bg-white);\n  border: 1px solid var(--gray-150);\n  display: block;\n\n  &::placeholder {\n    text-align: center;\n  }\n}\n\n.buttons {\n  flex: 0 1 260px;\n  gap: 20px;\n  padding-left: 12px;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "StoryHeaderPanel-module__root--SkJFn",
	"nameInput": "StoryHeaderPanel-module__nameInput--WUNbl",
	"buttons": "StoryHeaderPanel-module__buttons--ReGk5"
};
export default ___CSS_LOADER_EXPORT___;
