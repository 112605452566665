import { mapApiNewsPostToNewsPost } from 'entities/news/mapper/mapApiNewsPostToNewsPost';
import { TAPINewsPost } from 'entities/news/types';
import { TLangType, TMetaType } from 'shared/types/common';

type TMapNewsPostsResponseArgs = {
  data: TAPINewsPost[];
  meta?: TMetaType<true>;
  defaultLocale: TLangType;
};

export const mapNewsPostsResponse = ({
  data: news,
  meta,
  defaultLocale,
}: TMapNewsPostsResponseArgs) => {
  const page = meta!.page;

  return {
    total: page.total_entries,
    page: page.number,
    news: news.map((apiNewsPost) =>
      mapApiNewsPostToNewsPost(apiNewsPost, defaultLocale),
    ),
  };
};
