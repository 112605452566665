import cn from 'classnames';
import { TeamSelect } from 'entities/team-select/TeamSelect';
import { CountrySelect } from 'feature/country-select/CountrySelect';
import { FormLocalesInput } from 'feature/locales-input/FormLocalesInput';
import { PlayerPositionSelect } from 'feature/player-position-select/PlayerPositionSelect';
import { UploadImage } from 'feature/right-panel/ui/UploadImage/UploadImage';
import React from 'react';
import { Control } from 'react-hook-form';
import { FormInput } from 'shared/components/Form';
import { InputDatePicker } from 'shared/components/InputDatePicker/InputDatePicker';
import { Text } from 'shared/components/Text/Text';
import { imageSettings, TODAY } from 'shared/constants';
import { TPlayerFormFields } from 'widgets/player-form/types/types';
import {
  MAX_PLAYER_BIOGRAPHY_LENGTH,
  MAX_PLAYER_FIRST_NAME_LENGTH,
  MAX_PLAYER_LAST_NAME_LENGTH,
  MAX_PLAYER_NICKNAME_LENGTH,
  MAX_PLAYER_PUPIL_LENGTH,
} from 'widgets/player-form/ui/PlayerForm/validationSchema';
import { PlayerFormDiscarded } from 'widgets/player-form/ui/PlayerFormDiscarded/PlayerFormDiscarded';
import { PlayerFormPreview } from 'widgets/player-form/ui/PlayerFormPreview/PlayerFormPreview';
import { PlayerFormStatus } from 'widgets/player-form/ui/PlayerFormStatus/PlayerFormStatus';

import styles from './PlayerForm.module.css';

export const PLAYER_FORM_FROM_DATE = new Date(1970, 0);

type TProps = {
  control: Control<TPlayerFormFields>;
};

export const PlayerForm = ({ control }: TProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <Text color="var(--gray-500)" size={20} weight="SemiBold">
          Основные данные
        </Text>
        <PlayerFormPreview control={control} />
        <FormLocalesInput
          control={control}
          fieldName="firstName"
          inputClassName={cn(styles.input, styles.baseWidth)}
          label="Имя игрока"
          maxLength={MAX_PLAYER_FIRST_NAME_LENGTH}
          placeholder="Введите заголовок"
          previewInputPlaceholder="Не заполнено"
          required
          size="s"
          title="Заполнение имени"
        />
        <FormLocalesInput
          control={control}
          fieldName="lastName"
          inputClassName={cn(styles.input, styles.baseWidth)}
          label="Фамилия игрока"
          maxLength={MAX_PLAYER_LAST_NAME_LENGTH}
          placeholder="Введите заголовок"
          previewInputPlaceholder="Не заполнено"
          required
          size="s"
          title="Заполнение фамилии"
        />
        <FormInput
          control={control}
          fieldName="nickname"
          inputClassName={cn(styles.input, styles.baseWidth)}
          label="Никнейм игрока"
          maxLength={MAX_PLAYER_NICKNAME_LENGTH}
          placeholder="Не заполнено"
          showMaxLength={false}
          size="s"
        />
        <InputDatePicker
          control={control}
          fieldName="birthDate"
          fromDate={PLAYER_FORM_FROM_DATE}
          inputClassName={cn(styles.input, styles.baseWidth)}
          label="Дата рождения"
          placeholder="Выберите дату"
          toDate={TODAY}
        />
        <FormLocalesInput
          control={control}
          fieldName="biography"
          inputClassName={cn(styles.input, styles.baseWidth)}
          label="Биография игрока"
          maxLength={MAX_PLAYER_BIOGRAPHY_LENGTH}
          placeholder="Введите заголовок"
          previewInputPlaceholder="Не заполнено"
          size="s"
          title="Заполнение биографии"
          type="textarea"
        />
        <CountrySelect
          className={cn(styles.input, styles.baseWidth)}
          control={control}
          fieldName="countryId"
        />
        <div className={cn(styles.playerParams, styles.baseWidth)}>
          <FormInput
            control={control}
            fieldName="weight"
            inputClassName={styles.input}
            label="Вес"
            placeholder="Введите вес игрока"
            size="s"
            type="number"
          />
          <FormInput
            control={control}
            fieldName="height"
            inputClassName={styles.input}
            label="Рост"
            placeholder="Введите рост игрока"
            size="s"
            type="number"
          />
        </div>
        <PlayerFormStatus control={control} />
        <TeamSelect
          className={styles.baseWidth}
          control={control}
          fieldName="teamId"
        />
        <PlayerPositionSelect
          className={styles.baseWidth}
          control={control}
          fieldName="playerPositionId"
        />
        <FormInput
          control={control}
          fieldName="number"
          inputClassName={cn(styles.baseWidth, styles.input)}
          label="Номер игрока"
          placeholder="Введите номер"
          required
          size="s"
          type="number"
        />
        <FormLocalesInput
          control={control}
          fieldName="pupil"
          inputClassName={cn(styles.input, styles.baseWidth)}
          label="Воспитанник клуба"
          maxLength={MAX_PLAYER_PUPIL_LENGTH}
          placeholder="Введите заголовок"
          previewInputPlaceholder="Не заполнено"
          size="s"
          title="Заполнение воспитанника клуба"
        />
        <InputDatePicker
          control={control}
          fieldName="debutDate"
          fromDate={PLAYER_FORM_FROM_DATE}
          inputClassName={cn(styles.input, styles.baseWidth)}
          label="Дата дебюта"
          placeholder="Выберите дату"
          toDate={TODAY}
        />
        <UploadImage
          aspectRatio={imageSettings.player.picture.aspectRatio}
          control={control}
          fieldName="picture"
          hint={imageSettings.player.picture.hint}
          label="Изображение списка игроков"
          modalTitle="Настройка изображения"
          showIcon={false}
          wrapperClassName={styles.baseWidth}
        />
        <PlayerFormDiscarded control={control} />
      </div>
      <div className={styles.links}>
        <Text color="var(--gray-500)" size={20} weight="SemiBold">
          Ссылки
        </Text>
        <FormInput
          control={control}
          fieldName="shopLink"
          inputClassName={cn(styles.baseWidth, styles.input)}
          label="Ссылка на футболку игрока"
          placeholder="Вставьте ссылку"
          size="s"
        />
        <FormInput
          control={control}
          fieldName="sharingLink"
          inputClassName={cn(styles.baseWidth, styles.input)}
          label="Ссылка на страницу игрока"
          placeholder="Вставьте ссылку"
          size="s"
        />
      </div>
    </div>
  );
};
