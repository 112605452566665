import { videoApiKeys } from 'entities/video/consts';
import { mapVideoPostsResponse } from 'entities/video/mapper/mapVideoPostsResponse';
import {
  TAPIVideoPost,
  TEditVideoPostDTO,
  TVideoPostDTO,
} from 'entities/video/types';
import { del, get, patch, post } from 'shared/api/axios';
import { createPaginationParams } from 'shared/api/utils';
import { TEntityId, TLangType } from 'shared/types/common';

export type TFetchVideoPosts = {
  page: number;
  sort: 'published' | 'created_at' | '-published' | '-created_at' | undefined;
  filter?: Record<string, string>;
};

export const fetchVideoPosts = (
  { page, sort, filter }: TFetchVideoPosts,
  defaultLocale: TLangType,
) => {
  const paginationParams = createPaginationParams(page);
  const params = {
    ...paginationParams,
    ...(filter ?? {}),
    ...(sort ? { sort } : {}),
  };
  return get<TAPIVideoPost[], true>(videoApiKeys.getVideoPosts, { params }).then(
    (response) => mapVideoPostsResponse({ ...response.data, defaultLocale }),
  );
};

export const fetchVideoPost = (videoPostId: TEntityId) =>
  get<TAPIVideoPost>(videoApiKeys.getVideoPost(videoPostId)).then(
    (response) => response.data.data,
  );

export const createVideoPost = (videoPost: TVideoPostDTO) =>
  post(videoApiKeys.createVideoPost, videoPost);

export const editVideoPost = ({
  videoPost: { id, ...videoPost },
}: TEditVideoPostDTO) => patch(videoApiKeys.editVideoPost(id), videoPost);

export const deleteVideoPost = (videoId: TEntityId) =>
  del(videoApiKeys.deleteVideoPost(videoId));
