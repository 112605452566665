import cn from 'classnames';
import { Text } from 'shared/components/Text/Text';
import { EListStatus, TContentStatus } from 'shared/types/common';

import styles from './Status.module.css';

type TProps = {
  status: TContentStatus;
  containerClassName?: string;
  size?: 12 | 14;
};

const textByStatus: { [key in TProps['status']]: string } = {
  [EListStatus.Published]: 'Опубликовано',
  [EListStatus.Postponed]: 'Отложено',
  [EListStatus.Draft]: 'Черновик',
  completed: 'Завершена',
  error: 'Ошибка',
};

export const Status = ({ status, containerClassName, size = 12 }: TProps) => (
  <div className={cn(styles.container, styles[status], containerClassName)}>
    <div />
    <Text size={size}>{textByStatus[status]}</Text>
  </div>
);
