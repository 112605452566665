import { DateTimeCell } from 'pages/Stories/StoriesList/components/DateTimeCell';
import { generateMockData } from 'pages/UIKit/utils';
import { useToggle } from 'react-use';
import { Switch } from 'shared/components/Form/Switch';
import { Icon } from 'shared/components/Icon/Icon';
import { Table } from 'shared/components/SimpleTable';
import { TCommonSkeleton } from 'shared/components/Skeleton/types';
import { Status } from 'shared/components/Status';
import { Text } from 'shared/components/Text/Text';
import { toString } from 'shared/lib/toString';

import styles from '../UIKit.module.css';

const data = generateMockData(5);

const skeletonTemplate: TCommonSkeleton[] = [
  { variant: 'round', width: 32, height: 32 },
  { variant: 'default', width: 188 },
  { variant: 'default', width: 128 },
  { variant: 'default', width: 236 },
  { variant: 'default', width: 108 },
  { variant: 'default', width: 128 },
  { variant: 'default', width: 16, height: 16 },
];
export const SkeletonExample = () => {
  const [isLoading, toggleLoading] = useToggle(false);
  return (
    <div>
      <Text size={24} tag="h2">
        Skeleton
      </Text>
      <div className={styles.row} style={{ marginBottom: 16 }}>
        <Text>Switch loading</Text>
        <Switch
          checked={isLoading}
          size="l"
          value={toString(isLoading)}
          onChange={toggleLoading}
        />
      </div>
      <Table>
        <Table.Head>
          <Table.Row
            className={styles.rowGridTemplate}
            hoverable={false}
            padding="s"
          >
            <Table.Cell>
              <Text>Публикации</Text>
            </Table.Cell>
            <Table.Cell>
              <Text>Дата создания</Text>
            </Table.Cell>
            <Table.Cell>
              <Text>ID</Text>
            </Table.Cell>
            <Table.Cell>
              <Text>Статус</Text>
            </Table.Cell>
            <Table.Cell>
              <Text>Дата публикации</Text>
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {isLoading ? (
            <Table.Skeleton
              className={styles.skeletonGridTemplate}
              padding="s"
              rowsCount={5}
              skeletonTemplate={skeletonTemplate}
            ></Table.Skeleton>
          ) : (
            data.map((item) => (
              <Table.Row
                key={item.id}
                className={styles.rowGridTemplate}
                padding="s"
              >
                <Table.Cell className={styles.publicationNameColumn}>
                  <div>
                    <Icon kind="picture" size="s" />
                  </div>
                  <Text>{item.publication}</Text>
                </Table.Cell>
                <Table.Cell>
                  <DateTimeCell dateTimeString={item.dateCreated} />
                </Table.Cell>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>
                  <Status status={item.status} />
                </Table.Cell>
                <Table.Cell>
                  <DateTimeCell dateTimeString={item.datePublished} />
                </Table.Cell>
                <Table.Cell />
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </div>
  );
};
