import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { TMatch } from 'entities/matches/types';
import { MatchDatePickerCaption } from 'feature/right-panel/ui/MatchDatePicker/MatchDatePickerCaption/MatchDatePickerCaption';
import { MatchDatePickerDay } from 'feature/right-panel/ui/MatchDatePicker/MatchDatePickerDay/MatchDatePickerDay';
import React, { useMemo, useRef, useState } from 'react';
import { DateFormatter, DayPicker } from 'react-day-picker';
import defaultStyles from 'react-day-picker/dist/style.module.css';
import { Button } from 'shared/components/Button/Button';
import { OverlayModal } from 'shared/components/OverlayModal/OverlayModal';

import styles from './MatchDatePicker.module.css';

const formatWeekdayName: DateFormatter = (date) =>
  format(date, 'eeeeee', {
    locale: ru,
  });

export type TMatchDatePickerProps = {
  onChangeMatch: (match: TMatch | undefined) => void;
  onClose: () => void;
  fromDate: Date;
  maxDate: Date;
  years: number[];
  matches: TMatch[];
  selectedMatch?: TMatch;
};

const getMatchDate = (match: TMatch | undefined) =>
  match ? parseISO(match.startAt) : undefined;

export const MatchDatePicker = ({
  onChangeMatch,
  onClose,
  maxDate,
  years,
  fromDate,
  matches,
  selectedMatch: selectedMatchProp,
}: TMatchDatePickerProps) => {
  const [selectedMatch, setSelectedMatch] = useState<TMatch | undefined>(
    selectedMatchProp,
  );
  const [selected, setSelected] = useState<Date | undefined>(
    getMatchDate(selectedMatch),
  );
  const initialSelectedMatch = useRef(selectedMatch);

  const matchChange = (match: TMatch, date: Date) => {
    if (match.id === selectedMatch?.id) {
      setSelected(undefined);
      setSelectedMatch(undefined);
      return;
    }

    setSelected(date);
    setSelectedMatch(match);
  };

  const DayComponent = useMemo(
    () =>
      matches
        ? MatchDatePickerDay(matches, matchChange, selectedMatch?.id)
        : undefined,
    [matches, selectedMatch],
  );

  return (
    <OverlayModal opened>
      <div className={styles.container}>
        <DayPicker
          captionLayout="dropdown-buttons"
          className={styles.container}
          defaultMonth={selected}
          fixedWeeks
          formatters={{ formatWeekdayName }}
          fromDate={fromDate}
          mode="single"
          selected={selected}
          showOutsideDays
          toDate={maxDate}
          weekStartsOn={1}
          classNames={{
            ...defaultStyles,
            ...styles,
          }}
          components={{
            Day: DayComponent,
            Caption: ({ ...props }) => (
              <MatchDatePickerCaption maxDate={maxDate} years={years} {...props} />
            ),
          }}
        />

        <div className={styles.buttons}>
          <Button
            className={styles.footerButton}
            color="gray"
            kind="outlined"
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            className={styles.footerButton}
            color="green"
            isDisabled={initialSelectedMatch.current === selectedMatch}
            kind="primary"
            onClick={() => onChangeMatch(selectedMatch as TMatch)}
          >
            Подтвердить
          </Button>
        </div>
      </div>
    </OverlayModal>
  );
};
