import { TSettingsNavigationProps } from 'feature/settings-navigation/types';
import { SettingsNavigationItem } from 'feature/settings-navigation/ui/SettingsNavigationItem/SettingsNavigationItem';
import { Text } from 'shared/components/Text/Text';

import styles from './SettingsNavigation.module.css';

export const SettingsNavigation = ({ links }: TSettingsNavigationProps) => (
  <div className={styles.root}>
    <Text size={24} tag="h2" weight="Bold">
      Настройки
    </Text>
    {links.map((navigationItem) => (
      <SettingsNavigationItem
        key={navigationItem.name}
        navigationItem={navigationItem}
      />
    ))}
  </div>
);
