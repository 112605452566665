import { TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE } from 'shared/consts';
import { declination } from 'shared/lib/declination';
import { isValidUrl } from 'shared/lib/isValidUrl';
import { linkInWhitelist } from 'shared/lib/linkInWhitelist';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toBoolean } from 'shared/lib/toBoolean';
import { toNumber } from 'shared/lib/toNumber';
import { TLangType } from 'shared/types/common';
import z from 'zod';

const MAX_NUMBER_LENGTH = 2;
export const MAX_PLAYER_FIRST_NAME_LENGTH = 255;
export const MAX_PLAYER_LAST_NAME_LENGTH = 255;
export const MAX_PLAYER_BIOGRAPHY_LENGTH = 8000;
export const MAX_PLAYER_PUPIL_LENGTH = 1000;
export const MAX_PLAYER_NICKNAME_LENGTH = 255;
const MAX_WEIGHT = 300;
const MAX_HEIGHT = 300;
const MAX_LINK_LENGTH = 4096;

const maxLengthTextGenerate = (length: number) =>
  `Максимальная длина ${length} ${declination(length, ['символ', 'символа', 'символов'])}`;

export const playerFormValidationSchema = (
  locales: TLangType[],
  whitelistDomains: string[],
) =>
  z
    .object({
      firstName: z.object({
        ...reduceTranslationObject(locales, () =>
          z
            .string()
            .min(TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE)
            .max(
              MAX_PLAYER_FIRST_NAME_LENGTH,
              maxLengthTextGenerate(MAX_PLAYER_FIRST_NAME_LENGTH),
            ),
        ),
      }),
      lastName: z.object({
        ...reduceTranslationObject(locales, () =>
          z
            .string()
            .min(TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE)
            .max(
              MAX_PLAYER_LAST_NAME_LENGTH,
              maxLengthTextGenerate(MAX_PLAYER_LAST_NAME_LENGTH),
            ),
        ),
      }),
      nickname: z
        .string()
        .max(
          MAX_PLAYER_NICKNAME_LENGTH,
          maxLengthTextGenerate(MAX_PLAYER_NICKNAME_LENGTH),
        )
        .optional(),
      pupil: z
        .object({
          ...reduceTranslationObject(locales, () =>
            z
              .string()
              .max(
                MAX_PLAYER_PUPIL_LENGTH,
                maxLengthTextGenerate(MAX_PLAYER_PUPIL_LENGTH),
              ),
          ),
        })
        .optional(),
      biography: z
        .object({
          ...reduceTranslationObject(locales, () =>
            z
              .string()
              .max(
                MAX_PLAYER_BIOGRAPHY_LENGTH,
                maxLengthTextGenerate(MAX_PLAYER_BIOGRAPHY_LENGTH),
              ),
          ),
        })
        .optional(),
      countryId: z.string().min(TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE),
      teamId: z.string().min(TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE),
      playerPositionId: z.string().min(TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE),
      number: z
        .string()
        .min(TEXT_MIN_LENGTH, TEXT_MIN_LENGTH_MESSAGE)
        .max(MAX_NUMBER_LENGTH, maxLengthTextGenerate(MAX_NUMBER_LENGTH)),
      weight: z.string().refine((value) => {
        if (!value) {
          return true;
        }

        return toNumber(value) <= MAX_WEIGHT;
      }, `Максимальный вес игрока ${MAX_WEIGHT}`),
      height: z.string().refine((value) => {
        if (!value) {
          return true;
        }

        return toNumber(value) <= MAX_HEIGHT;
      }, `Максимальный рост игрока ${MAX_HEIGHT}`),
      discarded: z.string(),
      discardedAt: z.date().optional(),
      shopLink: z
        .string()
        .max(MAX_LINK_LENGTH, maxLengthTextGenerate(MAX_LINK_LENGTH))
        .optional()
        .refine((link) => (link ? isValidUrl(link) : true), 'Некорректная ссылка')
        .refine(
          (link) => (link ? linkInWhitelist(link, whitelistDomains) : true),
          'Данный домен не находится в списке разрешенных',
        ),
      sharingLink: z
        .string()
        .max(MAX_LINK_LENGTH, maxLengthTextGenerate(MAX_LINK_LENGTH))
        .optional()
        .refine((link) => (link ? isValidUrl(link) : true), 'Некорректная ссылка')
        .refine(
          (link) => (link ? linkInWhitelist(link, whitelistDomains) : true),
          'Данный домен не находится в списке разрешенных',
        ),
    })
    .passthrough()
    .superRefine((form, context) => {
      if (form.discarded === 'true' && !toBoolean(form.discardedAt)) {
        context.addIssue({
          code: z.ZodIssueCode.too_small,
          message: 'Поле не может быть пустым',
          path: ['discardedAt'],
          type: 'date',
          minimum: TEXT_MIN_LENGTH,
          inclusive: false,
        });
      }
    });
