import { TPlayerCareer } from 'entities/playerCareers/types';
import { TMetaType } from 'shared/types/common';

type TMapPlayerCareersResponseArgs = {
  data: TPlayerCareer[];
  meta?: TMetaType<true>;
};

export const mapPlayerCareersResponse = ({
  data: careers,
  meta,
}: TMapPlayerCareersResponseArgs) => {
  const page = meta?.page;

  return {
    total: page?.total_entries,
    page: page?.number,
    careers,
  };
};
