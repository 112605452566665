export const DNDItemTypes = {
  PARAGRAPH: 'paragraph',
  STORY_ELEMENT: 'storyElement',
  STORY_GROUP_IMAGE: 'storyGroupImage',
  LAYER_ITEM: 'layerItem',
} as const;

export const TODAY = new Date();

export const availableExtensions = ['png', 'jpg', 'jpeg', 'webp'];

export const allowedVideoFormats = ['.mp4'];

export const defaultImageRequirementText =
  'Рекомендованные требования к изображению JPEG, JPG, PNG, WebP. Максимальный размер файла 7 мб.';

export const MODAL_CLASS_NAME = 'modal-container';

export const isProduction = process.env.APP_ENV === 'production';

export enum LocalStorageKeys {
  swatches = 'swatches',
  backendSandbox = 'backendSandbox',
  token = 'token',
}

export const secureProtocol = 'https://';

export const w3 = 'www.';

export const videoFormatRegex = /\.\S{1,3}$/;

export const imageSettings = {
  stories: {
    preview: {
      aspectRatio: 1 / 1,
      hint: 'Рекомендованные требования к изображению 9:16,\n JPEG, JPG, PNG, WebP. Максимальный размер файла 7 мб.',
    },
    background: {
      aspectRatio: 9 / 16,
      hint: 'Рекомендованные требования к изображению 9:16,\n JPEG, JPG, PNG, WebP. Максимальный размер файла 7 мб.',
    },
  },
  playerCareer: {
    teamPicture: {
      aspectRatio: 1 / 1,
      hint: 'Рекомендованные требования к изображению 1:1, PNG, WebP. Максимальный размер файла 7 мб.',
    },
  },
  managerCareer: {
    teamPicture: {
      aspectRatio: 1 / 1,
      hint: 'Рекомендованные требования к изображению 1:1,\n JPEG, JPG, PNG, WebP. Максимальный размер файла 7 мб.',
    },
  },
  news: {
    preview: {
      aspectRatio: 3 / 4,
    },
    cover: {
      aspectRatio: 5 / 7,
    },
    slider: {
      aspectRatio: 9 / 16,
    },
    paragraph: {
      image: {
        aspectRatio: 6 / 7,
      },
      slider: {
        aspectRatio: 6 / 7,
      },
    },
  },
  video: {
    preview: {
      aspectRatio: 16 / 9,
    },
    importance: {
      aspectRatio: 3 / 4,
    },
  },
  player: {
    avatar: {
      aspectRatio: 9 / 16,
    },
    picture: {
      aspectRatio: 1 / 1,
      hint: 'Рекомендованные требования к изображению 1:1,\nJPEG, JPG, PNG, WebP. Максимальный размер файла 7 мб.',
    },
  },
  team: {
    picture: {
      aspectRatio: 1 / 1,
      hint: 'Рекомендованные требования к изображению 1:1,\n JPEG, JPG, PNG, WebP. Максимальный размер файла 7 мб.',
    },
  },
  manager: {
    avatar: {
      aspectRatio: 9 / 16,
    },
    picture: {
      aspectRatio: 1 / 1,
      hint: 'Рекомендованные требования к изображению 1:1,\nJPEG, JPG, PNG, WebP. Максимальный размер файла 7 мб.',
    },
  },
};
