import { notify } from 'shared/components/Notification';
import { useValidVideoLink } from 'shared/components/VideoPlayer/helpers';
import { allowedVideoFormats } from 'shared/constants';

import { IframePlayerElement } from './components/IframePlayerElement/IframePlayerElement';
import { VideoPlayerElement } from './components/VideoPlayerElement/VideoPlayerElement';
import { TDefaultVideoPlayerProps } from './types';

type TProps = TDefaultVideoPlayerProps & {
  hosting: string;
};

export const VideoPlayer = ({ src, hosting, width }: TProps) => {
  const { isVideoFile, isAllowedVideoFormat } = useValidVideoLink(src);
  if (!isVideoFile) {
    return <IframePlayerElement hosting={hosting} src={src} width={width} />;
  }
  if (isAllowedVideoFormat) {
    return <VideoPlayerElement src={src} width={width} />;
  } else {
    notify(`Список поддерживаемых форматов: ${allowedVideoFormats.join(', ')}`, {
      title: 'Указанный формат не соответствует поддерживаемым форматам',
      type: 'error',
    });
    // TODO: добавить картинку-заглушку для неподдерживаемого формата видео
    return <></>;
  }
};
