import { useFetchLabelsQuery } from 'entities/labels/queries';
import { useFetchVideoCategoriesQuery } from 'entities/videoCategories/queries';
import { useRightPanelLabel } from 'feature/right-panel/hooks/useRightPanelLabel';
import { TDefaultRightPanelProps } from 'feature/right-panel/types';
import { CommonLabel } from 'feature/right-panel/ui/CommonLabel/CommonLabel';
import { useController, useFormContext } from 'react-hook-form';
import { TVideoPostFields } from 'widgets/video-form/types';

export const VideoLabel = (commonProps: TDefaultRightPanelProps) => {
  const { control } = useFormContext<TVideoPostFields>();
  const { data: labelsData } = useFetchLabelsQuery({ pageSize: 100 });
  const { data: categoriesData } = useFetchVideoCategoriesQuery({ pageSize: 100 });

  const { field: selectedLabels } = useController({
    control,
    name: 'labels',
  });

  const { field: selectedCategories } = useController({
    control,
    name: 'categories',
  });

  const { labels, categories } = useRightPanelLabel<TVideoPostFields>({
    labels: labelsData?.categories,
    labelsFieldName: 'labels',
    categoriesFieldName: 'categories',
    categories: categoriesData?.categories,
    selectedLabelsCount: selectedLabels.value.length,
    selectedCategoriesCount: selectedCategories.value.length,
  });

  return (
    <CommonLabel
      categories={categories}
      control={control}
      description="“Лейбл” будет отображаться на видео. После выбора определенной “Категории”, видео будет отображаться в соответствующем списке."
      labels={labels}
      {...commonProps}
    />
  );
};
