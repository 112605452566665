import { getSuccessStoryPublicationNotify } from 'entities/stories/helpers';
import { useUpdateStoryGroupMutation } from 'entities/stories/mutations';
import isEmpty from 'lodash/isEmpty';
import { PreviewSelect } from 'pages/Stories/EditStory/components/PreviewSelect';
import { SaveStoryModal } from 'pages/Stories/EditStory/components/SaveStoryModal/SaveStoryModal';
import { StoryHeaderLocalesControl } from 'pages/Stories/EditStory/components/StoryHeaderPanel/components/StoryHeaderLocalesControl';
import { ModalContext } from 'pages/Stories/EditStory/context';
import { useStoryEditorContext } from 'pages/Stories/EditStory/hooks/useStoryEditorContext';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/components/Button/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { routes } from 'shared/routes';
import { TLangType } from 'shared/types/common';

import styles from './StoryHeaderPanel.module.css';

export type TFormValues = {
  publicationTime?: Date;
};

type TProps = {
  selectedStoryGroupId: string;
  selectedLocale: TLangType;
  createdLocales: TLangType[];
};

export const StoryHeaderPanel = ({
  selectedStoryGroupId,
  selectedLocale,
  createdLocales,
}: TProps) => {
  const navigate = useNavigate();
  const { elementsWithErrors } = useStoryEditorContext();
  const { openedModal, openModal } = useContext(ModalContext);
  const { mutateAsync: publishStoryGroup } = useUpdateStoryGroupMutation();

  const [isClosing, setIsClosing] = useState(false);
  const formMethods = useForm<TFormValues>({
    mode: 'onChange',
  });

  const handleBackButtonClick = () => {
    setIsClosing(true);
  };

  const handleBackButtonClose = () => {
    setIsClosing(false);
  };

  const handleSaveStory = () => {
    openModal?.('saveStory');
  };

  const handleCloseModal = () => {
    openModal?.(null);
  };

  const handlePublish = (date?: Date) => {
    if (date) {
      publishStoryGroup({
        id: selectedStoryGroupId,
        availableLocales: createdLocales,
        published: date.toString(),
      }).then((result) => {
        getSuccessStoryPublicationNotify(result);
        navigate(routes.stories);
      });
    }
    handleCloseModal();
  };

  return (
    <FormProvider {...formMethods}>
      <div className={styles.root}>
        <div className={styles.buttons}>
          <IconButton
            icon="chevron-left"
            onClick={handleBackButtonClick}
          ></IconButton>
        </div>
        <StoryHeaderLocalesControl selectedLocale={selectedLocale} />
        <div className={styles.buttons}>
          <PreviewSelect />
          <Button
            isDisabled={!isEmpty(elementsWithErrors)}
            onClick={handleSaveStory}
          >
            Подтвердить
          </Button>
        </div>
        <SaveStoryModal
          createdLocales={createdLocales}
          isOpened={openedModal === 'saveStory'}
          selectedStoryGroupId={selectedStoryGroupId}
          onClose={handleCloseModal}
          onPublishNow={handlePublish}
        />
        <ConfirmationModal
          description="Когда вы вернетесь назад, весь контент внутри рабочей области будет сохранен в черновик."
          isOpened={isClosing}
          title="Вернуться на главный экран?"
          onClose={handleBackButtonClose}
          onConfirm={() => navigate(routes.stories)}
        />
        <DatePicker
          isOpened={openedModal === 'datePicker'}
          onClose={handleSaveStory}
          onDateChange={handlePublish}
        />
      </div>
    </FormProvider>
  );
};
