import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import iphone13mockup from 'shared/assets/images/iphone-13-mockup.png';
import defaultElementsStyles from 'shared/components/Editor/elements/editorElementsStyle.module.css';
import { EditorElementTypeEnum } from 'shared/components/Editor/types';
import { Icon } from 'shared/components/Icon/Icon';
import { AddNewPostPreviewVideoElement } from 'shared/components/NewsPostForm/components/AddNewsPostPreview/AddNewsPostPreviewElements/AddNewPostPreviewVideoElement';
import { AddNewsPostPreviewImageElement } from 'shared/components/NewsPostForm/components/AddNewsPostPreview/AddNewsPostPreviewElements/AddNewsPostPreviewImageElement';
import { AddNewsPostPreviewParagraphElement } from 'shared/components/NewsPostForm/components/AddNewsPostPreview/AddNewsPostPreviewElements/AddNewsPostPreviewParagraphElement';
import { AddNewsPostPreviewSliderElement } from 'shared/components/NewsPostForm/components/AddNewsPostPreview/AddNewsPostPreviewElements/AddNewsPostPreviewSliderElement';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { Text } from 'shared/components/Text/Text';

import styles from './AddNewsPostPreview.module.css';

const elementMapper = {
  [EditorElementTypeEnum.Paragraph]: AddNewsPostPreviewParagraphElement,
  [EditorElementTypeEnum.Image]: AddNewsPostPreviewImageElement,
  [EditorElementTypeEnum.Slider]: AddNewsPostPreviewSliderElement,
  [EditorElementTypeEnum.Video]: AddNewPostPreviewVideoElement,
};

export const AddNewsPostPreviewContent = () => {
  const { getValues } = useFormContext<TEditNewsPostFields>();
  const {
    content: { preview, title, body },
    locale: { selected: selectedLocale },
  } = getValues();
  const filePreview = preview.translations[selectedLocale].data;

  return (
    <>
      <img alt="iphone 13 mockup" className={styles.mockup} src={iphone13mockup} />
      <div className={styles.content}>
        {filePreview ? (
          <img alt="news preview image" src={filePreview} />
        ) : (
          <div className={styles.dummyPreview}>
            <Icon kind="image-03" size="xl" />
          </div>
        )}
        <div className={cn(styles.body, defaultElementsStyles.root)}>
          <Text className={styles.title} size={12} weight="SemiBold">
            {title.translations[selectedLocale].title || 'Тут будет заголовок'}
          </Text>
          {body.map((element, index) => {
            const Element = elementMapper[element.type];

            return <Element key={index} element={element} lang={selectedLocale} />;
          })}
        </div>
      </div>
    </>
  );
};
