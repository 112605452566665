import cn from 'classnames';
import { useLocales } from 'entities/config/lib/useLocales';
import React from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { Text } from 'shared/components/Text/Text';
import { UploadAvatar } from 'shared/components/UploadAvatar/UploadAvatar';
import { imageSettings } from 'shared/constants';
import { TPlayerFormFields } from 'widgets/player-form/types/types';
import styles from 'widgets/player-form/ui/PlayerForm/PlayerForm.module.css';

type TProps = {
  control: Control<TPlayerFormFields>;
};

export const PlayerFormPreview = ({ control }: TProps) => {
  const { defaultLocale } = useLocales();
  const firstName = useWatch({
    control,
    name: 'firstName',
  });
  const lastName = useWatch({
    control,
    name: 'lastName',
  });
  const id = useWatch({
    control,
    name: 'id',
  });

  return (
    <div className={cn(styles.preview, styles.baseWidth)}>
      <Controller
        control={control}
        name="photo"
        render={({ field }) => (
          <UploadAvatar
            aspectRatio={imageSettings.player.avatar.aspectRatio}
            avatar={field.value}
            onLoadAvatar={field.onChange}
          />
        )}
      />
      <div className={styles.info}>
        <div className={styles.name}>
          <Text color="var(--gray-550)" size={16} weight="SemiBold">
            {firstName[defaultLocale] || 'Имя'}
          </Text>
          <Text color="var(--gray-550)" size={16} weight="SemiBold">
            {lastName[defaultLocale] || 'Фамилия'}
          </Text>
        </div>
        {id ? <Text color="var(--gray-450)">ID: {id}</Text> : null}
      </div>
    </div>
  );
};
