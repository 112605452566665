import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchSocialNetworksQuery } from 'entities/socialNetworks/queries';
import { SocialNetworkLabel } from 'feature/social-network-modal/ui/SocialNetworkLabel/SocialNetworkLabel';
import { useMemo } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { FormSelect } from 'shared/components/Form/Select/FormSelect';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { toString } from 'shared/lib/toString';
import { compareIds } from 'shared/utils/entityIds';

import styles from './SocialNetworkSelect.module.css';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
};

export const SocialNetworkSelect = <T extends FieldValues>({
  control,
  fieldName,
}: TProps<T>) => {
  const { defaultLocale } = useLocales();
  const {
    field: { value: selectedSocialNetworkId },
  } = useController({
    control,
    name: fieldName,
  });

  const { data: socialNetworks } = useFetchSocialNetworksQuery();

  const socialNetworkOptions = useMemo(
    () =>
      socialNetworks?.map<TSelectOption>((socialNetwork) => ({
        value: toString(socialNetwork.id),
        label: socialNetwork.translations[defaultLocale].name,
        renderLabel: (label: string) => (
          <SocialNetworkLabel image={socialNetwork.picture.x3} label={label} />
        ),
      })) ?? [],
    [socialNetworks],
  );

  const socialNetworkPlaceholder = useMemo(() => {
    if (selectedSocialNetworkId && socialNetworks?.length) {
      const selectedSocialNetwork = socialNetworks.find((c) =>
        compareIds(c.id, selectedSocialNetworkId),
      );

      return (
        <SocialNetworkLabel
          key={selectedSocialNetwork!.id}
          image={selectedSocialNetwork!.picture.x3}
          label={toString(selectedSocialNetwork!.translations[defaultLocale].name)}
        />
      );
    }

    return 'Выберите иконку';
  }, [selectedSocialNetworkId, socialNetworks]);

  return (
    <FormSelect
      control={control}
      fieldName={fieldName}
      label="Иконка ссылки"
      multiple={false}
      options={socialNetworkOptions}
      optionsContainerClassName={styles.optionsContainer}
      placeholder={socialNetworkPlaceholder}
      required
    />
  );
};
