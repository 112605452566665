import cn from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Alert } from 'shared/components/Alert/Alert';
import { Button } from 'shared/components/Button/Button';
import { IconFactory } from 'shared/components/Icon/IconFactory';
import { getUploadImageModalErrorMessage } from 'shared/components/ImageUploadModal/helpers';
import { Text } from 'shared/components/Text/Text';
import { defaultImageRequirementText } from 'shared/constants';
import { MAX_FILE_SIZE } from 'shared/consts';

import checkVerified from './images/check-verified.svg';
import fileAttachment from './images/file-attachment.svg';
import styles from './ImageUploadModal.module.css';

const FileAttachmentIcon = IconFactory(fileAttachment);
const CheckVerifiedIcon = IconFactory(checkVerified);

export type TImageUploadProps = {
  multiple?: boolean;
  hint?: string;
  files: File[];
  setFiles: (files: File[]) => void;
  className?: string;
};

export const ImageUpload = ({
  multiple = false,
  hint,
  setFiles,
  files,
  className,
}: TImageUploadProps) => {
  const [error, setError] = useState(false);
  const fileNameLabel = multiple ? 'Файлы' : 'Файл';
  const haveFiles = files.length !== 0;
  const maxFiles = multiple ? 10 : 1;

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length === 0) {
        setFiles(acceptedFiles);
      } else {
        setError(true);
      }
    },
    [files],
  );

  const { getRootProps, getInputProps, open, isDragActive, fileRejections } =
    useDropzone({
      accept: {
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/webp': ['.webp'],
      },
      noClick: true,
      noKeyboard: true,
      noDragEventsBubbling: true,
      maxFiles,
      maxSize: MAX_FILE_SIZE,
      onDrop,
    });

  const errorMessage = useMemo(
    () => getUploadImageModalErrorMessage(fileRejections, maxFiles),
    [fileRejections],
  );

  const renderHintText = () => {
    if (!haveFiles) {
      return hint || defaultImageRequirementText;
    }

    if (!multiple) {
      return files?.[0]?.name;
    }

    return '';
  };

  return (
    <>
      {errorMessage && error && (
        <Alert
          icon="alert-triangle"
          message={errorMessage}
          title="Произошла ошибка"
          variant="red"
        />
      )}
      <div
        {...getRootProps({
          className: cn(styles.dropzone, className, {
            [styles.uploaderActive]: isDragActive || haveFiles,
            [styles.error]: errorMessage && error,
          }),
        })}
      >
        {haveFiles && (
          <>
            <CheckVerifiedIcon className={styles.icon} />
            <Text className={styles.mainText} size={14}>
              {fileNameLabel} загружен{multiple && 'ы'}
            </Text>
          </>
        )}
        {!haveFiles && (
          <>
            <input {...getInputProps()} />
            <FileAttachmentIcon
              className={styles.icon}
              style={{ minHeight: '44px' }}
            />
            <div>
              <Text className={styles.mainText} size={14}>
                Перетащите {fileNameLabel} или{' '}
              </Text>
              <Button kind="basic" onClick={open}>
                {`Выберите ${fileNameLabel}`}
              </Button>
              <Text className={styles.mainText} size={14}>
                {' '}
                для загрузки
              </Text>
            </div>
          </>
        )}
        <Text className={styles.hintText}>{renderHintText()}</Text>
      </div>
    </>
  );
};
