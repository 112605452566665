import cn from 'classnames';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { Field } from 'shared/components/Form';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { InputDatePicker } from 'shared/components/InputDatePicker/InputDatePicker';
import { TODAY } from 'shared/constants';
import { TPlayerFormFields } from 'widgets/player-form/types/types';
import { PLAYER_FORM_FROM_DATE } from 'widgets/player-form/ui/PlayerForm/PlayerForm';
import styles from 'widgets/player-form/ui/PlayerForm/PlayerForm.module.css';

type TProps = {
  control: Control<TPlayerFormFields>;
};

const discardedStatusLabels: Record<string, string> = {
  false: 'В команде',
  true: 'Покинул команду',
};

export const PlayerFormDiscarded = ({ control }: TProps) => {
  const { field } = useController({
    control,
    name: 'discarded',
  });

  return (
    <>
      <Field label="Принадлежность к команде">
        <div className={styles.status}>
          {Object.keys(discardedStatusLabels).map((discardedStatus) => (
            <RadioButton
              key={discardedStatus}
              checked={field.value === discardedStatus}
              label={discardedStatusLabels[discardedStatus]}
              name={field.name}
              size="s"
              value={discardedStatus}
              onChange={() => {
                field.onChange(discardedStatus);
              }}
            />
          ))}
        </div>
      </Field>
      <InputDatePicker
        control={control}
        disabled={field.value === 'false'}
        fieldName="discardedAt"
        fromDate={PLAYER_FORM_FROM_DATE}
        inputClassName={cn(styles.input, styles.baseWidth)}
        label="Дата увольнения игрока из команды"
        placeholder="Выберите дату"
        required
        toDate={TODAY}
      />
    </>
  );
};
