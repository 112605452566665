import { managerCareersApiKeys } from 'entities/managerCareers/api/consts';
import { mapManagerCareersResponse } from 'entities/managerCareers/mapper/mapManagerCareersResponse';
import { TManagerCareer } from 'entities/managerCareers/model/types';
import { del, get } from 'shared/api/axios';
import { createPaginationParams } from 'shared/api/utils';
import { TEntityId } from 'shared/types/common';

export type TFetchManagerCareersParams = {
  page: number;
  filter?: Record<string, string>;
};

export const fetchManagerCareers = ({
  page,
  filter = {},
}: TFetchManagerCareersParams) =>
  get<TManagerCareer[], true>(managerCareersApiKeys.getManagerCareers, {
    params: {
      ...createPaginationParams(page),
      ...filter,
    },
  }).then((response) => mapManagerCareersResponse({ ...response.data }));

export const deleteManagerCareer = (careerId: TEntityId) =>
  del(managerCareersApiKeys.deleteCareer(careerId)).then(
    (response) => response.data.data,
  );
