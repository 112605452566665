import { TPhotoGalleryCategory } from 'entities/photoGalleryCategories/types';
import { TLangType, TMetaType } from 'shared/types/common';

type TMapPhotoGalleryResponseArgs = {
  data: TPhotoGalleryCategory[];
  meta?: TMetaType<true>;
  defaultLocale: TLangType;
};

export const mapPhotoGalleryCategoriesResponse = ({
  data: categories,
  meta,
}: TMapPhotoGalleryResponseArgs) => {
  const page = meta?.page;

  return {
    total: page?.total_entries,
    page: page?.number,
    categories,
  };
};
