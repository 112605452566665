import { useLocales } from 'entities/config/lib/useLocales';
import { useMemo } from 'react';
import { FieldValues, Path } from 'react-hook-form';

import { TCommonOptionType, TSelectFieldProps } from '../types';
import { generateOptionsArray, generatePlaceholder } from '../utils';

type TUseRightPanelLabel<T extends FieldValues> = {
  labels?: TCommonOptionType[];
  categories?: TCommonOptionType[];
  labelsFieldName: Path<T>;
  categoriesFieldName: Path<T>;
  selectedLabelsCount?: number;
  selectedCategoriesCount?: number;
};

type TUseRightPanelLabelReturn<T extends FieldValues> = {
  labels: TSelectFieldProps<T>;
  categories: TSelectFieldProps<T>;
};

export const useRightPanelLabel = <T extends FieldValues>({
  labels,
  categories,
  labelsFieldName,
  categoriesFieldName,
  selectedLabelsCount,
  selectedCategoriesCount,
}: TUseRightPanelLabel<T>): TUseRightPanelLabelReturn<T> => {
  const { defaultLocale } = useLocales();

  const labelsForSelect = useMemo(
    () => generateOptionsArray(defaultLocale, labels),
    [labels],
  );

  const categoriesForSelect = useMemo(
    () => generateOptionsArray(defaultLocale, categories),
    [categories],
  );

  const labelsPlaceholder = generatePlaceholder({
    count: selectedLabelsCount,
    singular: 'лейбл',
    few: 'лейбла',
    many: 'лейблов',
    defaultPlaceholder: 'Выбрать лейблы из списка',
  });

  const categoriesPlaceholder = generatePlaceholder({
    count: selectedCategoriesCount,
    singular: 'категория',
    few: 'категории',
    many: 'категорий',
    defaultPlaceholder: 'Выбрать категории из списка',
  });

  return {
    labels: {
      data: labelsForSelect,
      fieldName: labelsFieldName,
      placeholder: labelsPlaceholder,
    },
    categories: {
      data: categoriesForSelect,
      fieldName: categoriesFieldName,
      placeholder: categoriesPlaceholder,
    },
  };
};
