import { TStoryElementData } from 'entities/stories/types';
import { TElementProps } from 'pages/Stories/EditStory/types';
import { renderElement } from 'pages/Stories/EditStory/utils';
import { CSSProperties, useRef } from 'react';
import { TLangType } from 'shared/types/common';

type TProps = {
  element: TStoryElementData;
  selectedLocale: TLangType;
};

export const PreviewElement = ({ element, selectedLocale }: TProps) => {
  const {
    id,
    kind,
    translations: {
      [selectedLocale]: { elementPosition, settings },
    },
  } = element;
  const ref = useRef<HTMLDivElement>(null);
  const elementProps: TElementProps<typeof kind> = {
    id,
    isPreview: true,
    isSelected: false,
    settings,
  };
  const elementComponent = renderElement(kind, elementProps);
  const top = elementPosition ? elementPosition.top : 0;
  const left = elementPosition ? elementPosition.left : 0;
  const right = elementPosition ? elementPosition.right : 0;
  const bottom = elementPosition ? elementPosition.bottom : 0;
  const styles: CSSProperties = {
    position: 'absolute',
    top: `${top}%`,
    left: `${left}%`,
    right: `${right}%`,
    bottom: `${bottom}%`,
    display: 'inline-block',
    zIndex: element.layer,
  };

  return (
    <div key={id} ref={ref} style={styles}>
      {elementComponent}
    </div>
  );
};
