import cn from 'classnames';
import { TEXT_ELEMENT_MAX_LENGTH } from 'pages/Stories/EditStory/components/elements/constants';
import { TextElementPreview } from 'pages/Stories/EditStory/components/elements/TextElement/TextElementPreview';
import { fontStyleMap } from 'pages/Stories/EditStory/components/forms/constants';
import { useStoryEditorContext } from 'pages/Stories/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/Stories/EditStory/hooks/useStoryEditorDispatchContext';
import { TElementProps } from 'pages/Stories/EditStory/types';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import TextAreaAutosize from 'react-textarea-autosize';
import { useDebounce } from 'react-use';

import styles from './TextElement.module.css';

export const TextElement = ({ id, settings, isPreview }: TElementProps<'text'>) => {
  const [val, setVal] = useState('');
  const { selectedElement } = useStoryEditorContext();
  const dispatch = useStoryEditorDispatchContext();
  const isSelected = id === selectedElement?.id;
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isSelected && ref.current) {
      ref.current.focus();
    }
  }, [isSelected]);
  useDebounce(
    () => {
      if (isPreview) {
        return;
      }
      dispatch({
        type: 'updateSelectedStoryElementSettings',
        payload: { text: val },
      });
    },
    300,
    [val],
  );

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > TEXT_ELEMENT_MAX_LENGTH) {
      setVal(event.target.value.slice(0, TEXT_ELEMENT_MAX_LENGTH - 1));
    }
    setVal(event.target.value);
  };
  const { text, fontSize, fill, background, textAlign, fontFamily, fontStyle } =
    settings;

  useEffect(() => {
    setVal(text);
  }, [text]);

  const fontStyles = fontStyleMap[fontStyle];

  const style = {
    fontFamily,
    fontSize,
    color: fill,
    backgroundColor: background,
    textAlign,
    ...fontStyles,
  };

  return isPreview ? (
    <TextElementPreview initialFontSize={fontSize} style={style} text={val} />
  ) : (
    <TextAreaAutosize
      className={cn(styles.interactiveText, { [styles.disabled]: !isSelected })}
      disabled={!isSelected}
      maxLength={TEXT_ELEMENT_MAX_LENGTH}
      minRows={1}
      ref={ref}
      style={style}
      value={val}
      onChange={handleChange}
    />
  );
};
