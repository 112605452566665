import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Button } from 'shared/components/Button/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { useEditNewsPost } from 'shared/components/NewsPostForm/hooks/useEditNewsPost';
import { useIsEditNewsPost } from 'shared/components/NewsPostForm/hooks/useIsEditNewsPost';
import { useIsPublishNewsPost } from 'shared/components/NewsPostForm/hooks/useIsPublishNewsPost';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';

export const AddNewsPostUnPublishButton = () => {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const { formState, control, handleSubmit } = useFormContext<TEditNewsPostFields>();
  const { field } = useController({
    control,
    name: 'publishDate',
  });
  const editNewsPost = useEditNewsPost();

  const isEdit = useIsEditNewsPost();
  const isPublish = useIsPublishNewsPost();

  if (!isEdit || !isPublish) {
    return null;
  }

  const onUnPublish = () => {
    setIsOpenConfirmModal(true);
  };

  const onConfirm = () => {
    setIsOpenConfirmModal(false);
    field.onChange(undefined);
    handleSubmit(editNewsPost)();
  };

  return (
    <>
      <Button
        color="gray"
        isDisabled={formState.isSubmitting}
        kind="outlined"
        onClick={onUnPublish}
      >
        Снять с публикации
      </Button>
      <ConfirmationModal
        description='После снятия публикации, ей будет присвоен статус "Черновик"'
        isOpened={isOpenConfirmModal}
        title="Снять с публикации?"
        onClose={() => setIsOpenConfirmModal(false)}
        onConfirm={onConfirm}
      />
    </>
  );
};
