import { useLogout } from 'feature/auth/model/useLogout';
import { useProtectedSettingsLinks } from 'feature/settings-navigation/useProtectedSettingsLinks';
import { Link } from 'react-router-dom';
import { API_LIST } from 'shared/api/constants';
import { Icon } from 'shared/components/Icon/Icon';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Text } from 'shared/components/Text/Text';
import { isProduction, LocalStorageKeys } from 'shared/constants';
import { useProtectedLinks } from 'shared/hooks/useProtectedLinks';
import { toBoolean } from 'shared/lib/toBoolean';
import { routes } from 'shared/routes';
import logo from 'widgets/navigation/assets/logo.svg';
import { NavigationLink } from 'widgets/navigation/NavigationLink/NavigationLink';
import { navigationLinks } from 'widgets/navigation/navigationLinks';

import styles from './Navigation.module.css';

const getBackendName = () => {
  const savedBackend = API_LIST.find(
    (item) => item.value === localStorage.getItem(LocalStorageKeys.backendSandbox),
  );
  if (!savedBackend) {
    return;
  }

  return savedBackend.label;
};

export const Navigation = () => {
  const getProtectedLinks = useProtectedLinks();
  const { links: settingsLinks } = useProtectedSettingsLinks();

  const links = getProtectedLinks(navigationLinks).map((link) => (
    <NavigationLink key={link.name} link={link} />
  ));

  const logout = useLogout();

  const canOpenSettings = toBoolean(settingsLinks.length);

  return (
    <div className={styles.root}>
      <Link className={styles.logo} to={routes.root}>
        <img alt="logo" src={logo} />
      </Link>
      <div className={styles.links}>{links}</div>
      <div className={styles.leftWrapper}>
        {!isProduction && <Text className={styles.sandbox}>{getBackendName()}</Text>}
        {canOpenSettings && (
          <Link to={routes.settings}>
            <Icon className={styles.iconSettings} kind="settings-01" />
          </Link>
        )}
        <IconButton className={styles.user} icon="logout" onClick={logout} />
      </div>
    </div>
  );
};
