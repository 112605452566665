import { TLabel } from 'entities/labels/types';
import { LabelActions } from 'feature/labels/LabelActions/LabelActions';
import React from 'react';
import { Text } from 'shared/components/Text/Text';
import { TConfigCreator } from 'widgets/elements-list-table/types';

import styles from './LabelsPage.module.css';

export const getLabelsColumnsConfig: TConfigCreator<TLabel> = (
  defaultLocale,
  activeLabelId,
  setActiveLabelId,
) => [
  {
    title: 'Названия',
    cellClassName: styles.publicationNameColumn,
    render: (item) => (
      <Text className={styles.text}>{item?.translations[defaultLocale]?.name}</Text>
    ),
  },
  {
    title: '',
    render: (item) => (
      <LabelActions
        activeLabelId={activeLabelId}
        label={item}
        setActiveLabelId={setActiveLabelId}
      />
    ),
  },
];
