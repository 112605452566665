import { useQuery } from '@tanstack/react-query';
import {
  fetchManagerSocialNetworks,
  TFetchManagerSocialNetworksParams,
} from 'entities/managerSocialNetworks/api';
import { managerSocialNetworksQueryKeys } from 'entities/managerSocialNetworks/consts';

export const useFetchManagerSocialNetworksQuery = ({
  page = 1,
  filter,
}: Partial<TFetchManagerSocialNetworksParams>) => {
  return useQuery({
    queryKey: [managerSocialNetworksQueryKeys.managerSocialNetworks, page, filter],
    queryFn: () => fetchManagerSocialNetworks({ page, filter }),
    cacheTime: 0,
  });
};
