// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuizElementForm-module__addButton--_oby8 {\n  color: #1a3f6f;\n  border-color: #b4b8bb;\n}\n\n  .QuizElementForm-module__addButton--_oby8 span {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n.QuizElementForm-module__deleteQuestionButton--LTqGz {\n  margin-top: 20px; /* высота label + gap */\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/Stories/EditStory/components/forms/QuizElementForm/QuizElementForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qBAAqB;AAOvB;;EALE;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;AAGF;EACE,gBAAgB,EAAE,uBAAuB;AAC3C","sourcesContent":[".addButton {\n  color: #1a3f6f;\n  border-color: #b4b8bb;\n\n  span {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n}\n\n.deleteQuestionButton {\n  margin-top: 20px; /* высота label + gap */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButton": "QuizElementForm-module__addButton--_oby8",
	"deleteQuestionButton": "QuizElementForm-module__deleteQuestionButton--LTqGz"
};
export default ___CSS_LOADER_EXPORT___;
