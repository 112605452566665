import { TAPIError } from 'shared/api/types';
import { defaultErrorHandler } from 'shared/api/utils';
import { notify } from 'shared/components/Notification';

export const authErrorHandler = (reason: TAPIError) => {
  // TODO: This case only for QA acceptance. Error handling should be improved: [WEB-1011]
  if (reason.response?.status === 401) {
    notify('Проверьте введённые данные', {
      type: 'error',
      title: 'Неверный логин или пароль',
    });
  } else {
    defaultErrorHandler(reason);
  }
};
