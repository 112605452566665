import { zodResolver } from '@hookform/resolvers/zod';
import { useConfig } from 'entities/config/lib/useConfig';
import { useLocales } from 'entities/config/lib/useLocales';
import { EPlayerStatus } from 'entities/players/model/types';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TLangType } from 'shared/types/common';
import { TPlayerFormFields } from 'widgets/player-form/types/types';
import { playerFormValidationSchema } from 'widgets/player-form/ui/PlayerForm/validationSchema';

export type TPlayerFormProps = {
  initialValues?: TPlayerFormFields;
};

const generateDefaultValues = (
  locales: TLangType[],
  teamId: string,
): TPlayerFormFields => ({
  firstName: reduceTranslationObject(locales, () => ''),
  lastName: reduceTranslationObject(locales, () => ''),
  countryId: '',
  number: '',
  weight: '',
  height: '',
  teamId,
  playerPositionId: '',
  status: EPlayerStatus.PartOfTeam,
  discarded: 'false',
});

export const usePlayerForm = ({ initialValues }: TPlayerFormProps = {}) => {
  const { teamId } = useParams<{
    teamId: string;
  }>();
  const { config } = useConfig();
  const { locales } = useLocales();

  return useForm<TPlayerFormFields>({
    values: initialValues || generateDefaultValues(locales, teamId ?? ''),
    mode: 'onBlur',
    resolver: zodResolver(
      playerFormValidationSchema(locales, config!.whitelistDomains),
    ),
  });
};
