import { ManagerInformation } from 'shared/components/Card/CardInformationPart/ManagerInformation';
import { PlayerInformation } from 'shared/components/Card/CardInformationPart/PlayerInformation';
import { TeamInformation } from 'shared/components/Card/CardInformationPart/TeamInformation';
import { TCardInformation } from 'shared/components/Card/types';

const elementMapper = {
  player: PlayerInformation,
  manager: ManagerInformation,
  team: TeamInformation,
};

export const CardInformation = (props: TCardInformation) =>
  elementMapper[props.type](props);
