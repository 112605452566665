import { TPlayerSocialNetwork } from 'entities/playerSocialNetworks/types';
import { TMetaType } from 'shared/types/common';

type TMapPlayerSocialNetworksResponseArgs = {
  data: TPlayerSocialNetwork[];
  meta?: TMetaType<true>;
};

export const mapPlayerSocialNetworksResponse = ({
  data: socialLinks,
  meta,
}: TMapPlayerSocialNetworksResponseArgs) => {
  const page = meta!.page;

  return {
    total: page.total_entries,
    page: page.number,
    socialLinks,
  };
};
