import { noop } from 'lodash';
import { useDeleteManagerTeamAchievement } from 'pages/manager/ManagerTeamAchievementsPage/hooks/useDeleteManagerTeamAchievement';
import { TManagerTeamAchievementsActions } from 'pages/manager/ManagerTeamAchievementsPage/types';
import { useMemo } from 'react';
import { useBoolean } from 'react-use';
import { TGroupAction } from 'shared/types/common';

export const useManagerTeamAchievementsActions = ({
  managerTeamAchievement,
  setActiveItemId,
  activeItemId,
}: TManagerTeamAchievementsActions) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useBoolean(false);
  const { isDeletingManagerTeamAchievement, deleteManagerTeamAchievement } =
    useDeleteManagerTeamAchievement();

  const { id } = managerTeamAchievement;

  const isActive = id === activeItemId;

  const actions: TGroupAction[] = useMemo(
    () => [
      {
        title: 'Редактировать',
        icon: 'edit-02',
        action: noop,
      },
      {
        title: 'Удалить',
        icon: 'trash',
        action: () => setIsDeleteModalOpen(true),
      },
    ],
    [],
  );

  const handleActionsButtonClick = () => {
    setActiveItemId(isActive ? null : id);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setActiveItemId(null);
  };

  const handleConfirmDeletion = () => {
    handleCloseDeleteModal();
    deleteManagerTeamAchievement(id);
  };

  return {
    actionsProps: {
      actions,
      activeItemId,
      isLoading: isDeletingManagerTeamAchievement,
      isOpen: isActive,
      onIconClick: handleActionsButtonClick,
    },
    deleteModalProps: {
      onClose: handleCloseDeleteModal,
      onConfirm: handleConfirmDeletion,
      isOpened: isDeleteModalOpen,
      description: 'После удаления, содержимое \n будет утеряно навсегда',
      title: 'Удаление достижения',
    },
  };
};
