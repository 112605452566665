import { BackButton } from 'pages/Stories/EditStory/components/forms/components/BackButton/BackButton';
import { FontSettings } from 'pages/Stories/EditStory/components/forms/components/FontSettings/FontSettings';
import {
  TCommonElementFormProps,
  TOption,
  TVotingElementFormValues,
} from 'pages/Stories/EditStory/components/forms/types';
import { useStoryEditorContext } from 'pages/Stories/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/Stories/EditStory/hooks/useStoryEditorDispatchContext';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Divider } from 'shared/components/Divider';
import { Form, FormInput } from 'shared/components/Form';
import { ColorInput } from 'shared/components/Form/ColorInput/ColorInput';
import { Text } from 'shared/components/Text/Text';

import styles from '../ElementForm.module.css';

const labelResolver = (option: TOption) => option.name;
const valueResolver = (option: TOption) => option.value;

export const VotingElementForm = ({
  selectedElement,
}: TCommonElementFormProps<'voting'>) => {
  const { selectedLocale } = useStoryEditorContext();
  const dispatch = useStoryEditorDispatchContext();
  const { handleSubmit, control, watch } = useForm<TVotingElementFormValues>({
    values: selectedElement?.translations[selectedLocale].settings,
  });

  const submitForm = (data: TVotingElementFormValues) => {
    dispatch({
      type: 'updateSelectedStoryElementSettings',
      payload: data,
    });
  };

  const submit = useMemo(() => handleSubmit(submitForm), [submitForm]);

  useEffect(() => {
    const subscription = watch(() => handleSubmit(submitForm)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <div className={styles.root}>
      <Form action={submit} className={styles.formComponent}>
        <FormInput
          control={control}
          fieldName="title"
          inputClassName={styles.input}
          label="Заголовок"
          maxLength={20}
          placeholder="Введите заголовок"
        />
        <Divider />
        <FontSettings
          control={control}
          fontFamilyName="fontFamily"
          fontStyleName="fontStyle"
          labelResolver={labelResolver}
          valueResolver={valueResolver}
        />
        <Divider />
        <Text className={styles.subtitle} size={14} tag="h2" weight="Regular">
          Цвет заголовка
        </Text>
        <ColorInput
          control={control}
          fieldName="fill"
          inline
          inlineInputClassName={styles.formField}
          label="Заливка"
          placeholder=""
        />
        <Divider />
        <ColorInput
          control={control}
          fieldName="background"
          inline
          inlineInputClassName={styles.formField}
          label="Фон"
          placeholder=""
        />
      </Form>
      <BackButton />
    </div>
  );
};
