// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextElement-module__interactiveText--sZZaD {\n  display: block;\n  overflow: hidden;\n  border: none;\n  resize: none;\n  padding: 2px 4px;\n  border-radius: 4px;\n  width: 100%;\n  min-height: 100%;\n  max-height: 100%;\n  outline: none;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n\n.TextElement-module__previewText--gutVY {\n  display: flex;\n  align-items: center;\n  padding: 2px 4px;\n  border-radius: 4px;\n  outline: none;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n  word-wrap: break-word;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n\n.TextElement-module__previewText--gutVY > span {\n    max-width: 100%;\n    flex: 1 1;\n  }\n\n.TextElement-module__disabled--t0Zlf {\n  pointer-events: none;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/Stories/EditStory/components/elements/TextElement/TextElement.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,gBAAgB;AAMlB;;AAJE;IACE,eAAe;IACf,SAAO;EACT;;AAGF;EACE,oBAAoB;AACtB","sourcesContent":[".interactiveText {\n  display: block;\n  overflow: hidden;\n  border: none;\n  resize: none;\n  padding: 2px 4px;\n  border-radius: 4px;\n  width: 100%;\n  min-height: 100%;\n  max-height: 100%;\n  outline: none;\n  user-select: none;\n}\n\n.previewText {\n  display: flex;\n  align-items: center;\n  padding: 2px 4px;\n  border-radius: 4px;\n  outline: none;\n  user-select: none;\n  word-wrap: break-word;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n\n  > span {\n    max-width: 100%;\n    flex: 1;\n  }\n}\n\n.disabled {\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"interactiveText": "TextElement-module__interactiveText--sZZaD",
	"previewText": "TextElement-module__previewText--gutVY",
	"disabled": "TextElement-module__disabled--t0Zlf"
};
export default ___CSS_LOADER_EXPORT___;
