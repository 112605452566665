import { format, parseISO } from 'date-fns';
import { TStoryElementData } from 'entities/stories/types';
import { omit } from 'lodash';
import { notify } from 'shared/components/Notification';
import { TLangType } from 'shared/types/common';

// Story elements response always return locale keys, even if they are not been created,
// so we need to filter them using created locales from story group response, which is more reliable
export const omitExtraLocalesFromElement = (
  element: TStoryElementData,
  createdLocales: TLangType[],
) => {
  const translationKeys = Object.keys(element.translations) as TLangType[];
  const missingLocales = translationKeys.filter(
    (locale) => !createdLocales.includes(locale),
  );

  return omit(
    element,
    missingLocales.map((locale) => `translations.${locale}`),
  ) as TStoryElementData;
};

export const getSuccessStoryPublicationNotify = <
  T extends { published: string | null },
>(
  result: T,
) => {
  notify(
    `Сторис будет опубликована: ${format(
      parseISO(result.published as unknown as string),
      'dd-MM-yyyy HH:mm',
    )}`,
    {
      type: 'success',
    },
  );
};
