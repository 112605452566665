import { useLocales } from 'entities/config/lib/useLocales';
import {
  useUpdateStoryElementMutation,
  useUpdateStoryGroupMutation,
} from 'entities/stories/mutations';
import { omit } from 'lodash';
import { useStoryEditorContext } from 'pages/Stories/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorData } from 'pages/Stories/EditStory/hooks/useStoryEditorData';
import { useStoryEditorDispatchContext } from 'pages/Stories/EditStory/hooks/useStoryEditorDispatchContext';
import { useParams } from 'react-router-dom';
import { notify } from 'shared/components/Notification';
import { Languages, TLangType } from 'shared/types/common';

export const useLocalesControl = () => {
  const { defaultLocale } = useLocales();
  const dispatch = useStoryEditorDispatchContext();

  const { storyGroupId } = useParams() as { storyGroupId: string };
  const { storyGroup, storyElements, createdLocales } =
    useStoryEditorData(storyGroupId);
  const { elementsWithErrors } = useStoryEditorContext();

  const storyGroupUpdateMutation = useUpdateStoryGroupMutation();
  const storyElementUpdateMutation = useUpdateStoryElementMutation();
  const notifyRequiredFields = () => {
    notify('Заполните все обязательные поля', { type: 'error' });
  };

  const handleLocaleCreate = async (locale: TLangType) => {
    if (elementsWithErrors && elementsWithErrors?.length > 0) {
      notifyRequiredFields();
      return;
    }

    if (storyGroup) {
      const newAvailableLocales = [...storyGroup.availableLocales, locale];
      const defaultLocaleTranslations = storyGroup.translations[defaultLocale];
      const newTranslations = {
        ...storyGroup.translations,
        [locale]: { ...defaultLocaleTranslations },
      };
      await Promise.all([
        storyGroupUpdateMutation.mutateAsync({
          id: storyGroup.id,
          availableLocales: newAvailableLocales,
          translations: newTranslations,
        }),
        ...storyElements.map((element) =>
          storyElementUpdateMutation.mutateAsync({
            ...element,
            translations: {
              ...element.translations,
              [locale]: {
                ...element.translations[defaultLocale],
              },
            },
          }),
        ),
      ]);

      dispatch({ type: 'selectLocale', payload: locale });
      dispatch({ type: 'selectStoryElement', payload: null });
      notify('Не забудьте перевести элементы на новый язык', { type: 'warning' });
    }
  };

  const handleLocaleDelete = (localeToDelete: TLangType) => {
    if (storyGroup) {
      storyGroupUpdateMutation.mutate({
        id: storyGroup.id,
        availableLocales: storyGroup.availableLocales.filter(
          (locale) => locale !== localeToDelete,
        ),
      });
      storyElements.forEach((element) => {
        storyElementUpdateMutation.mutate({
          ...element,
          translations: omit(element.translations, [localeToDelete as Languages]),
        });
      });
      dispatch({ type: 'selectLocale', payload: defaultLocale });
    }
  };

  const handleLocaleSelect = (locale: TLangType) => {
    if (elementsWithErrors && elementsWithErrors?.length > 0) {
      notifyRequiredFields();
      return;
    }

    dispatch({ type: 'selectLocale', payload: locale });
    dispatch({ type: 'selectStoryElement', payload: null });
  };

  return {
    createdLocales,
    onLocaleSelect: handleLocaleSelect,
    onLocaleAdd: handleLocaleCreate,
    onLocaleDelete: handleLocaleDelete,
  };
};
