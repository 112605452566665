import cn from 'classnames';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { useFormContext } from 'react-hook-form';
import iphone13mockup from 'shared/assets/images/iphone-13-mockup.png';
import { Divider } from 'shared/components/Divider';
import defaultElementsStyles from 'shared/components/Editor/elements/editorElementsStyle.module.css';
import { Text } from 'shared/components/Text/Text';
import { TODAY } from 'shared/constants';
import { TVideoPostFields } from 'widgets/video-form/types';
import { VideoFormPreviewImage } from 'widgets/video-form/ui/VideoFormHeaderPanel/VideoFormPreviewButton/VideoFormPreviewImage';
import { VideoFormRecommendedVideoCard } from 'widgets/video-form/ui/VideoFormHeaderPanel/VideoFormPreviewButton/VideoFormRecommendedVideoCard';

import styles from './VideoFormPreviewButton.module.css';

export const VideoFormPreviewContent = () => {
  const { getValues } = useFormContext<TVideoPostFields>();
  const {
    preview,
    translations,
    locale: { selected: selectedLocale },
  } = getValues();

  const title = translations[selectedLocale]?.title;
  const todayDate = format(TODAY, 'dd MMMM yyyy', { locale: ru });

  return (
    <>
      <img alt="iphone 13 mockup" className={styles.mockup} src={iphone13mockup} />
      <div className={styles.content}>
        <VideoFormPreviewImage preview={preview} />
        <div className={cn(styles.body, defaultElementsStyles.root)}>
          <Text className={styles.title} size={12} weight="SemiBold">
            {title || 'Тут будет заголовок'}
          </Text>
          <Text className={styles.date} size={10}>
            {todayDate}
          </Text>
        </div>
        <Divider />
        <div className={cn(styles.videos, defaultElementsStyles.root)}>
          <Text className={styles.title} size={12} weight="SemiBold">
            Рекомендованные видео
          </Text>
          <VideoFormRecommendedVideoCard date={todayDate} />
          <VideoFormRecommendedVideoCard date={todayDate} />
        </div>
      </div>
    </>
  );
};
