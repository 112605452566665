import { TIconType } from 'shared/components/Icon/types';
import { TContentStatus } from 'shared/types/common';

export type TSortDirection = 'asc' | 'desc';

export type TSortParams = 'screenCount' | 'createdAt' | 'published' | 'viewsCount';

export type TStoriesFilter = {
  status: TContentStatus[];
};

export type TStoriesListModal =
  | 'confirmDelete'
  | 'confirmPublishNow'
  | 'changePublishDate';

export type TStoryGroupAction = {
  title: string;
  icon: TIconType;
  onClick: () => void;
};

export enum EStoriesStatus {
  Published = 'published',
  Draft = 'draft',
  Postponed = 'postponed',
}

export type TStoryGroupActionsByStatus = Record<EStoriesStatus, TStoryGroupAction[]>;
