import { ReactNode } from 'react';
import { Button } from 'shared/components/Button/Button';
import { OverlayModal } from 'shared/components/OverlayModal/OverlayModal';
import { Text } from 'shared/components/Text/Text';

import styles from './ConfirmationModal.module.css';

type TProps = {
  title: string;
  description?: ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  isOpened: boolean;
  isLoading?: boolean;
};

export const ConfirmationModal = ({
  isOpened,
  onClose,
  onConfirm,
  title,
  description,
  isLoading,
}: TProps) => (
  <OverlayModal opened={isOpened} onClose={onClose}>
    <div className={styles.root}>
      <Text className={styles.title} size={16} weight="SemiBold">
        {title}
      </Text>
      {description && (
        <Text className={styles.description} size={14}>
          {description}
        </Text>
      )}
      <div className={styles.actions}>
        <Button color="gray" kind="outlined" onClick={onClose}>
          Отменить
        </Button>
        <Button isLoading={isLoading} onClick={onConfirm}>
          Подтвердить
        </Button>
      </div>
    </div>
  </OverlayModal>
);
