import { TTeam } from 'entities/teams/types';
import { TMetaType } from 'shared/types/common';

type TMapTeamsResponseArgs = {
  data: TTeam[];
  meta?: TMetaType<true>;
};

export type TTeamsResponse = {
  total: number;
  page: number;
  teams: TTeam[];
};

export const mapTeamsResponse = ({
  data: teams,
  meta,
}: TMapTeamsResponseArgs): TTeamsResponse => {
  const page = meta!.page;

  return {
    total: page.total_entries,
    page: page.number,
    teams,
  };
};
