import { TTranslationsMapper } from 'shared/types/common';

export const DEFAULT_PAGINATION_PAGE_SIZE = 15;

export const URL_REGEXP =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const MAX_FILE_SIZE = 7 * 1024 * 1024;

export const langNameMap: TTranslationsMapper = {
  ru: 'Русский язык',
  en: 'Английский язык',
  de: 'Немецкий язык',
  fr: 'Французский язык',
};

export const TEXT_MIN_LENGTH = 1;
export const TEXT_MIN_LENGTH_MESSAGE = 'Не может быть пустым';

export const DEFAULT_APP_URL = 'https://crespo.app';
