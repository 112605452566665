import { useDeletePlayerAchievementMutation } from 'entities/playerAchievements/mutations';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useDeletePlayerAchievement = () => {
  const { mutate: deletePlayerAchievementMutation, isLoading } =
    useDeletePlayerAchievementMutation();

  const deletePlayerAchievement = (achievementId: TEntityId) => {
    deletePlayerAchievementMutation(achievementId, {
      onError: () =>
        notify('Не удалось удалить достижение игрока', { type: 'error' }),
    });
  };

  return {
    isDeletingPlayerAchievement: isLoading,
    deletePlayerAchievement,
  };
};
