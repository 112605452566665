// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SecondLayout-module__root--PRMej {\n  display: flex;\n  flex-direction: column;\n  min-height: calc(100vh - var(--nav-header-height));\n}\n\n.SecondLayout-module__content--wdjjR {\n  display: flex;\n  flex-shrink: 0;\n  min-height: calc(100% - var(--second-header-height));\n}\n\n.SecondLayout-module__header--hzco3 {\n  padding: 12px 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: var(--second-header-height);\n  border-bottom: var(--default-border);\n}\n\n.SecondLayout-module__title--ypxo0 {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n\n.SecondLayout-module__confirmButton--ygdvU {\n  width: auto;\n}\n\n.SecondLayout-module__loading--K5F8Z {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/ui/layouts/second-layout/SecondLayout.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kDAAkD;AACpD;;AAEA;EACE,aAAa;EACb,cAAc;EACd,oDAAoD;AACtD;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mCAAmC;EACnC,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  min-height: calc(100vh - var(--nav-header-height));\n}\n\n.content {\n  display: flex;\n  flex-shrink: 0;\n  min-height: calc(100% - var(--second-header-height));\n}\n\n.header {\n  padding: 12px 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: var(--second-header-height);\n  border-bottom: var(--default-border);\n}\n\n.title {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n\n.confirmButton {\n  width: auto;\n}\n\n.loading {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SecondLayout-module__root--PRMej",
	"content": "SecondLayout-module__content--wdjjR",
	"header": "SecondLayout-module__header--hzco3",
	"title": "SecondLayout-module__title--ypxo0",
	"confirmButton": "SecondLayout-module__confirmButton--ygdvU",
	"loading": "SecondLayout-module__loading--K5F8Z"
};
export default ___CSS_LOADER_EXPORT___;
