import { useFetchVideosQuery } from 'entities/video/queries';
import { TVideoFilter } from 'feature/filters/types';
import { TVideoTableSortParams } from 'pages/video/VideoPage/types';
import {
  calculateSelectedPage,
  calculateTotalItems,
  createFilterQuery,
  createSortQuery,
} from 'pages/video/VideoPage/utils';
import { VideoTable } from 'pages/video/VideoPage/VideoTable';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePagination } from 'shared/hooks/usePagination';
import { routes } from 'shared/routes';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';
import { useCommonListTableSort } from 'widgets/elements-list-table/hooks/useCommonListTableSort';

import styles from './VideoPage.module.css';

export const DEFAULT_VIDEO_SORT_OPTIONS = {
  direction: 'desc',
  sortBy: 'created_at',
} as const;

export const VideoPage = () => {
  const navigate = useNavigate();
  const { selectedPage, handlePageChange } = usePagination();
  const { sortOptions, handleSort } = useCommonListTableSort<TVideoTableSortParams>(
    DEFAULT_VIDEO_SORT_OPTIONS,
  );
  const [filter, setFilter] = useState<TVideoFilter | null>(null);

  const { data, isLoading, error } = useFetchVideosQuery({
    page: selectedPage,
    sort: createSortQuery(sortOptions),
    filter: createFilterQuery(filter),
  });

  const filteredData = data?.video;

  const handleFilter = (filter: TVideoFilter) => {
    setFilter(filter);
    handlePageChange(1);
  };

  return (
    <div className={styles.root}>
      <CommonListWrapper
        emptyComponentDescription="Создайте первое видео"
        error={error}
        isEmpty={!filter && (!data || (data?.total === 0 && !isLoading))}
        isLoading={isLoading}
        title="Видео"
        createButtonProps={{
          content: 'Создать видео',
          onClick: () => navigate(routes.createVideo),
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(filteredData, data?.page),
          totalItems: calculateTotalItems(filteredData, data?.total),
          onPageChange: handlePageChange,
        }}
      >
        {filteredData && sortOptions && (
          <VideoTable
            filter={filter}
            sortOptions={sortOptions}
            videoPosts={filteredData}
            onFilter={handleFilter}
            onSort={handleSort}
          />
        )}
      </CommonListWrapper>
    </div>
  );
};
