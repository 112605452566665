import { useEditPlayerTeamAchievementMutation } from 'entities/playerTeamAchievements/mutations';
import { TPlayerTeamAchievement } from 'entities/playerTeamAchievements/types';
import { notify } from 'shared/components/Notification';

export const useEditPlayerTeamAchievement = () => {
  const { mutate: editPlayerTeamAchievementMutation, isLoading } =
    useEditPlayerTeamAchievementMutation();

  const editPlayerTeamAchievement = (
    playerTeamAchievement: TPlayerTeamAchievement,
    onSuccess: () => void,
  ) => {
    editPlayerTeamAchievementMutation(playerTeamAchievement, {
      onSuccess,
      onError: () =>
        notify('Не удалось обновить командное достижение игрока', { type: 'error' }),
    });
  };

  return { isEditingPlayerTeamAchievement: isLoading, editPlayerTeamAchievement };
};
