import { PlayerLayout } from 'feature/player-layout/ui/PlayerLayout/PlayerLayout';
import { SocialNetworkModal } from 'feature/social-network-modal/ui/SocialNetworkModal/SocialNetworkModal';
import { useCreatePlayerSocialNetwork } from 'pages/player/PlayerSocialNetworksPage/hooks/useCreatePlayerSocialNetwork';
import { usePlayerSocialNetworks } from 'pages/player/PlayerSocialNetworksPage/hooks/usePlayerSocialNetworks';
import { PlayerSocialNetworksTable } from 'pages/player/PlayerSocialNetworksPage/PlayerSocialNetworksTable/PlayerSocialNetworksTable';
import {
  calculateSelectedPage,
  calculateTotalItems,
} from 'pages/video/VideoPage/utils';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

import styles from './PlayerSocialNetworksPage.module.css';

export const PlayerSocialNetworksPage = () => {
  const {
    error,
    isLoading,
    handlePageChange,
    socialLinks,
    page,
    total,
    handleConfirmButtonClick,
  } = usePlayerSocialNetworks();
  const { openCreateModal, createSocialNetworkModalProps } =
    useCreatePlayerSocialNetwork();

  return (
    <PlayerLayout
      confirmButtonProps={{
        onClick: handleConfirmButtonClick,
      }}
    >
      <CommonListWrapper
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title="Ссылки на социальные сети"
        createButtonProps={{
          content: 'Добавить ссылку',
          onClick: openCreateModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
          className: styles.createButton,
        }}
        paginationProps={{
          selectedPage: calculateSelectedPage(socialLinks, page),
          totalItems: calculateTotalItems(socialLinks, total),
          onPageChange: handlePageChange,
        }}
      >
        <PlayerSocialNetworksTable socialLinks={socialLinks ?? []} />
      </CommonListWrapper>
      {createSocialNetworkModalProps.isOpened && (
        <SocialNetworkModal {...createSocialNetworkModalProps} />
      )}
    </PlayerLayout>
  );
};
