import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { routes } from 'shared/routes';

export const VideoFormBackButton = () => {
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false);

  const handleBackButtonClick = () => {
    setIsClosing(true);
  };

  const handleBackButtonClose = () => {
    setIsClosing(false);
  };

  const handleBack = () => {
    navigate(routes.video);
  };

  return (
    <>
      <IconButton icon="chevron-left" onClick={handleBackButtonClick} />
      <ConfirmationModal
        isOpened={isClosing}
        title="Вернуться на главный экран?"
        description={
          <>
            Когда вы вернетесь назад, <br /> контент будет утерян.
          </>
        }
        onClose={handleBackButtonClose}
        onConfirm={handleBack}
      />
    </>
  );
};
