import {
  EditorElementTypeEnum,
  TEditorElement,
} from 'shared/components/Editor/types';
import { TLangType } from 'shared/types/common';
import { fileZodValidation } from 'shared/zod/fileZodValidation';
import z from 'zod';

export const MAX_WORKSPACE_TITLE_LENGTH = 255;

export const addNewsPostValidationSchema = z
  .object({
    locale: z.object({
      created: z.array(z.string()),
      selected: z.string(),
    }),
    preview: z.object({
      file: fileZodValidation('Загрузите превью-изображение'),
      data: z.string(),
    }),
    slider: z.object({
      file: z.optional(fileZodValidation('Загрузите обложку слайдера')),
      data: z.optional(z.string()),
    }),
    important: z.boolean(),
  })
  .passthrough()
  .superRefine((fields, context) => {
    const validatedContent = contentValidation(
      fields.locale.selected as TLangType,
    ).safeParse(fields);

    if (!validatedContent.success) {
      validatedContent.error.issues.forEach((issue) => {
        context.addIssue(issue);
      });
    }
  })
  .superRefine((fields, context) => {
    if (fields.important && !fields.slider.file) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Загрузите обложку слайдера',
        path: ['slider.file'],
      });
    }
  });

const contentValidation = (selected: TLangType) =>
  z.object({
    content: z.object({
      preview: z.object({
        translations: z.object({
          [selected]: z.object({
            file: fileZodValidation('Загрузите превью новости'),
            data: z.string(),
          }),
        }),
      }),
      title: z.object({
        translations: z.object({
          [selected]: z.object({
            title: z
              .string()
              .min(1, 'Введите заголовок новости')
              .max(
                MAX_WORKSPACE_TITLE_LENGTH,
                `Максимальная длина заголовка ${MAX_WORKSPACE_TITLE_LENGTH} символов`,
              ),
          }),
        }),
      }),
      body: z.any().refine((content: TEditorElement[]) => {
        return content.some((element) => {
          if (element.type === EditorElementTypeEnum.Paragraph) {
            const textParagraph = element.translations[
              selected
            ]!.text.trim().replace(/<br \/>/g, '');

            if (textParagraph === '') {
              return false;
            }
          }

          return true;
        });
      }, 'Введите текст новости'),
    }),
  });
