import { allOperations } from 'entities/user/model/consts';
import { routes } from 'shared/routes';
import { TNavigationLink } from 'widgets/navigation/types';

export const navigationLinks: TNavigationLink[] = [
  {
    name: 'Сторис',
    route: routes.stories,
    permissions: {
      StoryGroup: allOperations,
      Story: allOperations,
      'Story::Element': allOperations,
    },
  },
  {
    name: 'Новости',
    route: routes.news,
    permissions: {
      NewsPost: allOperations,
      Match: ['read'],
      Player: ['read'],
      Tournament: ['read'],
      Season: ['read'],
      Team: ['read'],
      Label: ['read'],
      NewsCategory: ['read'],
      Manager: ['read'],
    },
  },
  {
    name: 'Видео',
    route: routes.video,
    permissions: {
      VideoPost: allOperations,
      Match: ['read'],
      Player: ['read'],
      Tournament: ['read'],
      Season: ['read'],
      Team: ['read'],
      Label: ['read'],
      VideoCategory: ['read'],
      Manager: ['read'],
    },
  },
];
