// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StoryEditor-module__root--yA5bv {\n  display: flex;\n  justify-content: space-between;\n  height: calc(100vh - var(--nav-header-height) - var(--second-header-height));\n  background-color: var(--bg-gray);\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/Stories/EditStory/components/StoryEditor/StoryEditor.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,4EAA4E;EAC5E,gCAAgC;AAClC","sourcesContent":[".root {\n  display: flex;\n  justify-content: space-between;\n  height: calc(100vh - var(--nav-header-height) - var(--second-header-height));\n  background-color: var(--bg-gray);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "StoryEditor-module__root--yA5bv"
};
export default ___CSS_LOADER_EXPORT___;
