// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlayerCareersTable-module__root--Mwkm7 {\n  width: 100%;\n}\n\n.PlayerCareersTable-module__rowGridTemplate--JZQAx {\n  grid-template-columns: 22.5% 7.6% 12.8% 9.7% 11.7% 8.6% auto 3.1%;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/player/PlayerCareersPage/PlayerCareersTable/PlayerCareersTable.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iEAAiE;AACnE","sourcesContent":[".root {\n  width: 100%;\n}\n\n.rowGridTemplate {\n  grid-template-columns: 22.5% 7.6% 12.8% 9.7% 11.7% 8.6% auto 3.1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlayerCareersTable-module__root--Mwkm7",
	"rowGridTemplate": "PlayerCareersTable-module__rowGridTemplate--JZQAx"
};
export default ___CSS_LOADER_EXPORT___;
