import { TUserOperation } from 'entities/user/model/types';

const userBaseUrl = 'content_managers?include=permissions';

export const userQueryKeys = {
  user: 'user',
};

export const userApiKeys = {
  getUser: userBaseUrl,
};

export const allOperations: TUserOperation[] = [
  'create',
  'read',
  'update',
  'destroy',
];
