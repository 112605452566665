// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loader-module__root--ritdp {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000;\n}\n\n.Loader-module__xs--VGX3K {\n  width: 20px;\n  height: 20px;\n}\n\n.Loader-module__s--EAWVW {\n  width: 38px;\n  height: 38px;\n}\n\n.Loader-module__m--O8M7X {\n  width: 46px;\n  height: 46px;\n}\n\n.Loader-module__l--r9VcR {\n  width: 62px;\n  height: 62px;\n}\n\n@keyframes Loader-module__rotate--oNcjt {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.Loader-module__rotating--v9sMC {\n  animation: Loader-module__rotate--oNcjt 1s linear infinite;\n}\n\n.Loader-module__label--hbN5P {\n  margin-top: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/7-shared/components/Loader/Loader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,0DAAoC;AACtC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  z-index: 1000;\n}\n\n.xs {\n  width: 20px;\n  height: 20px;\n}\n\n.s {\n  width: 38px;\n  height: 38px;\n}\n\n.m {\n  width: 46px;\n  height: 46px;\n}\n\n.l {\n  width: 62px;\n  height: 62px;\n}\n\n@keyframes rotate {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.rotating {\n  animation: rotate 1s linear infinite;\n}\n\n.label {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Loader-module__root--ritdp",
	"xs": "Loader-module__xs--VGX3K",
	"s": "Loader-module__s--EAWVW",
	"m": "Loader-module__m--O8M7X",
	"l": "Loader-module__l--r9VcR",
	"rotating": "Loader-module__rotating--v9sMC",
	"rotate": "Loader-module__rotate--oNcjt",
	"label": "Loader-module__label--hbN5P"
};
export default ___CSS_LOADER_EXPORT___;
