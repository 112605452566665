import { TManagerSocialNetwork } from 'entities/managerSocialNetworks/types';
import { TMetaType } from 'shared/types/common';

type TMapManagerSocialNetworksResponseArgs = {
  data: TManagerSocialNetwork[];
  meta?: TMetaType<true>;
};

export const mapManagerSocialNetworksResponse = ({
  data: socialLinks,
  meta,
}: TMapManagerSocialNetworksResponseArgs) => {
  const page = meta!.page;

  return {
    total: page.total_entries,
    page: page.number,
    socialLinks,
  };
};
