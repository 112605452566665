import { TPlayerTeamAchievement } from 'entities/playerTeamAchievements/types';
import { getPlayerTeamAchievementsColumnsConfig } from 'pages/player/PlayerTeamAchievementsPage/columnsConfig';
import { useCommonListTable } from 'widgets/elements-list-table/hooks/useCommonListTable';
import { CommonListTable } from 'widgets/elements-list-table/ui/CommonListTable/CommonListTable';

import styles from './PlayerTeamAchievementsTable.module.css';

type TProps = {
  playerTeamAchievements: TPlayerTeamAchievement[];
};

export const PlayerTeamAchievementsTable = ({ playerTeamAchievements }: TProps) => {
  const { activeItemId, columnsConfig } = useCommonListTable({
    configCreator: getPlayerTeamAchievementsColumnsConfig,
  });

  return (
    <div className={styles.root}>
      <CommonListTable
        activeItemId={activeItemId}
        columns={columnsConfig}
        data={playerTeamAchievements}
        headRowClassName={styles.headRow}
        rowClassName={styles.rowGridTemplate}
      />
    </div>
  );
};
