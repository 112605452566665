import { photoGalleryCategoriesApiKeys } from 'entities/photoGalleryCategories/consts';
import { mapPhotoGalleryCategoriesResponse } from 'entities/photoGalleryCategories/mapper/mapPhotoGalleryCategoriesResponse';
import {
  TPhotoGalleryCategory,
  TPhotoGalleryEditCategories,
} from 'entities/photoGalleryCategories/types';
import { del, get, patch, post } from 'shared/api/axios';
import { createPaginationParams } from 'shared/api/utils';
import { TEntityId, TLangType, TTranslationsObject } from 'shared/types/common';

export const fetchPhotoGalleryCategories = (
  page: number,
  defaultLocale: TLangType,
) =>
  get<TPhotoGalleryCategory[], true>(
    photoGalleryCategoriesApiKeys.getPhotoGalleryCategories,
    {
      params: {
        ...createPaginationParams(page),
        sort: 'name',
      },
    },
  ).then((response) => {
    return mapPhotoGalleryCategoriesResponse({
      ...response.data,
      defaultLocale,
    });
  });

export const createPhotoGalleryCategory = (
  data: TTranslationsObject<{
    name: string;
  }>,
) =>
  post<TPhotoGalleryCategory>(
    photoGalleryCategoriesApiKeys.createPhotoGalleryCategory,
    {
      photoGalleryCategory: data,
    },
  ).then((response) => response.data.data);

export const deletePhotoGalleryCategory = (categoryId: TEntityId) =>
  del(photoGalleryCategoriesApiKeys.deletePhotoGalleryCategory(categoryId)).then(
    (response) => response.data.data,
  );

export const editPhotoGalleryCategory = ({
  translations,
  id,
}: TPhotoGalleryEditCategories) =>
  patch(photoGalleryCategoriesApiKeys.editPhotoGalleryCategory(id), {
    photoGalleryCategory: { translations },
  }).then((response) => response.data.data);
