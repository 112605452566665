import { notify } from 'shared/components/Notification';

export const onBlurVideoLinkHandler = (isValidLink: boolean, value: string) => {
  if (!isValidLink && value) {
    notify(
      'Видео не входит в список разрешенных, либо указана некорректная ссылка',
      {
        type: 'error',
      },
    );
  }
};
