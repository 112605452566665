import { useLocales } from 'entities/config/lib/useLocales';
import { useFormContext } from 'react-hook-form';
import { FormInput } from 'shared/components/Form';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';
import { useValidVideoLink } from 'shared/components/VideoPlayer/helpers';
import { VideoPlayer } from 'shared/components/VideoPlayer/VideoPlayer';
import { secureProtocol } from 'shared/constants';
import { onBlurVideoLinkHandler } from 'shared/handlers';
import { TVideoPostFields } from 'widgets/video-form/types';

import styles from './VideoWorkspace.module.css';

export const VideoWorkspace = () => {
  const { defaultLocale } = useLocales();
  const { control, watch } = useFormContext<TVideoPostFields>();
  const currentLocale = watch('locale.selected');
  const videoLink = watch('videoLink');

  const { isValidLink, attachedVideoHosting } = useValidVideoLink(videoLink);

  return (
    <div className={styles.content}>
      <FormInput
        className={styles.preview}
        control={control}
        disabled={defaultLocale !== currentLocale}
        fieldName="videoLink"
        label="Ссылка на видео"
        placeholder="Вставьте ссылку"
        required
        showErrors
        size="s"
        tip="Рекомендовано использовать Embed-ссылку"
        onBlur={() => onBlurVideoLinkHandler(isValidLink, videoLink)}
      />
      <Text className={styles.hintText} size={12}>
        Ссылки надо использовать из списка разрешенных сервисов, они должны
        начинаться с {secureProtocol}
      </Text>
      {!videoLink && (
        <div className={styles.emptyVideo}>
          <Icon className={styles.icon} kind="empty" />
        </div>
      )}
      {isValidLink && (
        <div className={styles.videoWrapper}>
          <VideoPlayer
            hosting={attachedVideoHosting ?? ''}
            src={videoLink}
            width={494}
          />
        </div>
      )}
      <FormInput
        className={styles.preview}
        control={control}
        fieldName={`translations.${currentLocale}.title`}
        label="Заголовок"
        maxLength={255}
        placeholder="Введите заголовок"
        required
        showErrors
        size="s"
        tip="Заголовок будет отображаться в превью и на детальном экране видео"
      />
    </div>
  );
};
