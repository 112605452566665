import { useQuery } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import {
  fetchVideoPost,
  fetchVideoPosts,
  TFetchVideoPosts,
} from 'entities/video/api';
import { videoQueryKeys } from 'entities/video/consts';
import { mapAPIVideoPostToVideoPostFields } from 'entities/video/mapper/mapAPIVideoPostToVideoPostFields';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

export const useFetchVideosQuery = ({
  page = 1,
  sort,
  filter,
}: Partial<TFetchVideoPosts>) => {
  const { defaultLocale } = useLocales();

  return useQuery({
    queryKey: [videoQueryKeys.videoPosts, page, sort, filter],
    queryFn: () => fetchVideoPosts({ page, sort, filter }, defaultLocale),
    cacheTime: 0,
  });
};

export const useFetchVideoForEditQuery = (videoPostId: TEntityId) => {
  const { defaultLocale } = useLocales();
  return useQuery({
    queryKey: [videoQueryKeys.videoPostForEdit, videoPostId],
    queryFn: async () =>
      await mapAPIVideoPostToVideoPostFields(
        await fetchVideoPost(videoPostId),
        defaultLocale,
      ),
    enabled: toBoolean(videoPostId),
    cacheTime: 0,
  });
};
