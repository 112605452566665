import { useFetchNewsQuery } from 'entities/news/queries';
import { NewsPageTable } from 'pages/news/NewsPage/NewsPageTable';
import { useNavigate } from 'react-router-dom';
import { usePagination } from 'shared/hooks/usePagination';
import { routes } from 'shared/routes';
import { CommonListWrapper } from 'widgets/elements-list/ui/CommonListWrapper/CommonListWrapper';

import styles from './NewsPage.module.css';

export const NewsPage = () => {
  const navigate = useNavigate();
  const { selectedPage, handlePageChange } = usePagination();
  const { data, isLoading, error } = useFetchNewsQuery(selectedPage);

  return (
    <div className={styles.root}>
      <CommonListWrapper
        emptyComponentDescription="Создайте первую новость"
        error={error}
        isEmpty={!data || (data.total === 0 && !isLoading)}
        isLoading={isLoading}
        title="Новости"
        createButtonProps={{
          content: 'Создать новость',
          onClick: () => navigate(routes.createNewsPost),
        }}
        paginationProps={{
          selectedPage: data?.page ?? 1,
          totalItems: data?.total ?? 0,
          onPageChange: handlePageChange,
        }}
      >
        {data?.news && <NewsPageTable news={data.news} />}
      </CommonListWrapper>
    </div>
  );
};
