import { Alert } from 'shared/components/Alert/Alert';

import styles from './LayersPanel.module.css';

const EMPTY_MESSAGE =
  'Вы ничего не создали! Попробуйте добавить что-нибудь из “Панели контента”';

export const EmptyLayers = () => {
  return (
    <>
      <Alert
        className={styles.alert}
        message={EMPTY_MESSAGE}
        textSize={12}
        variant="dark"
      />
    </>
  );
};
