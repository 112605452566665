import recommendedVideoImg from 'shared/assets/images/preview-video.jpeg';
import { Icon } from 'shared/components/Icon/Icon';
import { Text } from 'shared/components/Text/Text';

import styles from './VideoFormPreviewButton.module.css';

type TProps = {
  date: string;
};

export const VideoFormRecommendedVideoCard = ({ date }: TProps) => {
  return (
    <div className={styles.recommendedVideo}>
      <div className={styles.recommendedVideoImageWrapper}>
        <img alt="mock recommended video preview image" src={recommendedVideoImg} />
        <Icon className={styles.iconPlay} kind="play-circle" size="l" />
        <Text className={styles.timeStatus} size={10}>
          24:38
        </Text>
      </div>
      <div className={styles.recommendedVideoContent}>
        <Text className={styles.title} size={12}>
          Нгола Конте провел один из лучших сезонов за всю свою карьеру
        </Text>
        <Text className={styles.date} size={10}>
          {date}
        </Text>
      </div>
    </div>
  );
};
