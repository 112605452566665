// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlayerSocialNetworksPage-module__icon--AstQO {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n  width: 32px;\n  padding: 8px;\n  background-color: var(--gray-100);\n  aspect-ratio: 1 / 1;\n  border-radius: 100%;\n  margin-right: 8px;\n}\n\n.PlayerSocialNetworksPage-module__text--gOTwG {\n  padding-left: 12px;\n  padding-right: 12px;\n  /* 100% - (iconSize + margin) */\n  max-width: calc(100% - 32px - 8px);\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.PlayerSocialNetworksPage-module__createButton--MiagL {\n  padding: 7px 11px;\n}\n\n.PlayerSocialNetworksPage-module__nameColumn--WTZ2M {\n  width: 260px;\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/player/PlayerSocialNetworksPage/PlayerSocialNetworksPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,WAAW;EACX,YAAY;EACZ,iCAAiC;EACjC,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,+BAA+B;EAC/B,kCAAkC;EAClC,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n  width: 32px;\n  padding: 8px;\n  background-color: var(--gray-100);\n  aspect-ratio: 1 / 1;\n  border-radius: 100%;\n  margin-right: 8px;\n}\n\n.text {\n  padding-left: 12px;\n  padding-right: 12px;\n  /* 100% - (iconSize + margin) */\n  max-width: calc(100% - 32px - 8px);\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.createButton {\n  padding: 7px 11px;\n}\n\n.nameColumn {\n  width: 260px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "PlayerSocialNetworksPage-module__icon--AstQO",
	"text": "PlayerSocialNetworksPage-module__text--gOTwG",
	"createButton": "PlayerSocialNetworksPage-module__createButton--MiagL",
	"nameColumn": "PlayerSocialNetworksPage-module__nameColumn--WTZ2M"
};
export default ___CSS_LOADER_EXPORT___;
