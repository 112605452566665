import { useMutation, useQueryClient } from '@tanstack/react-query';
import { storiesQueryKeys } from 'entities/stories/constants';
import { TStoryData, TStoryGroupData } from 'entities/stories/types';
import { notify } from 'shared/components/Notification';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';

import {
  batchUpdateStories,
  createStory,
  createStoryElement,
  createStoryGroup,
  deleteStory,
  deleteStoryElement,
  deleteStoryGroup,
  updateStoryElement,
  updateStoryGroup,
} from './api';

// ============== Story group mutations ==============

export const useCreateStoryGroupMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createStoryGroup,
    onSuccess: () => {
      return queryClient.invalidateQueries([storiesQueryKeys.storyGroups]);
    },
    onError: () => {
      notify('Не удалось создать группу сторис', { type: 'error' });
    },
  });
};

export const useUpdateStoryGroupMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateStoryGroup,
    onSuccess: async (storyGroupParams) => {
      queryClient.setQueryData(
        [storiesQueryKeys.storyGroup, storyGroupParams.id.toString()],
        storyGroupParams,
      );
      await queryClient.invalidateQueries([storiesQueryKeys.storyGroups]);
    },
    onError: () => {
      notify('Не удалось обновить группу сторис', { type: 'error' });
    },
  });
};

export const useDeleteStoryGroupMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: TEntityId) => deleteStoryGroup(id),
    onMutate: async (id: TEntityId) => {
      await queryClient.cancelQueries([storiesQueryKeys.storyGroups]);

      const previousStoryGroups =
        queryClient.getQueryData<TStoryGroupData[]>([
          storiesQueryKeys.storyGroups,
        ]) ?? [];

      queryClient.setQueryData<TStoryGroupData[]>(
        [storiesQueryKeys.storyGroups],
        () => previousStoryGroups.filter((storyGroup) => storyGroup.id !== id),
      );
    },
    onSettled: () => queryClient.invalidateQueries([storiesQueryKeys.storyGroups]),
    onError: () => {
      notify('Не удалось удалить группу сторис', { type: 'error' });
    },
  });
};

// ============== Story mutations ==============

export const useCreateStoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createStory,
    onSuccess: (story: TStoryData) => {
      queryClient.setQueryData([storiesQueryKeys.story, story.id.toString()], story);
      return queryClient.invalidateQueries([
        storiesQueryKeys.storyGroup,
        story.storyGroup,
      ]);
    },
  });
};

export const useBatchUpdateStoriesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: batchUpdateStories,
    onMutate: async (newStoriesData) => {
      await queryClient.cancelQueries([storiesQueryKeys.story]);
      const prevStories = queryClient
        .getQueriesData<TStoryData>([storiesQueryKeys.story])
        .map((getQueryResult) => getQueryResult[1]);

      const newStories = newStoriesData.map((newStoryData) => {
        const prevStoryData = prevStories.find(
          (prevStory) => prevStory?.id.toString() === newStoryData.id.toString(),
        ) as TStoryData;

        return {
          ...prevStoryData,
          position: newStoryData.position,
        };
      });
      newStories.forEach((newStoryData) => {
        queryClient.setQueryData(
          [storiesQueryKeys.story, newStoryData.id.toString()],
          newStoryData,
        );
      });
      return {
        prevStories,
      };
    },

    onError: (_error, _variables, context) => {
      queryClient.setQueryData([storiesQueryKeys.story], context?.prevStories);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [storiesQueryKeys.story],
      });
    },
  });
};

export const useDeleteStoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteStory,
    onSuccess: (_result, storyData) => {
      return queryClient.invalidateQueries([
        storiesQueryKeys.storyGroup,
        storyData.storyGroup,
      ]);
    },
  });
};

//  ============== Story element mutations ==============

export const useCreateStoryElementMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createStoryElement,
    onSuccess: (storyElement) => {
      const storyKey = [storiesQueryKeys.story, storyElement.story];
      const story: TStoryData | undefined = queryClient.getQueryData(storyKey);
      queryClient.invalidateQueries(storyKey);

      if (story) {
        const updatedStory = {
          ...story,
          elements: [...(story.elements || []), toString(storyElement.id)],
        };
        queryClient.setQueryData(storyKey, updatedStory);
      }

      queryClient.setQueryData(
        [storiesQueryKeys.storyElement, toString(storyElement.id)],
        storyElement,
      );
    },
  });
};

export const useUpdateStoryElementMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateStoryElement,
    onMutate: async (storyData) => {
      await queryClient.cancelQueries({
        queryKey: [storiesQueryKeys.storyElement, storyData.id.toString()],
      });

      queryClient.setQueryData(
        [storiesQueryKeys.storyElement, storyData.id.toString()],
        storyData,
      );
      const prevElement = queryClient.getQueryData([
        storiesQueryKeys.storyElement,
        storyData.id.toString(),
      ]);

      return {
        prevElement,
      };
    },
    onError: (error, storyData, context) => {
      queryClient.setQueryData(
        [storiesQueryKeys.storyElement, storyData.id.toString()],
        context?.prevElement,
      );
    },
    onSettled: (storyData) => {
      if (storyData) {
        queryClient.invalidateQueries({
          queryKey: [storiesQueryKeys.storyElement, storyData.id.toString()],
        });
      }
    },
  });
};

export const useDeleteStoryElementMutation = (selectedStoryId?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteStoryElement,
    onSuccess: (_result) => {
      queryClient.invalidateQueries([storiesQueryKeys.story, selectedStoryId]);
    },
  });
};
