import { FloatingPortal, useMergeRefs } from '@floating-ui/react';
import cn from 'classnames';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import { Badge } from 'shared/components/Badge/Badge';
import { Dropdown } from 'shared/components/Dropdown/Dropdown';
import { BaseCheckbox } from 'shared/components/Form/Checkbox/BaseCheckbox';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { BaseSelect } from 'shared/components/Form/Select/BaseSelect';
import { TBaseSelectProps, TSelectProps } from 'shared/components/Form/Select/types';
import { useSelectSearch } from 'shared/components/Form/Select/useSelectSearch';
import { Text } from 'shared/components/Text/Text';
import { useFloat } from 'shared/hooks/useFloat';
import { toBoolean } from 'shared/lib/toBoolean';

import styles from './Select.module.css';

const SELECT_DROPDOWN_OFFSET = 2;

// TODO update Select component [WEB-1047]
export const Select = ({
  placement = 'bottom',
  value,
  size = 's',
  options,
  onChange,
  multiple,
  placeholderClassName,
  disabled = false,
  search,
  optionsContainerClassName,
  dropdownWidth = 0,
  ...props
}: TSelectProps & Omit<TBaseSelectProps, 'onClick' | 'icon'>) => {
  const [isOpen, toggleOpen] = useToggle(false);
  const ref = useRef<HTMLDivElement | null>();
  const [selectWidth, setSelectWidth] = useState(dropdownWidth);
  const isHaveValue = multiple ? toBoolean(value.length) : toBoolean(value);
  const { searchedOptions, resetSearch, onSearch } = useSelectSearch(options);

  useEffect(() => {
    if (!dropdownWidth) {
      const syncDropdownWidth = debounce(() => {
        if (ref.current && ref.current.clientWidth !== selectWidth) {
          setSelectWidth(ref.current.clientWidth);
        }
      });
      syncDropdownWidth();

      window.addEventListener('resize', syncDropdownWidth);

      return () => {
        window.removeEventListener('resize', syncDropdownWidth);
      };
    }

    return () => {};
  }, []);

  const {
    trigger: { triggerRef, ...trigger },
    floating,
  } = useFloat({
    isOpen,
    placement,
    onChange: (newIsOpen) => {
      resetSearch();
      toggleOpen(newIsOpen);
    },
    offset: SELECT_DROPDOWN_OFFSET,
  });

  const baseSelectRef = useMergeRefs([ref, triggerRef]);

  return (
    <BaseSelect
      {...props}
      disabled={disabled}
      icon={isOpen ? 'chevron-up' : 'chevron-down'}
      isActive={isOpen}
      ref={baseSelectRef}
      search={search && isOpen}
      size={size}
      placeholderClassName={cn(placeholderClassName, {
        [styles.placeholderWithValue]: isHaveValue,
      })}
      onSearch={onSearch}
      {...trigger}
    >
      {multiple && isHaveValue && (
        <div className={styles.valueWrapper}>
          {value.map((item) => (
            <Badge
              key={item}
              color="green"
              shape="pilled"
              size="s"
              text={options.find((o) => o.value === item)?.label ?? ''}
              variant="solid"
              onClose={() => {
                onChange(value.filter((v) => v !== item));
              }}
            />
          ))}
        </div>
      )}
      {isOpen && (
        <FloatingPortal>
          <Dropdown className={styles.dropdown} width={selectWidth} {...floating}>
            <div className={cn(styles.optionsContainer, optionsContainerClassName)}>
              {searchedOptions.length === 0 && <Text>Ничего не найдено</Text>}
              {multiple &&
                searchedOptions.map((option) => (
                  <BaseCheckbox
                    key={option.value}
                    checked={value.includes(option.value)}
                    className={cn(styles.checkbox, option.className)}
                    labelClassName={styles.checkboxLabel}
                    size={option.size}
                    label={
                      option.renderLabel
                        ? option.renderLabel(option.label)
                        : option.label
                    }
                    onChange={(checked) => {
                      if (checked) {
                        onChange([...value, option.value]);
                      } else {
                        onChange(value.filter((v) => v !== option.value));
                      }
                    }}
                  />
                ))}
              {!multiple &&
                searchedOptions.map((option) => (
                  <RadioButton
                    key={option.value}
                    checked={option.value === value}
                    className={cn(styles.radioButton, option.className)}
                    name={option.value}
                    size={option.size}
                    value={option.value}
                    label={
                      option.renderLabel
                        ? option.renderLabel(option.label)
                        : option.label
                    }
                    onChange={(_, value) => {
                      onChange(value as string);
                    }}
                  />
                ))}
            </div>
          </Dropdown>
        </FloatingPortal>
      )}
    </BaseSelect>
  );
};
