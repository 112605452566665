// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TransformTrigger-module__root--Jpnnt {\n  width: 12px;\n  height: 12px;\n  border: 2px solid var(--purple-400);\n  border-radius: 999px;\n  background-color: var(--gray-50);\n}\n", "",{"version":3,"sources":["webpack://./src/2-pages/Stories/EditStory/components/TransformTrigger/TransformTrigger.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mCAAmC;EACnC,oBAAoB;EACpB,gCAAgC;AAClC","sourcesContent":[".root {\n  width: 12px;\n  height: 12px;\n  border: 2px solid var(--purple-400);\n  border-radius: 999px;\n  background-color: var(--gray-50);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "TransformTrigger-module__root--Jpnnt"
};
export default ___CSS_LOADER_EXPORT___;
