import { useLocales } from 'entities/config/lib/useLocales';
import { TStoryToolKind } from 'entities/stories/types';
import { useStoryEditorContext } from 'pages/Stories/EditStory/hooks/useStoryEditorContext';
import { useCreateElement } from 'pages/Stories/hooks/useCreateElement';
import { useMemo } from 'react';
import { TIconType } from 'shared/components/Icon/types';
import { ToolButton } from 'shared/components/ToolButton';

type TProps = {
  title: string;
  icon: TIconType;
  kind: TStoryToolKind;
  limit: number;
};
export const ToolsListButton = ({ kind, limit, title, icon }: TProps) => {
  const { selectedLocale, selectedStory, createdLocales } = useStoryEditorContext();
  const { defaultLocale } = useLocales();
  const { storyElements, createElement, isLoading } = useCreateElement(
    createdLocales,
    selectedLocale,
  );

  const isLimitReached = useMemo(
    () => storyElements.filter((element) => element.kind === kind).length >= limit,
    [limit, storyElements],
  );
  const handleClick = () => {
    createElement(kind);
  };
  return (
    <ToolButton
      disabled={isLimitReached || !selectedStory || selectedLocale !== defaultLocale}
      icon={icon}
      isLoading={isLoading}
      title={title}
      onClick={handleClick}
    />
  );
};
